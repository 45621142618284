// https://stackoverflow.com/questions/57720654/after-change-route-jquery-not-working-when-refresh-again-then-work-correctly
// core
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

// self
import { ScriptService, LangService, ApiService, MetaDataService } from '../services';
import { Meta, Title } from '@angular/platform-browser';
import { Career } from '../model/career.model';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { NgForm } from '@angular/forms';
import { GeolocationService } from '../geolocation.service';
import { from } from 'rxjs';

@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.css']
})
export class CareerComponent extends MetaDataService implements OnInit {
  // for load init script
  isLoad = false;
  public current_lang: any = {};

  subscribeFrom = this.formBuilder.group({
    email: ['', Validators.required]
  });
  submitted = false;
  subscribe: any = '';
  blogs: any = [];
  public career: Career = new Career();
  countryCode: string = '+84';
  countryName: string = 'vn';
  public userLanguage: string;
  public userCountry: string;
  filesToUpload
  fileInputError
  fileChose1
  fileChose2
  files: File[];


  onCountryChange(event: any) {
    this.careerForm.get('countryCode')!.setValue(this.countryCode);
    this.careerForm.get('countryName')!.setValue(this.countryName);
  }
  careerForm = this.formBuilder.group({
    name: new FormControl(this.career.name, [Validators.required]),
    phone: new FormControl(this.career.phone, [Validators.required]),
    countryName: new FormControl(this.countryName),
    countryCode: new FormControl(this.countryCode),
    email: new FormControl(this.career.email, [Validators.required]),
    position: new FormControl(this.career.position, [Validators.required]),
    message: new FormControl(this.career.message, [Validators.required]),
    files: new FormControl(this.career.files, [Validators.required]),
  });

  constructor(
    private scriptService: ScriptService,
    private langService: LangService,
    private formBuilder: FormBuilder,
    private geolocation: GeolocationService,
    private apiService: ApiService,
    private location: Location,
    private router: Router,
    title: Title,
    meta: Meta,
  ) {
    super(title, meta);
    // this.title.setTitle("Career | Dicom Interactive");
    // this.meta.updateTag({ name: "description", content: "We have been providing software products to startups and Software development companies in various ranges of scale. We are offering custom software development, web app development, mobile app development & dedicated software teams to software companies and startups." });
    this.updateTags(
      'Career',
      'We have been providing software products to startups and Software development companies in various ranges of scale. We are offering custom software development, web app development, mobile app development & dedicated software teams to software companies and startups.',
      this.location.prepareExternalUrl(this.router.url),
    );
    
    // const careerPosition =  localStorage.getItem('career_position');
    // this.career.position = careerPosition || '';

    // this.scriptService.load("assets/js/main.js");
    // this._loadScript("assets/js/bootstrap.js");
    // this._loadScript("assets/vendor/owl-carousel/js/owl.carousel.min.js");
    // this._loadScript("assets/vendor/revslider/js/jquery.themepunch.tools.min.js");
    // this._loadScript("assets/vendor/revslider/js/jquery.themepunch.revolution.min.js");
    // this._loadScript("assets/js/init.js");
    // this._loadScript("assets/js/slide.js");
    this.scriptService.load(this.scriptService.INIT).then(() => {
      this.isLoad = true;
    });
    this.geolocation.getClientLocale().subscribe((data) => {
      this.userLanguage = data.language;
      this.userCountry = data.country;
    });
    // setTimeout(() => { this.isLoad = true }, 1000);
  }

  async ngOnInit() {
    this.current_lang = await this.langService.loadLang();
    this.submitted = false;
  }
  
  ngOnDestroy() {
    this.submitted = false;
  }

onRecaptchaName(form: NgForm) {
    this.career.name = '';
    this.career.message = '';
    this.career.phone = '';
    this.submitted = false;
    form.resetForm({
      position: 'business_development_executive',
      name: '',
      message: '',
      phone: ''
    });
  }

  /**
   * one way binding need pass NgForm as parameter
   * @param form
   * @returns
   */
  async onSubmit(form: NgForm) {
    try {
      const lang = this.userLanguage;
      this.submitted = true;
      if (form.invalid) {
        return;
      }
      this.career = form.value;
      this.filesToUpload = this.files;
      this.career.countryCode = this.careerForm.get('countryCode')!.value;
      this.career.countryName = this.careerForm.get('countryName')!.value;
      await this.apiService.handleCareerApply(form.value, this.filesToUpload, lang);
      if (lang === 'en') {
        alert("Thank for your applied, We will contact you as soon as possible!");
      } else {
        alert('Cảm ơn bạn đã ứng tuyển, Dicom Interactive sẽ liên hệ lại trong thời gian sớm nhất.')
      }
    } catch (error) {
      return;
    }
    this.handleResetField();
    this.onRecaptchaName(form);
  }

  
  /**
   * for auto set position
   * @param position apply position
   */
  selectPosition(position: string, form: NgForm) {
      this.handleResetField();
      this.career.position = position;
      this.submitted = false;
      this.career.position = position;
      const nameValue = form.controls['name'].value ? form.controls['name'].value.trim() : '';
      const phoneValue = form.controls['phone'].value ? form.controls['phone'].value.trim() : '';
      const MessageValue = form.controls['message'].value ? form.controls['message'].value.trim() : '';
      const file = form.controls['files'].value ? form.controls['files'].value.trim() : '';
      if (nameValue === '') {
        form.controls['name'].reset();
      }
      if (phoneValue === '') {
        form.controls['phone'].reset();
      }
      if (MessageValue === '') {
        form.controls['message'].reset();
      }
      if (file === '') {
        form.controls['files'].reset();
      }
    }

  /**
   *  scroll to
   * @param el element go to. e.g #x
   */
  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }

  /**
   * handle input file
   * @param files file select
   * @returns
   */
  handleFileInput(files: FileList) {
    this.filesToUpload = files;
    
    if (files.length > 2) {
      const lang = this.userLanguage;
      if (lang === 'en') {
        return this.fileInputError = "Maximum is 2 files"
      } else {
        return this.fileInputError = "Tải tối đa 2 file"
      }
    }
    console.log("this.files:: ", files);

    // binding filename
    let fileChose1 = files.item(0).name;
    let fileChose2 = files?.item(1)?.name;
    this.files = Array.from(files);
    if (this.verifyFileExtension(fileChose1) && this.verifyFileExtension(fileChose2)) {
      this.handleResetField();
      this.fileChose1 = fileChose1;
      this.fileChose2 = fileChose2;
      return;
    }

    this.handleResetField();
    return this.fileInputError = "Please upload only pdf, doc, docx file"
  }

  private handleResetField() {
    // reset error
    this.fileInputError = "";
    this.fileChose1 = "";
    this.fileChose2 = "";
  }

  private verifyFileExtension(fileName) {
    if (!fileName) return true;
    let extension = fileName.split(".").pop();
    if (['pdf', 'docx', 'doc'].indexOf(extension) != -1) {
      return true;
    }
    return false;
  }
}
<!--Start Hero-->
<section class="hero-section hero-bg-bg1 bg-gradient">
	<div class="text-block">
		<div class="container">
			<div class="row">
				<div class="col-lg-6 v-center">
					<div class="header-heading">
						<h1 class="wow fadeInUp" data-wow-delay=".2s" i18n>Leading Software Development Company in Vietnam
						</h1>
						<p class="wow fadeInUp" data-wow-delay=".4s" i18n>
							We are a fast-paced growing software company in Vietnam and Melbourne ( Australia ) focused
							on hi-tech custom software development services.
						</p>
						<a [routerLink]="['/portfolio']" class="btn-main bg-btn lnk wow fadeInUp" data-wow-delay=".6s" i18n>
							Our Awesome Works <i class="fas fa-chevron-right fa-icon"></i>
							<span class="circle"></span></a>
					</div>
				</div>
				<div class="col-lg-6 v-center">
					<div class="single-image wow fadeIn" data-wow-delay=".5s">
						<img fetchpriority="high" loading="lazy" srcset="assets/images/hero/hero-image.png 1x" alt="web development" class="img-fluid" />
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!--End Hero-->
<!--Start About-->
<section class="about-agency pad-tb">
	<div class="container">
		<div class="row">
			<div class="col-lg-6 v-center">
				<div class="image-block">
					<img src="assets/images/about/about-image.png" alt="about" class="img-fluid no-shadow" loading="lazy" />
				</div>
			</div>
			<div class="col-lg-6">
				<div class="common-heading text-l">
					<span i18n>About Us</span>
					<h2 i18n>What We Do</h2>
					<p i18n>We have been providing software products to startups and software production development
						companies in various ranges of scale. We are offering custom software development, web app
						development, mobile app development & dedicated software teams to software companies and
						startups. </p>
					<p class="quote" i18n>DICOM Interactive help your businesses grow and improve the touch points and
						enhance the communications with your target customers.</p>
					<div class="user- mt30">
						<div class="media">
							<div class="user-image bdr-radius"><img src="assets/images/user-thumb/girl2.webp" alt="girl" loading="lazy"
									class="img-fluid" /></div>
							<div class="media-body user-info v-center">
								<h5>Daniel Le</h5>
								<div class="user-size" i18n>Co-Founder of <span>Dicom Interactive</span></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!--End About-->
<!--Start Service-->
<section class="service-section web-servic pad-tb" id='services'>
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-lg-8">
				<div class="common-heading">
					<span i18n>What We Offer</span>
					<h2 class="mb30" i18n>Our Design & Development Services</h2>
				</div>
			</div>
		</div>
		<div class="row upset link-hover shape-num justify-content-center">
			<div class="col-lg-3 col-sm-6 mt30 shape-loc wow fadeInUp" data-wow-delay="0.2s">
				<div class="s-block hfull" data-tilt data-tilt-max="5" data-tilt-speed="1000">
					<div class="s-card-icon">
						<img src="assets/images/icons/development.svg" alt="service" class="img-fluid" loading="lazy" />
					</div>
					<h3 i18n>Web Design & Development</h3>
					<p i18n>Our skilled web app development team could always provide professional full-stack web
						application regardless of business scale. &nbsp; &nbsp; &nbsp; &nbsp;</p>
					<a [routerLink]="['/services/web-development', '']" class="rp-home-learn-more" i18n>Learn More <i
							class="fas fa-chevron-right fa-icon"></i></a>
				</div>
			</div>
			<div class="col-lg-3 col-sm-6 mt30 shape-loc wow fadeInUp" data-wow-delay="0.4s">
				<div class="s-block hfull" data-tilt data-tilt-max="5" data-tilt-speed="1000">
					<div class="s-card-icon">
						<img src="assets/images/icons/app.svg" alt="service" class="img-fluid" loading="lazy" />
					</div>
					<h3 i18n>Mobile App Development</h3>
					<p i18n>We offer top-notch native mobile app development in Vietnam and Australia (Swift for iOS and
						Java for Android) at competitive price. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
						&nbsp;</p>
					<a [routerLink]="['/services/mobile-app-development', '']" class="rp-home-learn-more" i18n>Learn More <i
							class="fas fa-chevron-right fa-icon"></i></a>
				</div>
			</div>
			<div class="col-lg-3 col-sm-6 mt30 shape-loc wow fadeInUp" data-wow-delay="0.6s">
				<div class="s-block hfull" data-tilt data-tilt-max="5" data-tilt-speed="1000">
					<div class="s-card-icon">
						<img src="assets/images/icons/branding.svg" alt="service" class="img-fluid" loading="lazy" />
					</div>
					<h3 i18n>Graphic & Brand Design</h3>
					<p i18n>We collaborate with enthusiastic designer to create your better brand identity as well as
						other graphic design related fields. &nbsp; &nbsp; &nbsp; &nbsp;</p>
					<a [routerLink]="['/services', 'graphic-design', '']" class="rp-home-learn-more" i18n>Learn More <i
							class="fas fa-chevron-right fa-icon"></i></a>
				</div>
			</div>
			<div class="col-lg-3 col-sm-6 mt30 shape-loc wow fadeInUp" data-wow-delay="0.8s">
				<div class="s-block mb0 hfull" data-tilt data-tilt-max="5" data-tilt-speed="1000">
					<div class="s-card-icon">
						<img src="assets/images/icons/marketing.svg" alt="service" class="img-fluid" loading="lazy" />
					</div>
					<h3 i18n>Digital Marketing Service</h3>
					<p i18n>Customer satisfaction is one of the factors to a successful business. We offer great plan to
						increase your brand awareness and get more inbound traffic.</p>
					<a [routerLink]="['/services/digital-marketing', '']" class="rp-home-learn-more" i18n>Learn More <i
							class="fas fa-chevron-right fa-icon"></i></a>
				</div>
			</div>
		</div>
		<div class="-cta-btn mt70">
			<div class="free-cta-title v-center wow zoomInDown" data-wow-delay=".9s">
				<p i18n>Hire a <span>Dedicated Developer</span></p>
				<a [routerLink]="['/contact']" class="btn-main bg-btn2 lnk" (click)="onHireNow()" i18n>Hire Now<i
						class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
			</div>
		</div>
	</div>
</section>
<!--End Service-->
<!--Start statistics-->
<div class="statistics-section bg-gradient pad-tb tilt3d">
	<div class="container from-container">
		<div class="row justify-content-center t-ctr">
			<div class="col-lg-4 col-sm-6">
				<div class="statistics">
					<div data-tilt data-tilt-max="20" data-tilt-speed="1000" class="statistics-img">
						<img src="assets/images/icons/startup.svg" alt="years" class="img-fluid" loading="lazy" />
					</div>
					<div class="statnumb">
						<span class="counter">5</span><span>+</span>
						<p i18n>Year In Business</p>
					</div>
				</div>
			</div>
			<div class="col-lg-4 col-sm-6">
				<div class="statistics">
					<div data-tilt data-tilt-max="20" data-tilt-speed="1000" class="statistics-img">
						<img src="assets/images/icons/team.svg" alt="team" class="img-fluid" loading="lazy" />
					</div>
					<div class="statnumb">
						<span class="counter">20</span><span>+</span>
						<p i18n>Team Members</p>
					</div>
				</div>
			</div>
		</div>
		<div class="row small t-ctr">
			<div class="col-lg-3 col-sm-6">
				<div class="statistics">
					<div data-tilt data-tilt-max="20" data-tilt-speed="1000" class="statistics-img">
						<img src="assets/images/icons/deal.svg" alt="happy" class="img-fluid" loading="lazy"/>
					</div>
					<div class="statnumb">
						<span class="counter">40</span><span>+</span>
						<p i18n>Happy Clients</p>
					</div>
				</div>
			</div>
			<div class="col-lg-3 col-sm-6">
				<div class="statistics">
					<div data-tilt data-tilt-max="20" data-tilt-speed="1000" class="statistics-img">
						<img src="assets/images/icons/computers.svg" alt="project" class="img-fluid" loading="lazy" />
					</div>
					<div class="statnumb counter-number">
						<span class="counter">60</span><span>+</span>
						<p i18n>Projects Done</p>
					</div>
				</div>
			</div>
			<div class="col-lg-3 col-sm-6">
				<div class="statistics">
					<div data-tilt data-tilt-max="20" data-tilt-speed="1000" class="statistics-img">
						<img src="assets/images/icons/worker.svg" alt="work" class="img-fluid" loading="lazy" />
					</div>
					<div class="statnumb">
						<span class="counter">50</span><span>k</span>
						<p i18n>Hours Worked</p>
					</div>
				</div>
			</div>
			<div class="col-lg-3 col-sm-6">
				<div class="statistics mb0">
					<div data-tilt data-tilt-max="20" data-tilt-speed="1000" class="statistics-img">
						<img src="assets/images/icons/customer-service.svg" alt="support" class="img-fluid" loading="lazy"/>
					</div>
					<div class="statnumb">
						<span class="counter">24</span><span>/7</span>
						<p i18n>Support Available</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!--End statistics-->
<!--Start Portfolio-->
<section class="portfolio-section pad-tb">
	<div class="container">
		<div class="row justify-content-center ">
			<div class="col-lg-8">
				<div class="common-heading">
					<span i18n>Our Work</span>
					<h2 class="mb0" i18n>Our Latest Creative Work</h2>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-8 col-sm-8 mt60 wow fadeInUp" data-wow-delay="0.2s">
				<div class="isotope_item hover-scale">
					<div class="item-image" data-tilt data-tilt-max="2" data-tilt-speed="1000">
						<a [routerLink]="['/portfolio', 'goen-e-learning-system', '']"><img
								src="assets/images/portfolio/image-d.webp" alt="image" class="img-fluid" loading="lazy" /> </a>
					</div>
					<div class="item-info">
						<h4><a [routerLink]="['/portfolio', 'goen-e-learning-system', '']" i18n>GoEn eLearning System</a>
						</h4>
						<p i18n>Web Application</p>
					</div>
				</div>
			</div>
			<div class="col-lg-4 col-sm-4 mt60 wow fadeInUp" data-wow-delay="0.4s">
				<div class="isotope_item hover-scale">
					<div class="item-image" data-tilt data-tilt-max="2" data-tilt-speed="1000">
						<a [routerLink]="['/portfolio', 'ask2go-mobile-applications', '']"><img
								src="assets/images/portfolio/image-1.webp" alt="image" class="img-fluid" loading="lazy" /> </a>
					</div>
					<div class="item-info">
						<h4><a [routerLink]="['/portfolio', 'ask2go-mobile-applications', '']" i18n>Ask2Go - App For
								Traveler</a></h4>
						<p i18n>iOS, Android</p>
					</div>
				</div>
			</div>
			<div class="col-lg-4 col-sm-4 mt60 wow fadeInUp" data-wow-delay="0.6s">
				<div class="isotope_item hover-scale">
					<div class="item-image" data-tilt data-tilt-max="2" data-tilt-speed="1000">
						<a [routerLink]="['/portfolio', 'ascentis-homepage-design', '']"><img
								src="assets/images/portfolio/image-6.webp" alt="image" class="img-fluid" loading="lazy" /> </a>
					</div>
					<div class="item-info">
						<h4><a [routerLink]="['/portfolio', 'ascentis-homepage-design', '']" i18n>Ascentis Homepage
								Design</a></h4>
						<p i18n>Web Design</p>
					</div>
				</div>
			</div>
			<div class="col-lg-8 col-sm-8 mt60 wow fadeInUp" data-wow-delay="0.8s">
				<div class="isotope_item hover-scale">
					<div class="item-image" data-tilt data-tilt-max="2" data-tilt-speed="1000">
						<a [routerLink]="['/portfolio', 'envoca-mobile-app', '']"><img
								src="assets/images/portfolio/image-c.webp" alt="image" class="img-fluid" loading="lazy" /> </a>
					</div>
					<div class="item-info">
						<h4><a [routerLink]="['/portfolio', 'envoca-mobile-app', '']" i18n>Envoca Mobile App</a></h4>
						<p i18n>iOS, Android</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!--End Portfolio-->
<!--Start Clients-->
<section class="clients-section pad-tb">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-lg-8">
				<div class="common-heading">
					<span i18n>Our happy customers</span>
					<h2 i18n>Some of our Clients</h2>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-12">
				<div class="opl">
					<ul>
						<li class=" wow fadeIn" data-wow-delay=".2s">
							<div class="clients-logo">
								<img src="assets/images/client/client-11.png" alt="marketinggroup" class="img-fluid" loading="lazy"/>
							</div>
						</li>
						<li class=" wow fadeIn" data-wow-delay=".2s">
							<div class="clients-logo">
								<img src="assets/images/client/client-6.webp" alt="wunder-mobility" class="img-fluid" loading="lazy"/>
							</div>
						</li>
						<li class=" wow fadeIn" data-wow-delay=".2s">
							<div class="clients-logo">
								<img src="assets/images/client/client-7.png" alt="MMHF" class="img-fluid" loading="lazy" />
							</div>
						</li>
						<li class=" wow fadeIn" data-wow-delay=".2s">
							<div class="clients-logo">
								<img src="assets/images/client/client-8.webp" alt="ShareRing" class="img-fluid" loading="lazy" />
							</div>
						</li>
						<li class=" wow fadeIn" data-wow-delay=".2s">
							<div class="clients-logo">
								<img src="assets/images/client/client-9.webp" alt="Anan-med-tech-group" class="img-fluid" loading="lazy" />
							</div>
						</li>
						<!-- <li class=" wow fadeIn" data-wow-delay=".2s">
							<div class="clients-logo">
								<img src="assets/images/client/client-10.png" alt="npt-new-pine-multimedia-technologies-jsc" class="img-fluid" loading="lazy" />
							</div>
						</li> -->
						<li class=" wow fadeIn" data-wow-delay=".2s">
							<div class="clients-logo">
								<img src="assets/images/client/client-1.png" alt="goen-business-japan" class="img-fluid" loading="lazy" />
							</div>
						</li>
						<li class=" wow fadeIn" data-wow-delay=".4s">
							<div class="clients-logo">
								<img src="assets/images/client/client-2.png" alt="ask2go" class="img-fluid" loading="lazy" />
							</div>
						</li>
						<li class=" wow fadeIn" data-wow-delay=".6s">
							<div class="clients-logo">
								<img src="assets/images/client/client-3.png" alt="ascentic" class="img-fluid" loading="lazy"/>
							</div>
						</li>
						<li class=" wow fadeIn" data-wow-delay=".8s">
							<div class="clients-logo">
								<img src="assets/images/client/client-4.png" alt="the-color-club" class="img-fluid" loading="lazy" />
							</div>
						</li>
						<li class=" wow fadeIn" data-wow-delay="1s">
							<div class="clients-logo">
								<img src="assets/images/client/client-5.png" alt="nordic-coder" class="img-fluid" loading="lazy" />
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="-cta-btn mt70">
			<div class="free-cta-title v-center wow zoomInDown" data-wow-delay="1.2s">
				<p class="home-from" i18n>We <span>Promise.</span> We <span>Deliver.</span></p>
				<p><a [routerLink]="['/contact']" class="btn-main bg-btn2 lnk" i18n>Let's Work Together<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a></p>
			</div>
		</div>
	</div>
</section>
<!--End Clients-->
<!--Start work-category-->
<section class="work-category pad-tb tilt3d">
	<div class="container">
		<div class="row">
			<div class="col-lg-4 v-center">
				<div class="common-heading text-l">
					<span i18n>Industries we work for</span>
					<h2 i18n>Helping Businesses in All Domains</h2>
					<p i18n>Successfully delivered digital products to those listed industries.</p>
				</div>
			</div>
			<div class="col-lg-8">
				<div class="work-card-set">
					<!--div  class="icon-set wow fadeIn" data-wow-delay=".2s">
	<div class="work-card cd1">
		<div class="icon-bg"><img src="assets/images/icons/icon-1.png" alt="Industries" /></div>
		<p>Social Networking</p>
	</div>
</div-->
					<div class="icon-set wow fadeIn" data-wow-delay=".1s">
						<div class="work-card cd2">
							<div class="icon-bg"><img src="assets/images/icons/icon-2.png" alt="Industries" loading="lazy" /></div>
							<p i18n>Digital Marketing</p>
						</div>
					</div>
					<div class="icon-set wow fadeIn" data-wow-delay=".2s">
						<div class="work-card cd3">
							<div class="icon-bg"><img src="assets/images/icons/icon-3.png" alt="Industries" loading="lazy" /></div>
							<p i18n>Ecommerce Development</p>
						</div>
					</div>
					<div class="icon-set wow fadeIn" data-wow-delay=".3s">
						<div class="work-card cd4">
							<div class="icon-bg"><img src="assets/images/icons/icon-4.png" alt="Industries" loading="lazy" /></div>
							<p i18n>Video Service</p>
						</div>
					</div>
					<!--div class="icon-set wow fadeIn" data-wow-delay="1s">
	<div class="work-card cd5">
		<div class="icon-bg"><img src="assets/images/icons/icon-5.png" alt="Industries" /></div>
		<p>Banking Service</p>
	</div>
</div-->
					<!--div class="icon-set wow fadeIn" data-wow-delay="1.2s">
	<div class="work-card cd6">
		<div class="icon-bg"><img src="assets/images/icons/icon-6.png" alt="Industries" /></div>
		<p>Enterprise Service</p>
	</div>
</div-->
					<div class="icon-set wow fadeIn" data-wow-delay="0.4s">
						<div class="work-card cd7">
							<div class="icon-bg"><img src="assets/images/icons/icon-7.png" alt="Industries" loading="lazy" /></div>
							<p i18n>Education Service</p>
						</div>
					</div>
					<div class="icon-set wow fadeIn" data-wow-delay="0.5s">
						<div class="work-card cd8">
							<div class="icon-bg"><img src="assets/images/icons/icon-8.png" alt="Industries" loading="lazy" /></div>
							<p i18n>Tour and Travels</p>
						</div>
					</div>
					<div class="icon-set wow fadeIn" data-wow-delay="0.6s">
						<div class="work-card cd9">
							<div class="icon-bg"><img src="assets/images/icons/icon-9.png" alt="Industries" loading="lazy" /></div>
							<p i18n>Health Service</p>
						</div>
					</div>
					<!--div class="icon-set wow fadeIn" data-wow-delay="2s">
	<div class="work-card cd10">
		<div class="icon-bg"><img src="assets/images/icons/icon-10.png" alt="Industries" /></div>
		<p>Event & Ticket</p>
	</div>
</div-->
					<div class="icon-set wow fadeIn" data-wow-delay="0.7s">
						<div class="work-card cd11">
							<div class="icon-bg"><img src="assets/images/icons/icon-11.png" alt="Industries" loading="lazy" /></div>
							<p i18n>Restaurant Service</p>
						</div>
					</div>
					<div class="icon-set wow fadeIn" data-wow-delay="0.8s">
						<div class="work-card cd12">
							<div class="icon-bg"><img src="assets/images/icons/icon-12.png" alt="Industries" loading="lazy" /></div>
							<p i18n>Business Consultant</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!--End  work-category-->
<!--Start Testinomial-->
<section class="testinomial-section pad-tb">
	<div class="container">
		<div class="row">
			<div class="col-lg-5 v-center">
				<div class="common-heading text-l">
					<span i18n>Customer Testimonials</span>
					<h2 class="mb0" i18n>What our clients say about Dicom Interactive.</h2>
				</div>
			</div>
			<div class="col-lg-7">
				<div class="owl-carousel testimonial-card-a pl25">
					<div class="testimonial-card">
						<div class="t-text">
							<p i18n>Dicom Interactive is an exceptional IT company that delivers high-quality interactive
								solutions tailored to specific needs.
								Their team is knowledgeable, professional, and reliable, and I highly recommend them to
								anyone seeking top-notch IT services.</p>
						</div>
						<div class="client-thumbs mt30">
							<div class="media v-center">
								<div class="user-image bdr-radius"><img src="assets/images/user-thumb/tim-bos.png"
										alt="tim-bos" class="img-fluid" loading="lazy" /></div>
								<div class="media-body user-info v-center">
									<h5>Tim Bos</h5>
									<p i18n>Founder & CEO at ShareRing <small>Australia</small></p>
								</div>
							</div>
						</div>
					</div>
					<div class="testimonial-card">
						<div class="t-text">
							<p i18n>Dicom Interactive is an exceptional IT company that exceeded my expectations. Their
								attention to detail, responsiveness, and dedication to delivering high-quality work were
								truly impressive. I highly recommend them to anyone looking for top-quality IT services
								and solutions.</p>
						</div>
						<div class="client-thumbs mt30">
							<div class="media v-center">
								<div class="user-image bdr-radius"><img src="assets/images/user-thumb/peter-david.png"
										alt="peter" class="img-fluid" loading="lazy"/></div>
								<div class="media-body user-info v-center">
									<h5>Peter David
									</h5>
									<p i18n>Co-Principal at Realty <small>Australia</small></p>
								</div>
							</div>
						</div>
					</div>
					<div class="testimonial-card">
						<div class="t-text">
							<p i18n>My company received outstanding services from Dicom.
								Their skillful team brought us remarkable value in both cross-platform development and
								graphic design.
								We will come back for some bigger projects.
								Highly recommended to tech-coms.</p>
						</div>
						<div class="client-thumbs mt30">
							<div class="media v-center">
								<div class="user-image bdr-radius"><img src="assets/images/user-thumb/girl1.jpg"
										alt="girl" class="img-fluid" loading="lazy" /></div>
								<div class="media-body user-info v-center">
									<h5>Sa Tran</h5>
									<p i18n>CEO of GoEn, <small>Japan</small></p>
								</div>
							</div>
						</div>
					</div>
					<div class="testimonial-card">
						<div class="t-text">
							<p i18n>Dicom-interactive is an exceptional agency that can combine technology and client
								service. My
								company and I have experienced in-depth consulting from the CEO. Their solid
								foundation is ready to be in charge of big and sophisticated projects which request
								high level of concentration and responsibility. Highly recommended for tech-com
								start up!</p>
						</div>
						<div class="client-thumbs mt30">
							<div class="media v-center">
								<div class="user-image bdr-radius"><img src="assets/images/user-thumb/girl.webp"
										alt="girl" class="img-fluid" loading="lazy" /></div>
								<div class="media-body user-info">
									<h5>Huyen Dao</h5>
									<p i18n>CEO of Mihoo, <small>Vietnam</small></p>
								</div>
							</div>
						</div>
					</div>
					<div class="testimonial-card">
						<div class="t-text">
							<p i18n>I must say that their top quality of work and adaptation to change of requirements are
								pretty impressive.
								They know what exactly they need to do to achieve the client’s goal and they do it
								wholeheartedly.
								On top of that, they are all super friendly and communicative people.
								Dicom Interactive is simply a very reliable partner.</p>
						</div>
						<div class="client-thumbs mt30">
							<div class="media v-center">
								<div class="user-image bdr-radius"><img src="assets/images/user-thumb/girl3.jpg"
										alt="girl" class="img-fluid" loading="lazy" /></div>
								<div class="media-body user-info">
									<h5>Ryan Le</h5>
									<p i18n>MD of Nordic Coder, <small>Vietnam</small></p>
								</div>
							</div>
						</div>
					</div>
					<div class="testimonial-card">
						<div class="t-text">
							<p i18n>I had some chances to work with Dicom Interactive. They are definitely hard-working
								technical guys with astonishingly fertile creative imagination and clearly
								comfortable with taking on responsibility. They very concentrated on achieving the
								project deadlines and very goes above and beyond what is required to ensure that
								their work is both professional and cutting edge.
							</p>
						</div>
						<div class="client-thumbs mt30">
							<div class="media v-center">
								<div class="user-image bdr-radius"><img src="assets/images/user-thumb/girl4.jpg" alt="girl" class="img-fluid" loading="lazy" /></div>
								<div class="media-body user-info">
									<h5>Yuzo Suzuki</h5>
									<p i18n>CEO of Atech, <small>Japan</small></p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!--End Testinomial-->
<!--Start Enquire Form-->
<section class="enquire-form pad-tb">
	<div class="container">
		<div class="row light-bgs">
			<div class="col-lg-6">
				<div class="common-heading text-l">
					<span i18n>Contact Now</span>
					<h2 class="mt0" i18n>Have Question? Write a Message</h2>
				</div>
				<div class="form-block">
					<form #contactForm="ngForm" (ngSubmit)="onSubmit(contactForm)" name="feedback-form">
						<div class="row">
							<div class="form-group col-sm-6">
								<input type="text" name="name" id="name" [ngModel]="contact.name" #name="ngModel" placeholder="Enter name"
								required oninput="this.value = this.value.replace(/[\s]+/g, ' ',)" minlength="6" i18n-placeholder>
								<div *ngIf="submitted || (name.invalid && (name.dirty || name.touched))">
									<div *ngIf="name.errors?.required" class="help-block with-errors" i18n>Name is required</div>
									<div *ngIf="name.errors?.minlength" class="help-block with-errors" i18n>Name must be at least 6 characters</div>
								</div>
							</div>
							<div class="form-group col-sm-6">
								<input type="email" id="email" name="email" [ngModel]="contact.email" #email="ngModel" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$" placeholder="Enter email" required email i18n-placeholder>
								<div *ngIf="submitted || (email.invalid && (email.dirty || email.touched))">
									<div *ngIf="email.errors?.required" class="help-block with-errors" i18n>Email is required</div>
									<div *ngIf="email.errors?.pattern" class="help-block with-errors" i18n>Email is invalid</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="form-group col-sm-6 phone">
								<input type="text" id="mobile" name="phone" id="phone" [ngModel]="contact.phone" ng2TelInput
								  [ng2TelInputOptions]="{'preferredCountries': ['au', 'vn']}" required minlength="6" maxlength="12"
								  [minlength]="phone.errors?.pattern ? null : 6" [maxlength]="phone.errors?.pattern ? null : 12"
								  pattern="\d+" (countryChange)="onCountryChange($event)" #phone="ngModel" placeholder="Enter mobile" i18n-placeholder required data-error="Please fill Out">
								<div *ngIf="(submitted && phone.invalid) || (phone.invalid && (phone.dirty || phone.touched))">
								  <div *ngIf="phone.errors?.required" class="help-block with-phone" i18n>Mobile is required</div>
								  <div *ngIf="phone.errors?.minlength || phone.errors?.maxlength" class="help-block with-phone" i18n>Mobile number must be between 6 and 12 digits</div>
								  <div *ngIf="phone.errors?.pattern" class="help-block with-phone" i18n>Invalid Mobile Number</div>
								</div>
							  </div>
							<div class="form-group col-sm-6">
								<input id="Dtype" name="subject" id="subject" [ngModel]="contact.subject" #subject="ngModel" placeholder="Enter subject" i18n-placeholder required>
								<div *ngIf="(submitted && subject.invalid) || (subject.invalid && (subject.dirty || subject.touched))">
									<div *ngIf="subject.errors?.required" class="help-block with-errors" i18n>Subject is required</div>
								  </div>
							</div>
						</div>
						<div class="form-group">
							<textarea id="message" rows="5" name="message" id="message" [ngModel]="contact.message" #message="ngModel" placeholder="Enter your message" i18n-placeholder required></textarea>
							<div *ngIf="(submitted && message.invalid) || (message.invalid && (message.dirty || message.touched))">
								<div *ngIf="message.errors?.required" class="help-block with-errors" i18n>Message is required</div>
							</div>
						</div>
						<div class="form-group from-recaptcha">
							<div class="g-recaptcha" ng-model="g-recaptcha-response" id="recaptcha"></div>
						</div>
						<div class="fieldsets mt20">
							<button type="submit" name="submit" id="form-submit" class="btn lnk btn-main bg-btn" [disabled]="!submitted" i18n>Submit<i class="fas fa-chevron-right fa-icon"></i>
								<span class="circle"></span>
							</button>
						</div>
						<p class="trm" i18n><i class="fas fa-lock"></i>We hate spam, and we respect your privacy.</p>
					</form>
				</div>
			</div>
			<div class="col-lg-6 v-center">
				<div class="enquire-image">
					<img src="assets/images/about/hellopic.webp" alt="enquire" class="img-fluid" loading="lazy" />
				</div>
			</div>
		</div>
	</div>
</section>
<!--End Enquire Form-->
<!--Start Location-->
<section class="our-office pad-tb">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-lg-8">
				<div class="common-heading">
					<span i18n>Our Locations</span>
					<h2 i18n>Our offices</h2>
				</div>
			</div>
		</div>
		<div class="row justify-content-center upset shape-numm">
			<div class="col-lg-4 col-sm-6 shape-loc wow fadeInUp" data-wow-delay=".2s">
				<div class="office-card">
					<div class="skyline-img">
						<img src="assets/images/location/newyork.webp" alt="Saigon" class="img-fluid" loading="lazy" />
					</div>
					<div class="office-text">
						<h4>Saigon</h4>
						<p i18n>81 CMT8 st, Bến Thành ward, dist 1, Hochiminh city, Vietnam.</p>
						<a href="https://goo.gl/maps/wRttxVknzVHBoDjaA" target="blank" class="btn-outline rount-btn"><i
								class="fas fa-map-marker-alt"></i></a>
						<a href="tel:+84907506614" target="blank" class="btn-outline rount-btn"><i
								class="fas fa-phone-alt"></i></a>
						<a href="mailto:sales@dicom-interactive.com" target="blank" class="btn-outline rount-btn"><i
								class="fas fa-envelope"></i></a>
						<a href="whatsapp://send?text=Hello&phone=+84907506614" target="blank"
							class="btn-outline rount-btn"><i class="fas fa-comment-dots"></i></a>
					</div>
				</div>
			</div>
			<div class="col-lg-4 col-sm-6 shape-loc wow fadeInUp" data-wow-delay=".5s">
				<div class="office-card">
					<div class="skyline-img">
						<img src="assets/images/location/sydeny.webp" alt="Melbourne" class="img-fluid" loading="lazy"/>
					</div>
					<div class="office-text">
						<h4>Melbourne</h4>
						<p class="mb48" i18n>18 Dennis St, Lalor VIC 3075, Australia.</p>
						<a href="https://goo.gl/maps/cg2wjvwDss7RQxAx5" target="blank" class="btn-outline rount-btn"><i
								class="fas fa-map-marker-alt"></i></a>
						<a href="tel:+61402752523" target="blank" class="btn-outline rount-btn"><i
								class="fas fa-phone-alt"></i></a>
						<a href="mailto:sales@dicom-interactive.com" target="blank" class="btn-outline rount-btn"><i
								class="fas fa-envelope"></i></a>
						<a href="whatsapp://send?text=Hello&phone=+61402752523" target="blank"
							class="btn-outline rount-btn"><i class="fas fa-comment-dots"></i></a>
					</div>
				</div>
			</div>
			<div class="col-lg-4 col-sm-6 shape-loc wow fadeInUp" data-wow-delay=".8s">
				<div class="office-card mb0">
					<div class="skyline-img">
						<img src="assets/images/location/rome.webp" alt="Brussels" class="img-fluid" loading="lazy"/>
					</div>
					<div class="office-text">
						<h4>Brussels</h4>
						<p i18n>Establishing.</p>
						<!--a href="javascript:void(0)" target="blank" class="btn-outline rount-btn"><i class="fas fa-map-marker-alt"></i></a>
<a href="javascript:void(0)" target="blank" class="btn-outline rount-btn"><i class="fas fa-phone-alt"></i></a>
<a href="javascript:void(0)" target="blank" class="btn-outline rount-btn"><i class="fas fa-envelope"></i></a>
<a href="javascript:void(0)" target="blank" class="btn-outline rount-btn"><i class="fab fa-skype"></i></a-->
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!--End Location-->


  <!--Breadcrumb Area-->
  <section class="breadcrumb-areav2" data-background="assets/images/banner/10.jpg">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-7">
          <div class="bread-titlev2">
            <h1 class="wow fadeInUp" data-wow-delay=".2s" i18n>Digital Marketing Services
            </h1>
            <p class="mt20 wow fadeInUp" data-wow-delay=".4s" i18n>Professional Digital Marketing Services That Drive
              Results.</p>
            <a [routerLink]="['/contact']" class="btn-main bg-btn2 lnk mt20 wow zoomInDown" data-wow-delay=".6s" (click)="onContactMar()" i18n>Get Quote <i
                class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- <div class="statistics-wrap bg-gradient5">
    <div class="container">
      <div class="row small t-ctr mt0">
        <div class="col-lg-3 col-sm-6">
          <div class="statistics">
            <div class="statistics-img">
              <img src="assets/images/icons/deal.svg" alt="happy" class="img-fluid">
            </div>
            <div class="statnumb">
              <span class="counter">40</span><span>+</span>
              <p>Happy Clients</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6">
          <div class="statistics">
            <div class="statistics-img">
              <img src="assets/images/icons/computers.svg" alt="project" class="img-fluid">
            </div>
            <div class="statnumb counter-number">
              <span class="counter">60</span><span>+</span>
              <p>Projects Done</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6">
          <div class="statistics">
            <div class="statistics-img">
              <img src="assets/images/icons/worker.svg" alt="work" class="img-fluid">
            </div>
            <div class="statnumb">
              <span class="counter">50</span><span>k</span>
              <p>Hours Worked</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6">
          <div class="statistics mb0">
            <div class="statistics-img">
              <img src="assets/images/icons/customer-service.svg" alt="support" class="img-fluid">
            </div>
            <div class="statnumb">
              <span class="counter">24</span><span>/</span><span class="counter">7</span>
              <p>Support Available</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <app-services-counter></app-services-counter>
  <!--End Hero-->
  <!--Start About-->
  <section class="service pad-tb bg-gradient5">
    <div class="container">
      <div class="row">
        <div class="col-lg-4">
          <div class="image-block wow fadeIn">
            <img src="assets/images/service/digitalmarketing.png" alt="image" class="img-fluid no-shadow" />
          </div>
        </div>
        <div class="col-lg-8 block-1">
          <div class="common-heading text-l pl25">
            <span i18n>Overview</span>
            <h2 i18n>Digital Marketing Services for Growing Your Company</h2>
            <p i18n>Having more leads, more sales, more revenue yet remaining sustainable in the industry is what all of
              businesses have been seeking for. We can help you build a long-term digital marketing strategy for your
              ultimate business plan. At the same time, we offer digital advertising services to boost sales, promote
              your brands, products, and services. We do not boast about working with Fortune 500 companies as one of
              our core values is helping our clients achieve their success in business growth and revenue. </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--End About-->
  <!--Start Service-->
  <section class="service-block bg-gradient6 pad-tb">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="common-heading ptag">
            <span i18n>Service</span>
            <h2 i18n>Our Services</h2>
            <p class="mb30" i18n>We think big and have hands in all leading technology platforms to provide you wide array of
              services.</p>
          </div>
        </div>
      </div>
      <div class="row upset link-hover">
        <div class="col-lg-6 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".2s">
          <div class="s-block wide-sblock hfull">
            <div class="s-card-icon-large"><img src="assets/images/service/seo.png" alt="service" class="img-fluid" /></div>
            <div class="s-block-content-large">
              <h4 i18n>Search Engine Optimization</h4>
              <p i18n>The better visibility your pages have in search results, the more likely you are to garner
                attention and attract prospective and existing customers to your business.</p>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".4s">
          <div class="s-block wide-sblock hfull">
            <div class="s-card-icon-large"><img src="assets/images/service/smo.png" alt="service" class="img-fluid" /></div>
            <div class="s-block-content-large">
              <h4 i18n>Social Media Marketing</h4>
              <p i18n>Social marketing is a great solution to connect you with your audience and boost your brand identity to potential customers as well as
                expanding your community.</p>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".6s">
          <div class="s-block wide-sblock hfull">
            <div class="s-card-icon-large"><img src="assets/images/service/cm.png" alt="service" class="img-fluid" /></div>
            <div class="s-block-content-large">
              <h4 i18n>Content Marketing</h4>
              <p i18n>An impactful piece of content should be value-added to the customers, in innovative forms, and reflect
                your business’s missions as well as relating to your community.</p>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".8s">
          <div class="s-block wide-sblock hfull">
            <div class="s-card-icon-large"><img src="assets/images/service/banner.png" alt="service" class="img-fluid" /></div>
            <div class="s-block-content-large">
              <h4 i18n="@@html5_banner">HTML5 Banner</h4>
              <p i18n>Banner ads is always a preferable way to digitally promote your brand awareness in the marketplace,
                whenever it comes to online advertising.</p>
            </div>
          </div>
        </div>


      </div>
      <div class="-cta-btn mt70">
        <div class="free-cta-title v-center wow zoomInDown" (click)="onContactMar()" data-wow-delay="1.1s">
          <p i18n>Hire a <span>Dedicated Developer</span></p>
          <a [routerLink]="['/contact']" class="btn-main bg-btn2 lnk" i18n>Hire Now<i class="fas fa-chevron-right fa-icon"></i><span
              class="circle"></span></a>
        </div>
      </div>
    </div>
  </section>
  <!--End Service-->
  <!--Start Key points-->
  <section class="service pad-tb light-dark">
    <div class="container">
      <div class="row">
        <div class="col-lg-7 single-image-app2">
          <div class="text-l service-desc- pr25">
            <h3 class="mb20" i18n="@@html5_banner">HTML5 Banner</h3>
            <p i18n>Banner ads is always a preferable way to digitally promote your brand awareness in the marketplace,
              whenever it comes to online advertising. These days ad retargeting let your target customers see your ad
              intentionally everywhere they go. As so, we offer solutions to increase traffic to your online services,
              your landing pages... Whether they are static or animated, big or small banners, when working with us,
              your messages to the customers are creatively integrated into the HTML5 Banners in the most appealing
              manners. With our banner ad creation service, our clients always experience a boost of quality clicks that
              leads to higher conversion rates. Our specialists at Dicom Interactive are hands on with the mechanism of
              creating classy and affecting banner ads in Automobile, FMCG, Healthcare, Pharmacy, Fashion, and more to
              come. Get in touch with us today.</p>
            <div class="row">
              <div class="col-md-6 mt10 mobile-lang">
                <ul class="list-ul ul-check">
                  <li i18n>Static banner.</li>
                  <li i18n>Rich media banner.</li>
                </ul>
              </div>
              <div class="col-md-6 mt10 mobile-lang">
                <ul class="list-ul ul-check">
                  <li i18n>Google DoubleClick.</li>
                  <li i18n>Facebook banner.</li>
                </ul>
              </div>
            </div>
            <a [routerLink]="['/contact']" class="btn-main bg-btn2 lnk mt30" (click)="onContactMar()" i18n>Request A Quote <i
                class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
          </div>
        </div>
        <div class="col-lg-5 single-image-app1">
          <div class="single-image wow fadeIn">
            <img src="assets/images/service/branding-marketing-1.jpg" alt="image" class="img-fluid no-shadow" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="service pad-tb light-dark">
    <div class="container">
      <div class="row">
        <div class="col-lg-5">
          <div class="single-image wow fadeIn">
            <img src="assets/images/service/branding-marketing-2.jpg" alt="image" class="img-fluid no-shadow" />
          </div>
        </div>
        <div class="col-lg-7">
          <div class="text-l service-desc- pr25">
            <h3 class="mb20" i18n>Social Marketing</h3>
            <p i18n>90% of all marketers say that social marketing has increased their business exposure to their potential
              customers. Social marketing is a great solution to boost your brand identity to potential customers as
              well as expanding your community. Needless to say, social community is one of the key assets of any
              business in this digital era, where everything is transferable and customer loyalty is too expensive. Your
              choice of using suitable social marketing methods brings your business more sales and more brand
              ambassadors organically. Dicom Interactive offer a vast list of services that encompasses social media
              advertising, endorsement, management. We manage the complexity and offer the best use of Facebook,
              Instagram management, along with other platforms, that suit your utmost business transformation stage.</p>
            <div class="row">
              <div class="col-md-6 mt10 mobile-lang">
                <ul class="list-ul ul-check">
                  <li i18n>Social Media Management.</li>
                  <li i18n>Social Media Design.</li>
                </ul>
              </div>
              <div class="col-md-6 mt10 mobile-lang">
                <ul class="list-ul ul-check">
                  <li i18n>Social Media Advertising.</li>
                  <li i18n>Social Media SEO.</li>
                </ul>
              </div>
            </div>
            <a [routerLink]="['/contact']" class="btn-main bg-btn2 lnk mt30" (click)="onContactMar()" i18n>Request A Quote <i
                class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="service pad-tb light-dark">
    <div class="container">
      <div class="row">
        <div class="col-lg-7 single-image-app2">
          <div class="text-l service-desc- pr25">
            <h3 class="mb20" i18n>Content Marketing</h3>
            <p i18n>Content marketing is one of the most powerful strategies in digital marketing. An impactful piece of
              content should be value-added to the customers, in innovative forms, and reflect your business’s missions
              as well as relating to your community. Complying with these principles results in higher value products,
              successful sales, and loyal customers. At Dicom Interactive, our marketing specialists understand many
              businesses in and out, so that we are well capable in delivering insightful stories and impactful
              strategies that move and convert your target audience. Whether working with us on a full package of lead
              nurture content or on a customized package for your tactical campaigns, we ensure your business reach the
              ultimate goals.</p>
            <div class="row">
              <div class="col-md-6 mt10 mobile-lang">
                <ul class="list-ul ul-check">
                  <li i18n>Content Strategy and Development.</li>
                  <li i18n>Website and Social Media Engagement Content Design.</li>
                  <li i18n>Online CRM marketing.</li>
                  <li i18n>Email marketing.</li>
                </ul>
              </div>
              <div class="col-md-6 mt10 mobile-lang">
                <ul class="list-ul ul-check">
                  <li i18n>On page and Off page SEO.</li>
                  <li i18n>Landing pages & Funnels.</li>
                  <li i18n>PPC management.</li>
                  <li i18n>Lead generation.</li>
                </ul>
              </div>
            </div>
            <a [routerLink]="['/contact']" class="btn-main bg-btn2 lnk mt30" (click)="onContactMar()" i18n>Request A Quote <i
                class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
          </div>
        </div>
        <div class="col-lg-5 single-image-app1">
          <div class="single-image wow fadeIn">
            <img src="assets/images/service/branding-marketing-3.jpg" alt="image" class="img-fluid no-shadow" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--End Key points-->

<!-- why us, some works, let work together -->
<app-services-why-us></app-services-why-us>

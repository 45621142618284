import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class LinkService {

  constructor() { }

  setLinkCanonical() {
    const url = window.location.href;
    if(url.includes("www.")){
      const newUrl = url.replace("www.","");
      const link: HTMLLinkElement = document.createElement('link');
      link.setAttribute('rel', 'canonical');
      document.head.appendChild(link);
      link.setAttribute('href', newUrl);
    }
  }
}


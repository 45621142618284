// language.service.ts

import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export abstract class MetaDataService {

  constructor(private title: Title, private meta: Meta) {
  }

  updateTags(tag: string, description: string, partUrl?: string, thumbnailUrl?: string) {
    if (!tag) {
      tag = "Home"
    }

    this.updateMeta(tag, description)
    this.updateUrl(partUrl, thumbnailUrl)
  }

  private updateMeta(title: string, description) {
    this.title.setTitle(title + " | Dicom Interactive")
    // this.meta.updateTag({ name: "og:site_name", content: title })
    this.meta.updateTag({ property: "og:title", content: title })
    this.meta.updateTag({ name: "twitter:title", content: title })
    if (description) {
      this.meta.updateTag({ name: "description", content: description })
      this.meta.updateTag({ property: "og:description", content: description })
      this.meta.updateTag({ name: "twitter:description", content: description })
    }
  }

  private updateUrl(partUrl: string, thumbnail: string) {
    if (partUrl) {
      this.meta.updateTag({ property: "og:url", content: partUrl })
      this.meta.updateTag({ name: "twitter:url", content: partUrl })
    }

    if (thumbnail) {
      if (thumbnail.startsWith('/')) {
        thumbnail = 'https://dicom-interactive.com' + thumbnail;
      }
      this.meta.updateTag({ property: "og:image", content: thumbnail })
    }
  }
}

// https://stackoverflow.com/questions/57720654/after-change-route-jquery-not-working-when-refresh-again-then-work-correctly
// core
import { Component, OnInit } from '@angular/core';

// self
import { ScriptService, LangService } from '../services';


@Component({
  selector: 'app-services-why-us',
  templateUrl: './why-us.component.html',
  styleUrls: ['./service-detail.component.css']
})
export class WhyUsComponent implements OnInit {
  // for load init script
  isLoad = false;
  public current_lang: any = {};

  constructor(
    private scriptService: ScriptService,
    private langService: LangService,
  ) {
    // this._loadScript("assets/js/jquery-2.0.0.min.js");
    // this._loadScript("assets/js/bootstrap.js");
    // this._loadScript("assets/vendor/owl-carousel/js/owl.carousel.min.js");
    // this._loadScript("assets/vendor/revslider/js/jquery.themepunch.tools.min.js");
    // this._loadScript("assets/vendor/revslider/js/jquery.themepunch.revolution.min.js");
    // this._loadScript("assets/js/init.js");
    // this._loadScript("assets/js/slide.js");
    // setTimeout(() => { this.isLoad = true }, 1000);
  }

  async ngOnInit() {
    this.current_lang = await this.langService.loadLang();
  }


}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ShowCase } from '../types/showcase';
import { Meta, Title ,DomSanitizer} from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { ApiService, MetaDataService } from '../services';

@Component({
  selector: 'app-showcase-detail',
  templateUrl: './showcase-detail.component.html',
  styleUrls: ['./showcase-detail.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ShowCaseDetailComponent extends MetaDataService implements OnInit {
  showcase: ShowCase
  imgEndpoint = environment.imgEndPoint;

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private sanitizer: DomSanitizer,
    private location: Location,
    private router: Router,
    title: Title,
    meta: Meta,
  ) {
    super(title, meta);
  }

  ngOnInit(): void {
    const slug = this.route.snapshot.paramMap.get('slug');
    this.getShowcase(slug)
  }

  async getShowcase(slug: string) {
    const res = await this.apiService.getShowcase(slug);
    if(res.data && res.data.length > 0){
      if(res.data[0].attributes.description) {
      res.data[0].attributes.description = this.sanitizer.bypassSecurityTrustHtml(res.data[0].attributes.description as string)
      }
      this.showcase = res.data[0]
      // this.title.setTitle(this.showcase.attributes.title + " | Dicom Interactive");
      // this.meta.updateTag({ name: "description", content: this.showcase.attributes.sortDescription });
      this.updateTags(
        this.showcase.attributes.title,
        this.showcase.attributes.sortDescription,
        this.location.prepareExternalUrl(this.router.url),
      );
    }
  }
}

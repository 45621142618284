// https://stackoverflow.com/questions/57720654/after-change-route-jquery-not-working-when-refresh-again-then-work-correctly
// core
import { Component, OnInit, ElementRef, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';

// self
import { ScriptService, LangService, MetaDataService } from '../services';


@Component({
  selector: 'app-about-dicom',
  templateUrl: './about-dicom.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutDicomComponent extends MetaDataService implements OnInit {
  // for load init script
  isLoad = false;
  public current_lang: any = {};

  constructor(
    private scriptService: ScriptService,
    private langService: LangService,
    private location: Location,
    private router: Router,
    title: Title,
    meta: Meta,
  ) {
    super(title, meta);
    // this.title.setTitle("About Us | Dicom Interactive");
    // this.meta.updateTag({ property: "og:title", content: `About Us` });
    // this.meta.updateTag({ property: "og:description", content: "DICOM Interactive is a fast-paced growing Vietnamese and Melbourne (Australia) software company focused on hi-tech custom development services." });
    // this.meta.updateTag({ name: "description", content: "DICOM Interactive is a fast-paced growing Vietnamese and Melbourne (Australia) software company focused on hi-tech custom development services." });
    this.updateTags(
      'About Us',
      'DICOM Interactive is a fast-paced growing Vietnamese and Melbourne (Australia) software company focused on hi-tech custom development services.',
      this.location.prepareExternalUrl(this.router.url),
    );

    // this._loadScript("assets/js/jquery-2.0.0.min.js");
    // this._loadScript("assets/js/bootstrap.js");
    // this._loadScript("assets/vendor/owl-carousel/js/owl.carousel.min.js");
    // this._loadScript("assets/vendor/revslider/js/jquery.themepunch.tools.min.js");
    // this._loadScript("assets/vendor/revslider/js/jquery.themepunch.revolution.min.js");
    // this._loadScript("assets/js/init.js");
    // this._loadScript("assets/js/slide.js");
    this.scriptService.load(this.scriptService.INIT).then(() => {
      this.isLoad = true;
    });
    // setTimeout(() => { this.isLoad = true }, 1000);
  }

  async ngOnInit() {
    this.current_lang = await this.langService.loadLang();
  }


}

// https://stackoverflow.com/questions/57720654/after-change-route-jquery-not-working-when-refresh-again-then-work-correctly
// core
import { Component, OnInit, ElementRef, Inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

// self
import { ScriptService, LangService, MetaDataService } from '../services';


@Component({
  selector: 'app-about-dicom-aus',
  templateUrl: './about-dicom-aus.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutDicomAusComponent extends MetaDataService implements OnInit {
  // for load init script
  isLoad = false;
  place = "Melbourne"
  public current_lang: any = {};

  constructor(
    private scriptService: ScriptService,
    // private route: ActivatedRoute,
    private langService: LangService,
    private location: Location,
    private router: Router,
    title: Title,
    meta: Meta,
  ) {
    super(title, meta);
    // this.route.params.subscribe(async params => {
    // console.log('xxx', router.url);
    if (router.url == '/software-development-sydney/') {
      this.place = "Sydney";
    }
    let place = this.place;
    // set title and description
    // this.title.setTitle(`Software Development ${place} | Dicom Interactive`);
    // this.meta.updateTag({ property: "og:title", content: `Software Development ${place}` });
    // this.meta.updateTag({ property: "og:description", content: `DICOM Interactive is a fast-paced growing Vietnamese and ${place} (Australia) software company focused on hi-tech custom development services.` });
    // this.meta.updateTag({ name: "description", content: `DICOM Interactive is a fast-paced growing Vietnamese and ${place} (Australia) software company focused on hi-tech custom development services.` });
    // });
    this.updateTags(
      `Software Development ${place}`,
      `DICOM Interactive is a fast-paced growing Vietnamese and ${place} (Australia) software company focused on hi-tech custom development services.`,
      this.location.prepareExternalUrl(this.router.url),
    );

    // this._loadScript("assets/js/jquery-2.0.0.min.js");
    // this._loadScript("assets/js/bootstrap.js");
    // this._loadScript("assets/vendor/owl-carousel/js/owl.carousel.min.js");
    // this._loadScript("assets/vendor/revslider/js/jquery.themepunch.tools.min.js");
    // this._loadScript("assets/vendor/revslider/js/jquery.themepunch.revolution.min.js");
    // this._loadScript("assets/js/init.js");
    // this._loadScript("assets/js/slide.js");
    this.scriptService.load(this.scriptService.INIT).then(() => {
      this.isLoad = true;
    });
    // setTimeout(() => { this.isLoad = true }, 1000);
  }

  async ngOnInit() {
    this.current_lang = await this.langService.loadLang();
  }


}

<!-- // Ascentis Homepage Design -->
<!--Breadcrumb Area-->
<section class="breadcrumb-area banner-5">
	<div class="text-block">
		<div class="container">
			<div class="row">
				<div class="col-lg-12 v-center">
					<div class="bread-inner">
						<div class="bread-menu">
							<ul>
								<li><a [routerLink]="['/']" i18n>Home</a></li>
								<li><a [routerLink]="['/portfolio' , '']" i18n>Portfolio</a></li>
							</ul>
						</div>
						<div class="bread-title">
							<h1 i18n>Ascentis Homepage Design</h1>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!--End Breadcrumb Area-->

<!--Start Case Study About-->
<section class="case-study pad-tb">
	<div class="container">
		<div class="row">
			<div class="col-lg-6">
				<div class="image-block upset">
					<img src="assets/images/case-study/ascentis-intro.png" alt="case study"
						class="img-fluid no-shadow" />
				</div>
			</div>
			<div class="col-lg-6 block-1 v-center">
				<div class="common-heading inner-heading text-l pl25">
					<span i18n>About The Project</span>
					<h2 i18n>Ascentis Homepage Web Design</h2>
					<p i18n>As a part of rebranding process and in order to increase traffic and generate more leads, our
						client - Ascentis - wants to redesign their website.</p>
					<p i18n>They want to have clean & clear design but still looks modern and elegant. The keywords they
						give us are <i>Simple, Modern, Pop and Elegance</i>.</p>
					<p><strong> Illustrator | Figma | Photoshop | MockFlow </strong></p>
				</div>
				<!--div class="project-platform mt60 pl25">
						<div class="project-platform-used -shadow"><a href="#"><img src="assets/images/icons/android.svg"
									alt="android"></a></div>
						<div class="project-platform-used -shadow"><a href="#"><img src="assets/images/icons/apple.svg"
									alt="apple"></a></div>
					</div-->
			</div>
		</div>
	</div>
</section>
<!--End Case Study About-->
<!--Start Solutions-->
<section class="case-study pad-tb bg-gradient5 deep-dark">
	<div class="container">
		<div class="row">
			<div class="col-lg-12 block-1 v-center">
				<div class="common-heading inner-heading text-l pr25">
					<span i18n>Solutions</span>
					<h2 i18n>How We Work</h2>
					<p i18n>We talk to our client to understand their expectation on this rebranding mission. We ask them
						for the
						branding guideline and simultaneously, we
						analyze their current website and sketch out their ideas and we work together
						until that sketch becomes our final interactive prototype.
					</p>
					<p i18n>Soon enough, our designers come in and
						collaborate on the vision then created 2 different look-and-feel homepages so that they
						could pick one. We then adapt that style into all other pages and on each page finish, we
						check and fine-tune it with them. <br><br>
					</p>
				</div>
				<div class="card-nx row" style="justify-content: center; display:flex;">
					<div class="col-lg-3 col-md-6 col-sm-6 col-6 mt30">
						<div class="icon-card- hfull">
							<img src="assets/images/icons/branding.svg" alt="case study" class="img-fluid">
							<h4>UX Prototyping Design</h4>
						</div>
					</div>
					<div class="col-lg-3 col-md-6 col-sm-6 col-6 mt30">
						<div class="icon-card- hfull">
							<img src="assets/images/icons/logo-and-branding.svg" alt="case study" class="img-fluid">
							<h4>UI/UX Graphic Design</h4>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!--End Solutions-->
<div class="case-study pad-tb">
	<div class="container">
		<div class="row">
			<div class="col-lg-12">
				<div class="porto-slide owl-carousel">
					<div class="screen-splits"> <img src="assets/images/case-study/ascentis-web-1.jpg" alt="case study"
							class="img-fluid" /></div>
					<div class="screen-splits"> <img src="assets/images/case-study/ascentis-web-2.jpg" alt="case study"
							class="img-fluid" /></div>
					<div class="screen-splits"> <img src="assets/images/case-study/ascentis-web-3.jpg" alt="case study"
							class="img-fluid" /></div>
					<div class="screen-splits"> <img src="assets/images/case-study/ascentis-web-4.jpg" alt="case study"
							class="img-fluid" /></div>
					<div class="screen-splits"> <img src="assets/images/case-study/ascentis-web-5.jpg" alt="case study"
							class="img-fluid" /></div>
					<div class="screen-splits"> <img src="assets/images/case-study/ascentis-web-6.jpg" alt="case study"
							class="img-fluid" /></div>
				</div>
			</div>
		</div>
	</div>
</div>

<!--Start Navigate-->
<section class="testinomial-section pad-tb">
	<div class="container">
		<div class="row">
			<div class="col-6 v-center">
				<a [routerLink]="['/', 'portfolio', previousLink]" class="nav_link" i18n>&laquo; Previous</a>
			</div>
			<div class="col-6 v-center">
				<a [routerLink]="['/', 'portfolio', nextLink]" class="nav_link next_nav_link" i18n>Next &raquo;</a>
			</div>
		</div>
	</div>
</section>
<!--End Navigate-->

<!--Start Testinomial-->
<app-testinomial-component></app-testinomial-component>
<!--End Testinomial-->
<!--Breadcrumb Area-->
<section class="breadcrumb-area banner-5">
  <div class="text-block">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 v-center">
          <div class="bread-inner">
            <div class="bread-menu">
              <ul>
                <li><a [routerLink]="['/']" i18n>Home</a></li>
                <li><a [routerLink]="['/portfolio' , '']" i18n>Portfolio</a></li>
              </ul>
            </div>
            <div class="bread-title">
              <h1 i18n>Men's Mental Health Foundation site</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Breadcrumb Area-->

<!--Start Case Study About-->
<section class="case-study pad-tb">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="image-block upset">
          <img src="assets/images/portfolio/image-14.jpg" alt="case study" class="img-fluid no-shadow" />
        </div>
      </div>
      <div class="col-lg-6 block-1 v-center">
        <div class="common-heading inner-heading text-l pl25">
          <span i18n>About The Project</span>
          <h2 i18n>A Stunning Wordpress Site</h2>
          <p i18n>MMHF is a new website providing resources and support for men's mental health in Australia, covering topics
            such as depression, anxiety, and stress.</p>
          <p i18n>MMHF provides practical mental health advice and a directory of local support groups and services, helping
            visitors manage and improve their mental health.</p>
          <p i18n>Visitors can donate directly through the MMHF site to support their efforts in promoting men's mental
            health and reducing stigma around mental health issues in Australia.</p>
          <p><strong> Wordpress 6.x | WPBakery PageBuilder | Stripe | Figma </strong></p>
        </div>
        <div class="project-platform mt60 pl25">
          <div class="project-platform-used -shadow"><a href="https://mmhf.dicom-interactive.com/" target="blank"><img
                src="assets/images/icons/www.png" alt="android"></a>
          </div>
          <div><strong> ⤺ </strong><span style="font-size: smaller;" i18n>Click here to visit the site</span></div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Case Study About-->
<section class="case-study pad-tb bg-gradient6 deep-dark">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <div class="common-heading">
          <span i18n>Service Features</span>
          <h2 class="mb30" i18n>As per client's need, we have developed a dynamic and mobile-friendly Wordpress site.
          </h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <h4 class="mt20 mb10" i18n>Simple Animation and Niche Layout</h4>
        <ul class="list-style-">
          <li i18n>To create an engaging and visually appealing user experience, we designed a simple animation and niche
            layout. This feature ensures that visitors can easily navigate the site and access the information they need
            to improve their mental health.</li>
        </ul>
      </div>
      <div class="col-lg-6">
        <h4 class="mt20 mb10" i18n>Stripe Payment Gateway</h4>
        <ul class="list-style-">
          <li i18n>To enable visitors to donate directly to the foundation through the site, we integrated the Stripe payment
            gateway. This feature ensures that the foundation can receive donations seamlessly and securely, furthering
            their mission to promote men's mental health in Australia.</li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <h4 class="mt20 mb10" i18n>Responsive and Mobile-Friendly</h4>
        <ul class="list-style-">
          <li i18n>To ensure that the site is accessible to as many people as possible, we made it responsive and
            mobile-friendly. This feature enables visitors to access the site's resources from any device, whether it's
            a desktop computer, tablet, or smartphone.
          </li>
        </ul>
      </div>
      <div class="col-lg-6">
        <h4 class="mt20 mb10">SEO</h4>
        <ul class="list-style-">
          <li i18n>To increase the site's visibility and search engine rankings, we implemented SEO best practices. This
            feature improves the site's chances of being discovered by those searching for mental health resources and
            support in Australia.</li>
        </ul>
      </div>
    </div>
  </div>
</section>
<!--Start Project Challenges-->
<section class="case-study pad-tb">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-sm-6">
        <h2 class="mt20 mb20" i18n>Challenges</h2>
        <p i18n>We faced several challenges while building the MMHF (Men's Mental Health Foundation) Wordpress site,
          including:<br><br></p>
        <p style="text-indent: 15px;" i18n>➟ Not being the original creators of the site: As we were not involved in the
          site's initial development, we had to spend extra time understanding the existing codebase and structure to
          make any necessary changes and updates.<br><br></p>
        <p style="text-indent: 15px;" i18n>➟ Timezone differences: With our team in Saigon and the client based in Melbourne,
          we had to navigate a significant timezone difference. This required careful planning and communication to
          ensure that we could collaborate effectively despite the distance.<br><br></p>
        <p style="text-indent: 15px;" i18n>➟ Tight deadline and a long list of requests: The client had a tight deadline for
          the project, and there was also a long list of requests to be completed. This required us to work efficiently
          and prioritize tasks to ensure that we met the client's expectations and delivered a high-quality site on
          time.<br><br></p>
        <p i18n>Despite these challenges, we were able to work closely with the client, maintain clear communication, and
          deliver a website that met their needs and exceeded their expectations.</p>
      </div>
      <div class="col-lg-6 col-sm-6">
        <div class="project-view m-mt30">
          <img src="assets/images/case-study/goen-view.png" alt="case study" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Project Challenges-->
<!--Start Solutions-->
<section class="case-study pad-tb bg-gradient5 deep-dark">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 block-1 v-center">
        <div class="common-heading inner-heading text-l pr25">
          <span i18n>Solutions</span>
          <h2 i18n>How We Work</h2>
          <h4 i18n>We chose to build our client's website using WordPress, as it met their requirements and provided the
            necessary functionality without the need for additional technologies.<br><br></h4>
          <p i18n>When we were brought into the project, MMHF's website was already about 50% complete, and we were handed a
            detailed 28-page brief outlining their requirements and expectations for the site. Despite not being
            involved in the initial development phase, our team worked closely with MMHF to ensure that the final
            product met their expectations and was consistent with their original vision.</p>
          <p i18n>We started by conducting a thorough review of the existing work, and worked collaboratively with MMHF to
            identify gaps and areas for improvement. We established clear milestones and timelines for completing the
            remaining work, and worked efficiently to ensure that we met the tight deadline.
          </p>
          <p i18n>Throughout the project, we maintained close communication with MMHF to ensure that we were on the right
            track, and provided regular updates on our progress. Our team was committed to delivering a high-quality
            website that met all of MMHF's needs, and we were able to successfully launch the site on time and within
            budget.
          </p>
          <p i18n>We also provided MMHF with comprehensive training and support to ensure that they could effectively manage
            and maintain their website moving forward. Our team is dedicated to delivering exceptional results for our
            clients, regardless of when we are brought into a project or the level of detail provided in the initial
            brief.</p>
        </div>
        <div class="card-nx row">
          <div class="col-lg-3 col-md-6 col-sm-6 col-6 mt30">
            <div class="icon-card- hfull">
              <img src="assets/images/icons/logo-and-branding.svg" alt="case study" class="img-fluid">
              <h4>UI/UX Graphic Design</h4>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-6 mt30">
            <div class="icon-card- hfull">
              <img src="assets/images/icons/branding.svg" alt="case study" class="img-fluid">
              <h4>Front-end Development</h4>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-6 mt30">
            <div class="icon-card- hfull">
              <img src="assets/images/icons/back.svg" alt="case study" class="img-fluid">
              <h4>Back-end/API Development</h4>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-6 mt30">
            <div class="icon-card- hfull">
              <img src="assets/images/icons/price.svg" alt="case study" class="img-fluid">
              <h4>Stripe Payment Gateway Integration</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Solutions-->
<div class="case-study pad-tb">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="porto-slide owl-carousel">
          <div class="screen-splits"> <img src="assets/images/case-study/mmhf-web-1.png" alt="case study"
              class="img-fluid" style="border-style: solid; border-width: thin;" /></div>
          <div class="screen-splits"> <img src="assets/images/case-study/mmhf-web-2.png" alt="case study"
              class="img-fluid" style="border-style: solid; border-width: thin;" /></div>
          <div class="screen-splits"> <img src="assets/images/case-study/mmhf-web-3.png" alt="case study"
              class="img-fluid" style="border-style: solid; border-width: thin;" /></div>
          <div class="screen-splits"> <img src="assets/images/case-study/mmhf-web-4.png" alt="case study"
              class="img-fluid" style="border-style: solid; border-width: thin;" /></div>
        </div>
      </div>
    </div>
  </div>

  <!--Start Navigate-->
  <section class="testinomial-section pad-tb">
    <div class="container">
      <div class="row">
        <div class="col-6 v-center">
          <a [routerLink]="['/', 'portfolio', previousLink]" class="nav_link" i18n>&laquo; Previous</a>
        </div>
        <div class="col-6 v-center">
          <a [routerLink]="['/', 'portfolio', nextLink]" class="nav_link next_nav_link" i18n>Next &raquo;</a>
        </div>
      </div>
    </div>
  </section>
  <!--End Navigate-->

  <!--Start Testinomial-->
  <app-testinomial-component></app-testinomial-component>
  <!--End Testinomial-->


  <div class="statistics-wrap bg-gradient5">
    <div class="container">
      <div class="row small t-ctr mt0">
        <div class="col-lg-3 col-sm-6">
          <div class="statistics">
            <div class="statistics-img">
              <img src="assets/images/icons/deal.svg" alt="happy" class="img-fluid">
            </div>
            <div class="statnumb">
              <span class="counter">40</span><span>+</span>
              <p i18n>Happy Clients</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6">
          <div class="statistics">
            <div class="statistics-img">
              <img src="assets/images/icons/computers.svg" alt="project" class="img-fluid">
            </div>
            <div class="statnumb counter-number">
              <span class="counter">60</span><span>+</span>
              <p i18n>Projects Done</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6">
          <div class="statistics">
            <div class="statistics-img">
              <img src="assets/images/icons/worker.svg" alt="work" class="img-fluid">
            </div>
            <div class="statnumb">
              <span class="counter">50</span><span>k</span>
              <p i18n>Hours Worked</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6">
          <div class="statistics mb0">
            <div class="statistics-img">
              <img src="assets/images/icons/customer-service.svg" alt="support" class="img-fluid">
            </div>
            <div class="statnumb">
              <span class="counter">24</span><span>/</span><span class="counter">7</span>
              <p i18n>Support Available</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--End Hero-->

// https://stackoverflow.com/questions/57720654/after-change-route-jquery-not-working-when-refresh-again-then-work-correctly
// core
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Location } from '@angular/common';
import { NgForm } from '@angular/forms';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
// self
import { Quote } from '../model/quote.model';
import { ScriptService, LangService, ApiService, MetaDataService } from '../services';
import { Contact } from '../model/contact.model';
import { RecaptchaService } from '../services/recaptcha.service';
import { GeolocationService } from '../geolocation.service';
import { SharedService } from '../services/sharedService.service';
declare var grecaptcha: any;
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
})
export class ContactComponent extends MetaDataService implements OnInit, OnDestroy {
  isLoad = false;
  public current_lang: any = {};
  public quote: Quote = new Quote();
  public contact: Contact = new Contact();
  countryCode: string = '+61';
  countryName: string = 'aus';
  subject: string = '';
  recaptchaResponse: string | null = null;
  public userLanguage: string;
  public userCountry: string;
  filesToUpload
  fileInputError
  fileChose1
  fileChose2
  currentLanguage: any;
  selectedSubject: string;
  showWebSection: boolean;
  showAppSection: boolean;
  widgetId: string = '';
  files: File[];

  onCountryChange(event: any) {
    this.quoteForm.get('countryCode')!.setValue(`+${event.dialCode}`);
    this.quoteForm.get('countryName')!.setValue(event.iso2);
  }

  quoteForm = this.formBuilder.group({
    name: new FormControl(this.quote.name, [Validators.required]),
    email: new FormControl(this.quote.email, [Validators.required]),
    countryName: new FormControl(this.countryName),
    countryCode: new FormControl(this.countryCode),
    phone: new FormControl(this.quote.phone, [Validators.required]),
    companyName: new FormControl(this.quote.companyName),
    subject: new FormControl(this.quote.subject, [Validators.required]),
    deadline: new FormControl(this.quote.deadline),
    contentPlan: new FormControl(this.quote.contentPlan),
    pageRequest: new FormControl(this.quote.pageRequest),
    featureRequest: new FormControl(this.quote.featureRequest),
    message: new FormControl(this.quote.message, [Validators.required]),
    files: new FormControl(this.quote.files),
  });

  otherFormGroup = this.formBuilder.group({
    name: new FormControl(this.quote.name, [Validators.required]),
    email: new FormControl(this.quote.email, [Validators.required]),
    countryName: new FormControl(this.countryName),
    countryCode: new FormControl(this.countryCode),
    phone: new FormControl(this.quote.phone, [Validators.required]),
    subject: new FormControl(this.quote.subject, [Validators.required]),
    message: new FormControl(this.quote.message, [Validators.required]),
  });

  submitted = false;
  companyName: string;
  deadline: string;
  pageRequest: string;
  featureRequest: string;
  message: string;

  constructor(
    private scriptService: ScriptService,
    private langService: LangService,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private recaptchaService: RecaptchaService,
    private geolocation: GeolocationService,
    private sharedService: SharedService,
    private metadataService: MetaDataService,
    private location: Location,
    private router: Router,
    title: Title,
    meta: Meta,
  ) {
    super(title, meta);
    // this.title.setTitle('Contact | Dicom Interactive');
    // this.meta.updateTag({ name: 'description', content: '' });
    this.updateTags(
      'Contact',
      'Tell us about your project, and we\'ll provide a customized quote.',
      this.location.prepareExternalUrl(this.router.url),
    );

    const contactSubject = localStorage.getItem('contact_subject');
    this.quote.subject = contactSubject || '';
    // this._loadScript("assets/js/jquery-2.0.0.min.js");
    // this._loadScript("assets/js/bootstrap.js");
    // this._loadScript("assets/vendor/owl-carousel/js/owl.carousel.min.js");
    // this._loadScript("assets/vendor/revslider/js/jquery.themepunch.tools.min.js");
    // this._loadScript("assets/vendor/revslider/js/jquery.themepunch.revolution.min.js");
    // this._loadScript("assets/js/init.js");
    // this._loadScript("assets/js/slide.js");
    this.scriptService.load(this.scriptService.INIT).then(() => {
      this.isLoad = true;
    });
    this.geolocation.getClientLocale().subscribe((data) => {
      this.userLanguage = data.language;
      this.userCountry = data.country;
    });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        // Reset this.quote.subject to an empty string or handle as needed
        this.quote.subject = '';
        this.selectedSubject = '';
      }
    });
    // setTimeout(() => { this.isLoad = true }, 1000);
  }
  ngOnDestroy() {
    this.sharedService.changeSubject('');
  }

  async ngOnInit() {
    const storedSubject = localStorage.getItem('contact_subject');
    if (storedSubject) {
      this.quote.subject = storedSubject;
    }
    await this.recaptchaService.loadRecaptcha();
    this.widgetId = grecaptcha.render('recaptcha', {
      sitekey: environment.recaptcha,
      callback: this.onRecaptchaSuccess.bind(this),
      'expired-callback': this.onRecaptchaExpired.bind(this),
    });
    this.current_lang = await this.langService.loadLang();
    this.sharedService.currentSubject.subscribe((subject) => {
      if (subject) {
        this.quote.subject = subject;
        this.selectedSubject = subject;
      } else {
        this.quote.subject = '';
        this.selectedSubject = '';
      }
    });
    this.currentLanguage = this.userLanguage
  }

  resetErrors(contactForm: NgForm) {
    if (grecaptcha && grecaptcha.reset) {
      grecaptcha.reset(this.widgetId);
      this.submitted = false;
    }
    const nameValue = contactForm.controls['name'].value ? contactForm.controls['name'].value.trim() : '';
    const emailValue = contactForm.controls['email'].value ? contactForm.controls['email'].value.trim() : '';
    const phoneValue = contactForm.controls['phone'].value ? contactForm.controls['phone'].value.trim() : '';
    const MessageValue = contactForm.controls['message'].value ? contactForm.controls['message'].value.trim() : '';
    if (emailValue === '') {
      contactForm.controls['email'].reset();
    }
    if (nameValue === '') {
      contactForm.controls['name'].reset();
    }
    if (phoneValue === '') {
      contactForm.controls['phone'].reset();
    }
    if (MessageValue === '') {
      contactForm.controls['message'].reset();
    }
  }

  onRecaptchaSuccess(response: string) {
    this.recaptchaResponse = response;
    (document.getElementById('form-submit') as HTMLButtonElement).disabled = false;
  }
  onRecaptchaExpired() {
    (document.getElementById('form-submit') as HTMLButtonElement).disabled = true;
  }

  onRecaptchaName(contactForm: NgForm) {
    this.quote.name = '';
    this.quote.email = '';
    this.quote.message = '';
    this.quote.phone = '';
    this.quote.subject = '';
    contactForm.resetForm(new Quote());
  }

  async onSubmit(contactForm: NgForm) {
    try {
      this.submitted = true;
      const lang = this.userLanguage;
      let formData: any;
      if (contactForm.invalid) {
        return;
      }
      if (!this.recaptchaResponse) {
        if (lang === 'en'){
          alert('Please complete captcha verification to submit your request.');
        } else {
          alert('Vui lòng hoàn thành Captcha để gửi yêu cầu của bạn.')
        }
        return;
      }
      if (this.quote.subject === 'web') {
        formData = this.quoteForm.value;
      } else {
        formData = this.otherFormGroup.value;
      }
      this.quote = contactForm.value;
      this.filesToUpload = this.files;
      this.quote.countryCode = this.quoteForm.get('countryCode')!.value;
      this.quote.countryName = this.quoteForm.get('countryName')!.value;
      await this.apiService.handleFreeQuoteRequest(this.quote, this.filesToUpload, lang);
      if (lang === 'en') {
      alert("We've received your request! Thanks for your quote request! We'll be in touch soon to discuss your project further. Feel free to reach out at sales@dicom-interactive.com if you have any questions.");
      } else {
        alert('Dicom Interactive đã nhận được yêu cầu của bạn. Cảm ơn bạn đã gửi yêu cầu đến chúng tôi! Chúng tôi sẽ liên hệ sớm nhất có thể để trao đổi thêm về dự án. Nếu có thắc mắc hoặc cần hỗ trợ thêm, vui lòng liên hệ thông qua sales@dicom-interactive.com.')
      }
    } catch {
      return;
    }
    this.handleResetField();
    if (grecaptcha && grecaptcha.reset) {
      grecaptcha.reset(this.widgetId);
      this.submitted = false;
    }
    this.onRecaptchaName(contactForm);
    this.onRecaptchaExpired();
  }

  private handleResetField() {
    // reset error
    this.fileInputError = "";
    this.fileChose1 = "";
    this.fileChose2 = "";
  }

  /**
 * for auto set position
 * @param package apply package
 */
  async selectPackage(pkg: string) {
    // because can not binding while open dialog, we have to delay biding
    setTimeout(() => {
      this.handleResetField();
      this.quote.subject = pkg;
    }, 400);
  }


  /**
   * handle input file
   * @param files file select
   * @returns
   */
  handleFileInput(files: FileList) {
    this.filesToUpload = files;
    if (files.length > 2) {
      const lang = this.userLanguage;
      if (lang === 'en') {
        return this.fileInputError = "Maximum is 2 files"
      } else {
        return this.fileInputError = "Tải tối đa 2 file"
      }
    }
    console.log("this.files:: ", files);

    // binding filename
    let fileChose1 = files.item(0).name;
    let fileChose2 = files?.item(1)?.name;
    this.files = Array.from(files);
    if (this.verifyFileExtension(fileChose1) && this.verifyFileExtension(fileChose2)) {
      this.handleResetField();
      this.fileChose1 = fileChose1;
      this.fileChose2 = fileChose2;
      return;
    }

    this.handleResetField();
    return this.fileInputError = "Please upload only pdf, doc, docx file"
  }

  private verifyFileExtension(fileName: string) {
    if (!fileName) return true;
    let extension = fileName.split(".").pop();
    if (['pdf', 'docx', 'doc'].indexOf(extension) != -1) {
      return true;
    }
    return false;
  }
}

<!--Start Preloader -->
<div class="onloadpage" id="page_loader">
  <div class="pre-content">
    <div class="logo-pre"><img src="assets/images/logo_small.png" alt="Logo" class="img-fluid" /></div>
    <div class="pre-text-"><span>Leading Software Development Company in Vietnam</span></div>
  </div>
</div>
<!--End Preloader -->
<!--Start Header -->
<header class="nav-bg-w main-header navfix fixed-top menu-white">
  <div class="container-fluid m-pad">
    <div class="menu-header">
      <div class="dsk-logo"><a class="nav-brand" [routerLink]="['/']">
          <img src="assets/images/logo_small.png" alt="Logo" class="mega-white-logo" />
          <img src="assets/images/logo_small.png" alt="Logo" class="mega-darks-logo" />
        </a></div>
      <div class="custom-nav" role="navigation">
        <ul class="nav-list">
          <li><a [routerLink]="['/']" class="menu-links" i18n>Home</a></li>
          <li><a [routerLink]="['/portfolio']" class="menu-links" i18n>Portfolio</a></li>
          <li class="sbmenu rpdropdown"><a [routerLink]="[]" class="menu-links" i18n>Services</a>
            <div class="nx-dropdown menu-dorpdown">
              <div class="sub-menu-section">
                <div class="sub-menu-center-block">
                  <div class="sub-menu-column smfull">
                    <ul>
                      <li><a [routerLink]="['/services/web-development', '']" i18n>Web Development</a> </li>
                      <li><a [routerLink]="['/services/mobile-app-development', '']" i18n>Mobile App Development</a> </li>
                      <li><a [routerLink]="['/services', 'graphic-design', '']" i18n>Graphic Design</a> </li>
                      <li><a [routerLink]="['/services/digital-marketing', '']" i18n>Digital Marketing</a> </li>
                      <li><a [routerLink]="['/services/offshore-development-center', '']" i18n>Offshore Development
                          Center</a> </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="sbmenu rpdropdown"><a [routerLink]="[]" class="menu-links" i18n>About Us</a>
            <div class="nx-dropdown menu-dorpdown">
              <div class="sub-menu-section">
                <div class="sub-menu-center-block">
                  <div class="sub-menu-column smfull">
                    <ul>
                      <li><a [routerLink]="['/about-us']" i18n>About Us</a> </li>
                      <li><a [routerLink]="['/our-team']">Our Team</a> </li>
                      <li><a [routerLink]="['/software-development-melbourne']" i18n>Melbourne Team</a> </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li><a [routerLink]="['/career']" class="menu-links" i18n>Career</a></li>
          <li><a [routerLink]="['/contact']" class="menu-links" i18n>Contact</a></li>
          <li><a [routerLink]="['/blog']" class="menu-links" i18n>Blog</a></li>
          <li class="sbmenu rpdropdown language-menu"><a [routerLink]="[]" class="menu-links lang-option">
            <img *ngIf="userLanguage=='en'" src="assets/images/flags/gb.svg" alt="English" class="flags-size">
            <img *ngIf="userLanguage=='vn'" src="assets/images/flags/vn.svg" alt="Vietnamese" class="flags-size">
            {{userLanguage}}</a>
            <div class="nx-dropdown menu-dorpdown language-options">
              <div class="sub-menu-section">
                <div class="sub-menu-center-block">
                  <div class="sub-menu-column smfull">
                    <ul>
                      <li *ngIf="userLanguage!=='en'"><a [routerLink]="[]" role="button" (click)="changeLanguage('en', $event)"><img src="assets/images/flags/gb.svg" alt="English" class="flags-size"> EN</a> </li>
                      <li *ngIf="userLanguage!=='vn'"><a [routerLink]="[]" role="button" (click)="changeLanguage('vn', $event)"><img src="assets/images/flags/vn.svg" alt="Vietnamese" class="flags-size"> VN</a> </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <!-- <li><a [routerLink]="[]" class="menu-links right-bddr">&nbsp;</a></li> -->
          <!--menu right border-->
          <li class="contact-show"><a [routerLink]="[]" class="btn-round- trngl btn-br bg-btn"><i
                class="fas fa-phone-alt"></i></a>
            <div class="contact-inquiry">
              <div class="contact-info-">
                <div class="inquiry-card-nn">
                  <ul>
                    <li><a href="tel:+61390187886">
                        <img src="assets/images/flags/au.svg" alt="Australia office" class="flags-size foem-img">
                        (+61) 390 1878 86 </a>
                    </li>
                    <!-- <li><a href="tel:+61402752523">
                        <img src="assets/images/flags/au.svg" alt="Australia office" class="flags-size">
                        Steven: (+61) 402 7525 23 </a>
                    </li> -->
                    <li><a href="tel:+842871065144">
                        <img src="assets/images/flags/vn.svg" alt="Vietnam office" class="flags-size">
                        (+84) 287 1065 144</a></li>
                    <!-- <li><a href="tel:+84907506614">
                        <img src="assets/images/flags/vn.svg" alt="Vietnam office" class="flags-size">
                        Daniel: (+84) 907 5066 14</a></li> -->
                    <!-- <li><a href="tel:+84932762016"><img src="assets/images/flags/vn.svg" alt="Vietnam office"
                          class="flags-size">
                        Suzanne: (+84) 932 7620 16</a></li> -->
                    <li>
                      <a href="whatsapp://send?text=Hello&phone=+84907506614">
                        <img src="assets/images/flags/whatsapp-square.svg" alt="Phone" class="flags-size">
                        Dicom Interactive</a>
                    </li>
                    <li>
                      <a href="mailto:sales@dicom-interactive.com">
                        <img src="assets/images/flags/envelope-solid.svg" alt="Email" class="flags-size">
                        Sales@dicom-interactive.com</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
          <li><a [routerLink]="['/contact']" class="btn-br bg-btn3 btshad-b2 lnk" i18n>Request A Quote <span
                class="circle"></span></a> </li>
        </ul>
      </div>
      <div class="mobile-menu2">
        <ul class="mob-nav2">
          <li><a href="#" class="btn-round- trngl btn-br bg-btn btshad-b1" data-toggle="modal"
              data-target="#menu-popup"><i class="fas fa-envelope-open-text"></i></a></li>
          <li class="navm-"> <a class="toggle" href="#"><span></span></a></li>
        </ul>
      </div>
    </div>
    <!--Mobile Menu-->
    <nav id="main-nav">
      <ul class="first-nav">
        <li><a [routerLink]="['/']" i18n>Home</a></li>
        <li><a [routerLink]="['/portfolio']" i18n="@@portfolio_mobile">Portfolio</a></li>
        <li><a href="#" i18n="@@service_mobile">Services</a>
          <ul>
            <li><a [routerLink]="['/services', 'web-development', '']" i18n>Web Development</a> </li>
            <li><a [routerLink]="['/services', 'mobile-app-development', '']" i18n>Mobile App Development</a> </li>
            <li><a [routerLink]="['/services', 'graphic-design', '']" i18n>Graphic Design</a> </li>
            <li><a [routerLink]="['/services', 'digital-marketing', '']" i18n>Digital Marketing</a> </li>
            <li><a [routerLink]="['/services', 'offshore-development-center', '']" i18n>Offshore Development</a></li>
          </ul>
        </li>
        <li><a href="#" i18n>About Us</a>
          <ul>
            <li><a [routerLink]="['/about-us']" i18n>About Us</a> </li>
            <li><a [routerLink]="['/our-team']">Our Team</a> </li>
            <li><a [routerLink]="['/software-development-melbourne']" i18n>Melbourne Team</a> </li>
          </ul>
        </li>
        <li><a [routerLink]="['/career']" i18n>Career</a></li>
        <li><a [routerLink]="['/contact']" i18n="@@contact_mobile">Contact</a></li>
        <li><a [routerLink]="['/blog']" i18n="@@blog_mobile">Blog</a></li>
        <li>
            <a href="#" class="menu-links">
              <img *ngIf="userLanguage=='en'" src="assets/images/flags/gb.svg" alt="English" class="flags-size">
              <img *ngIf="userLanguage=='vn'" src="assets/images/flags/vn.svg" alt="Vietnamese" class="flags-size">
              {{userLanguage | uppercase}}
            </a>
            <ul>
              <li *ngIf="userLanguage!=='en'"><a href="#" role="button" (click)="changeLanguage('en', $event)"><img src="assets/images/flags/gb.svg" alt="English" class="flags-size"> EN</a> </li>
              <li *ngIf="userLanguage!=='vn'"><a href="#" role="button" (click)="changeLanguage('vn', $event)"><img src="assets/images/flags/vn.svg" alt="Vietnamese" class="flags-size"> VN</a> </li>
            </ul>
        </li>
      </ul>
      <ul class="bottom-nav">
        <li class="prb">
          <a href="tel:+84907506614">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 384">
              <path
                d="M353.188,252.052c-23.51,0-46.594-3.677-68.469-10.906c-10.719-3.656-23.896-0.302-30.438,6.417l-43.177,32.594
                c-50.073-26.729-80.917-57.563-107.281-107.26l31.635-42.052c8.219-8.208,11.167-20.198,7.635-31.448
                c-7.26-21.99-10.948-45.063-10.948-68.583C132.146,13.823,118.323,0,101.333,0H30.813C13.823,0,0,13.823,0,30.813
                C0,225.563,158.438,384,353.188,384c16.99,0,30.813-13.823,30.813-30.813v-70.323C384,265.875,370.177,252.052,353.188,252.052z" />
            </svg>
          </a>
        </li>
        <li class="prb">
          <a href="mailto:sales@dicom-interactive.com">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path
                d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z" />
              <path d="M0 0h24v24H0z" fill="none" />
            </svg>
          </a>
        </li>
        <li class="prb">
          <a href="whatsapp://send?text=Hello&phone=+84907506614">
            <svg viewBox="0 0 28 28" class="whatsapp-ico">
              <path
                d=" M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z"
                fill-rule="evenodd"></path>
            </svg>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</header>
<!--Mobile contact-->
<div class="popup-modal1">
  <div class="modal" id="menu-popup">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <div class="common-heading">
            <h4 class="mt0 mb0" i18n>Write a Message</h4>
          </div>
          <button type="button" class="closes" data-dismiss="modal">&times;</button>
        </div>
        <!-- Modal body -->
        <div class="modal-body">
          <div class="form-block fdgn2 mt10 mb10">
            <form #contactForm="ngForm" name="feedback-form">
              <div class="fieldsets row">
                <div class="col-md-12">
                  <input type="text" name="name" id="name" [ngModel]="contact.name" #name="ngModel"
                    placeholder="Enter name" oninput="this.value = this.value.replace(/[\s]+/g, ' ',)" minlength="6" required i18n-placeholder>
                  <div *ngIf="submitted || (name.invalid && (name.dirty || name.touched))">
                    <div *ngIf="name.errors?.required" class="help-block with-errors" i18n>Name is required</div>
                    <div *ngIf="name.errors?.minlength" class="help-block with-errors" i18n>Name must be at least 6 characters</div>
                  </div>
                </div>
                <div class="col-md-12">
                  <input type="email" id="email" name="email" [ngModel]="contact.email" #email="ngModel" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$"
                    placeholder="Enter email" i18n-placeholder required email>
                  <div *ngIf="submitted || (email.invalid && (email.dirty || email.touched))">
                    <div *ngIf="email.errors?.required" class="help-block with-errors" i18n>Email is required</div>
                    <div *ngIf="email.errors?.pattern" class="help-block with-errors" i18n>Email is invalid</div>
                  </div>
                </div>
                <div class="col-md-12 phone">
                  <input type="text" id="mobile" name="phone" id="phone" i18n-placeholder [ngModel]="contact.phone"
                    ng2TelInput [ng2TelInputOptions]="{'preferredCountries': ['au', 'vn']}"
                    pattern="[0-9]{6,12}" (countryChange)="onCountryChange($event)" #phone="ngModel"
                    placeholder="Enter mobile" required data-error="Please fill Out">
                  <div *ngIf="submitted || (phone.invalid && (phone.dirty || phone.touched))">
                    <div *ngIf="phone.errors?.required" class="help-block with-phone" i18n>Mobile is required</div>
                    <div *ngIf="phone.errors?.pattern" class="help-block with-phone" i18n>Invalid Mobile Number</div>
                  </div>
                </div>
                <div class="col-md-12">
                  <input id="Dtype" name="subject" id="subject" [ngModel]="contact.subject" #subject="ngModel"
                    placeholder="Enter subject" i18n-placeholder required>
                  <div *ngIf="submitted || (subject.invalid && (subject.dirty || subject.touched))">
                    <div *ngIf="subject.errors?.required" class="help-block with-errors" i18n>Subject is required</div>
                  </div>
                </div>
                <div class="col-md-12">
                  <textarea id="message" rows="5" name="message" id="message" [ngModel]="contact.message"
                    #message="ngModel" placeholder="Enter your message" i18n-placeholder required></textarea>
                  <div *ngIf="submitted || (message.invalid && (message.dirty || message.touched))">
                    <div *ngIf="message.errors?.required" class="help-block with-errors" i18n>Message is required</div>
                  </div>
                  <div class="form-group from-recaptcha">
                    <div class="g-recaptcha" ng-model="g-recaptcha-response" id="grecapt"></div>
                  </div>
                </div>
              </div>
              <div class="fieldsets mt20 pb20">
                <button type="button" name="submit" id="form-submit-app" class="btn lnk btn-main bg-btn" (click)="onSubmitContact(contactForm)"
                [disabled]="!submitted" i18n>Submit <i class="fas fa-chevron-right fa-icon"></i><span
                  class="circle"></span></button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Mobile contact-->

<router-outlet (activate)="onActivate($event)"></router-outlet>



<!--Start Footer-->
<footer>
  <div class="footer-row1">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="email-subs">
            <h3 i18n>Get New Insights Weekly</h3>
            <p i18n>Want to Be Always Informed?</p>
          </div>
        </div>
        <div class="col-lg-6 v-center">
          <div class="email-subs-form">
            <form #subscribeFrom="ngForm" (ngSubmit)="onSubmit(subscribeFrom)" name="feedback-form">
              <input type="email" id="email" name="email" [ngModel]="subscribe.email" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$"
               #emailref="ngModel" placeholder="Email Your Address" i18n-placeholder required email>
              <button type="submit" name="submit" class="lnk btn-main bg-btn btn-submit">
                <span class="subscribe-text" i18n>Subscribe
                <i class="fas fa-chevron-right fa-icon button-icon"></i><span class="circle"></span></span>
              </button>
            </form>
            <div *ngIf="emailref.errors &&(emailref.touched || emailref.dirty)" class="mt20">
              <div *ngIf="emailref.errors?.required" class="help-block with-errors" i18n>Email is required</div>
              <div *ngIf="emailref.errors?.pattern" class="help-block with-errors" i18n>Email is invalid</div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 v-center">
          <!-- <app-gg-reviews></app-gg-reviews> -->
          <!-- <div id="rplg"></div>
                <script type="text/javascript">
                rplg_embed = window.rplg_embed || [];
                rplg_embed.push({id: 1239});
                (function() {
                    var mc = document.createElement('script');
                    mc.type = 'text/javascript';
                    mc.async = true;
                    mc.src = 'https://embed.richplugins.com/static/js/embed.js';
                    var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(mc, s.nextSibling);
                })();
                </script>
                <a href="https://richplugins.com" class="rplg-cr" target="_blank">Reviews Powered by RichPlugins.com</a> -->
        </div>
      </div>
    </div>
  </div>
  <div class="footer-row2">
    <div class="container">
      <div class="row justify-content-between">
        <!--div class="col-lg-3 col-sm-6  ftr-brand-pp">
<a class="navbar-brand mt30 mb25" href="#"> <img src="assets/images/logo_small.png" alt="Logo" width="100" /></a>
<p>We could assist you to increase the profits by offering an opportunity to do business together.</p>
<a href="#" class="btn-main bg-btn3 lnk mt20">Become Partner <i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
</div-->
        <div class="col-lg-5 col-sm-6">
          <h5 i18n>Contact Us</h5>
          <ul class="footer-address-list ftr-details">
            <li>
              <span><i class="fas fa-envelope"></i></span>
              <p i18n>Email <span class="phone-app"> <a href="mailto:company@dicom-interactive.com">company@dicom-interactive.com</a></span>
                <span class="phone-app"> <a href="mailto:sales@dicom-interactive.com">sales@dicom-interactive.com</a></span>
              </p>
            </li>
            <li>
              <span><i class="fas fa-phone-alt"></i></span>
              <p i18n>
                Phone
                <span class="phone-app"> <a href="tel:+61390187886">Hotline: (+61) 390 1878 86</a></span>
                <span class="phone-app"> <a href="tel:+842871065144">Hotline: (+84) 287 1065 144</a></span>
                <span class="phone-app"> <a href="tel:+84932762016">Suzanne: (+84) 932 7620 16</a></span>
                <span class="phone-app"> <a href="tel:+84907506614">Daniel: (+84) 907 5066 14</a></span>
                <span class="phone-app"> <a href="tel:+61402752523">Vu (Steven) Quach: (+61) 402 7525 23</a></span>
              </p>
            </li>
            <li>
              <span><i class="fas fa-map-marker-alt"></i></span>
              <p i18n>Address <span class="footer-address"> 18 Dennis St, Lalor, VIC 3075, Australia.</span>
                <span class="footer-address">81 Cách Mạng Tháng Tám St, Bến Thành Ward, Dist 1, HCMC, Vietnam.</span>
                <span class="footer-address">224-226 Nguyễn Phúc Nguyên St, Ward 9, Dist 3, HCMC, Vietnam.</span>
              </p>
            </li>
          </ul>
        </div>
        <div class="col-lg-3 col-sm-6">
          <h5 i18n>Useful Links</h5>
          <ul class="footer-address-list link-hover">
            <li><a [routerLink]="['/services/web-development', '']" i18n>Web Development Service</a></li>
            <li><a [routerLink]="['/services/mobile-app-development', '']" i18n>Mobile App Development Service</a></li>
            <li><a [routerLink]="['/services', 'graphic-design', '']" i18n>Graphic Design Service</a></li>
            <li><a [routerLink]="['/services/digital-marketing', '']" i18n>Digital Marketing Service</a></li>
            <li><a [routerLink]="['/services/offshore-development-center', '']" i18n>Offshore Development Center</a></li>
            <li><a [routerLink]="['/portfolio']" i18n>Our Portfolio</a></li>
            <li><a [routerLink]="['/about-us']" i18n>About Us</a></li>
            <!-- <li><a [routerLink]="['/our-team']">Our Team</a></li> -->
            <li><a [routerLink]="['/contact']" i18n>Contact Us</a></li>
            <li><a [routerLink]="['/blog']" class="menu-links">Blog</a></li>
          </ul>
        </div>
        <div class="col-lg-4 col-sm-6 footer-blog-">
          <h5 i18n>Latest Blogs</h5>
          <div *ngFor="let blog of blogs" class="single-blog-">
            <div class="post-thumb">
              <a [routerLink]="['/', 'blog', blog.urlBlog]" (click)="scrollToTop()">
                <img (click)="incrementView(blog._id)" class="img-blog-thumb" [src]="blog.imgBlog" [alt]="blog.urlBlog" [title]="blog.urlBlog">
              </a>
            </div>
            <div class="content" [title]="blog.title">
              <h4 class="title ellipsis"><a [routerLink]="['/', 'blog', blog.urlBlog]" (click)="scrollToTop()">{{blog.title}}</a></h4>
              <p class="post-meta"><span class="post-date"><i class="far fa-clock"></i>{{ blog.formattedDate }}</span></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-brands">
    <div class="container">
      <h5 style="text-align: center;" i18n>We Are Here And There</h5>
    </div>
  </div>
  <div class="footer-row3">
    <div class="copyright">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="footer-social-media-icons">
              <a href="https://www.facebook.com/dicominteractive" target="blank">
                <i class="fab fa-facebook"></i></a>
              <a href="https://www.linkedin.com/company/dicom-interactive/" target="blank">
                <i class="fab fa-linkedin"></i></a>
            </div>
            <div class="footer-">
              <p>Copyright &copy; {{copyrightYear}} Dicom Interactive. All rights reserved. Designed By <a
                  href="https://dicom-interactive.com" target="blank">Dicom Interactive</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
<!--End Footer-->

<!--Start Testinomial-->
<section class="testinomial-section pad-tb">
	<div class="container">
		<div class="row">
			<div class="col-lg-5 v-center">
				<div class="common-heading text-l">
					<span i18n>Customer Testimonials</span>
					<h2 class="mb0" i18n>What our clients say about Dicom Interactive.</h2>
				</div>
			</div>
			<div class="col-lg-7">
				<div class="owl-carousel testimonial-card-a pl25">
					<div class="testimonial-card">
						<div class="t-text">
							<p i18n>Dicom Interactive is an exceptional IT company that delivers high-quality interactive
								solutions tailored to specific needs.
								Their team is knowledgeable, professional, and reliable, and I highly recommend them to
								anyone seeking top-notch IT services.</p>
						</div>
						<div class="client-thumbs mt30">
							<div class="media v-center">
								<div class="user-image bdr-radius"><img src="assets/images/user-thumb/tim-bos.png"
										alt="tim-bos" class="img-fluid" /></div>
								<div class="media-body user-info v-center">
									<h5>Tim Bos</h5>
									<p>Founder & CEO at ShareRing <small>Australia</small></p>
								</div>
							</div>
						</div>
					</div>
					<div class="testimonial-card">
						<div class="t-text">
							<p i18n>Dicom Interactive is an exceptional IT company that exceeded my expectations. Their
								attention to detail, responsiveness, and dedication to delivering high-quality work were
								truly impressive. I highly recommend them to anyone looking for top-quality IT services
								and solutions.</p>
						</div>
						<div class="client-thumbs mt30">
							<div class="media v-center">
								<div class="user-image bdr-radius"><img src="assets/images/user-thumb/peter-david.png"
										alt="peter" class="img-fluid" /></div>
								<div class="media-body user-info v-center">
									<h5>Peter David
									</h5>
									<p>Co-Principal at Realty <small>Australia</small></p>
								</div>
							</div>
						</div>
					</div>
					<div class="testimonial-card">
						<div class="t-text">
							<p i18n>My company received outstanding services from Dicom.
								Their skillful team brought us remarkable value in both cross-platform development and
								graphic design.
								We will come back for some bigger projects.
								Highly recommended to tech-coms.</p>
						</div>
						<div class="client-thumbs mt30">
							<div class="media v-center">
								<div class="user-image bdr-radius"><img src="assets/images/user-thumb/girl1.jpg"
										alt="girl" class="img-fluid" /></div>
								<div class="media-body user-info v-center">
									<h5>Sa Tran</h5>
									<p>CEO of GoEn, <small>Japan</small></p>
								</div>
							</div>
						</div>
					</div>
					<div class="testimonial-card">
						<div class="t-text">
							<p i18n>Dicom-interactive is an exceptional agency that can combine technology and client
								service. My
								company and I have experienced in-depth consulting from the CEO. Their solid
								foundation is ready to be in charge of big and sophisticated projects which request
								high level of concentration and responsibility. Highly recommended for tech-com
								start up!</p>
						</div>
						<div class="client-thumbs mt30">
							<div class="media v-center">
								<div class="user-image bdr-radius"><img src="assets/images/user-thumb/girl.webp"
										alt="girl" class="img-fluid" /></div>
								<div class="media-body user-info">
									<h5>Huyen Dao</h5>
									<p>CEO of Mihoo, <small>Vietnam</small></p>
								</div>
							</div>
						</div>
					</div>
					<div class="testimonial-card">
						<div class="t-text">
							<p i18n>I must say that their top quality of work and adaptation to change of requirements are
								pretty impressive.
								They know what exactly they need to do to achieve the client’s goal and they do it
								wholeheartedly.
								On top of that, they are all super friendly and communicative people.
								Dicom Interactive is simply a very reliable partner.</p>
						</div>
						<div class="client-thumbs mt30">
							<div class="media v-center">
								<div class="user-image bdr-radius"><img src="assets/images/user-thumb/girl3.jpg"
										alt="girl" class="img-fluid" /></div>
								<div class="media-body user-info">
									<h5>Ryan Le</h5>
									<p>MD of Nordic Coder, <small>Vietnam</small></p>
								</div>
							</div>
						</div>
					</div>
					<div class="testimonial-card">
						<div class="t-text">
							<p i18n>I had some chances to work with Dicom Interactive. They are definitely hard-working
								technical guys with astonishingly fertile creative imagination and clearly
								comfortable with taking on responsibility. They very concentrated on achieving the
								project deadlines and very goes above and beyond what is required to ensure that
								their work is both professional and cutting edge.
							</p>
						</div>
						<div class="client-thumbs mt30">
							<div class="media v-center">
								<div class="user-image bdr-radius"><img src="assets/images/user-thumb/girl4.jpg"
										alt="girl" class="img-fluid" /></div>
								<div class="media-body user-info">
									<h5>Yuzo Suzuki</h5>
									<p>CEO of Atech, <small>Japan</small></p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!--End Testinomial-->

<!--Breadcrumb Area-->
<section class="breadcrumb-area banner-5">
  <div class="text-block">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 v-center">
          <div class="bread-inner">
            <div class="bread-menu">
              <ul>
                <li><a [routerLink]="['/']" i18n>Home</a></li>
                <li><a [routerLink]="['/portfolio' , '']" i18n>Portfolio</a></li>
              </ul>
            </div>
            <div class="bread-title">
              <h1 i18n>Movinavir Business Website</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Breadcrumb Area-->

<!--Start Case Study About-->
<section class="case-study pad-tb">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="image-block upset">
          <img src="assets/images/portfolio/image-11.jpg" alt="case study" class="img-fluid no-shadow" />
        </div>
      </div>
      <div class="col-lg-6 block-1 v-center">
        <div class="common-heading inner-heading text-l pl25">
          <span i18n>About The Project</span>
          <h2 i18n>Responsive Corporate Website</h2>
          <p i18n>An An Med Tech Group's corporate website is designed to showcase their new business offerings and announce
            their latest medicine, which has been developed to combat the COVID-19 virus.</p>
          <p i18n>As the client and owner of the website, An An Med Tech Group aims to provide a comprehensive overview of
            their medical products and services, and demonstrate their commitment to delivering innovative healthcare
            solutions.</p>
          <p><strong> ReactJS | NodeJS | Redis | MongoDB | AWS </strong></p>
        </div>
        <div class="project-platform mt60 pl25">
          <div class="project-platform-used -shadow"><a href="https://movinavir.com/" target="blank"><img
                src="assets/images/icons/www.png" alt="android"></a>
          </div>
          <div><strong> ⤺ </strong><span style="font-size: smaller;" i18n>Click here to visit the site</span></div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Case Study About-->
<section class="case-study pad-tb bg-gradient6 deep-dark">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <div class="common-heading">
          <span i18n>Service Features</span>
          <h2 class="mb30" i18n>As per client's need, we have developed a mobile-friendly corporate site.
          </h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <h4 class="mt20 mb10" i18n>Elegant Animation</h4>
        <ul class="list-style-">
          <li i18n>The animation on the website we built for our client is truly elegant and sophisticated. It adds an
            element of refinement to the user interface and enhances the browsing experience. Users will find themselves
            engaged and visually captivated by the subtle yet captivating animations.</li>
        </ul>
      </div>
      <div class="col-lg-6">
        <h4 class="mt20 mb10" i18n>SEO</h4>
        <ul class="list-style-">
          <li i18n>By incorporating SEO best practices into the development process, such as optimizing for keywords and
            ensuring the site is mobile-friendly, you can improve the visibility of the site in search engine results
            pages.
          </li>
          <li i18n>This can lead to increased traffic and exposure for the site.</li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <h4 class="mt20 mb10" i18n>Multi-language</h4>
        <ul class="list-style-">
          <li i18n>Our developed site is designed to support two different languages: English and Vietnamese. This enables
            users who speak either language to easily navigate and interact with the site, accessing all content and
            features without language barriers.</li>
          <li i18n>Ensuring that all text, images, and other elements are displayed correctly and coherently regardless of
            the language selected.
          </li>
        </ul>
      </div>
      <div class="col-lg-6">
        <h4 class="mt20 mb10" i18n>Mobile-friendly</h4>
        <ul class="list-style-">
          <li i18n>The website we have developed is designed to be mobile-friendly, ensuring that it is optimized for viewing
            and navigating on a range of mobile devices. With the increasing use of smartphones and tablets, it is
            essential for a website to be easily accessible and user-friendly on these devices to provide a positive
            user experience.</li>
        </ul>
      </div>
    </div>
  </div>
</section>
<!--Start Project Challenges-->
<section class="case-study pad-tb">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-sm-6">
        <h2 class="mt20 mb20" i18n>Challenges</h2>
        <p i18n>During the website development process for our client, we faced several challenges. Firstly, the client had
          multiple decision-makers, which caused delays and inconsistencies in the project.<br><br></p>
        <p i18n>Secondly, we encountered difficulties due to the lack of necessary materials such as design guidelines, their
          logo design, and site structure. This caused delays and added additional time and cost to the project.<br><br>
        </p>
        <p i18n>Additionally, we faced competition from other web development companies with cheaper prices, which made it
          challenging to provide a competitive quote while maintaining quality and meeting the client's needs.<br><br>
        </p>
        <p i18n>Our team was able to effectively communicate and collaborate with the client to ensure everyone was on the
          same page, and we were able to create a visually appealing and functional website without the need for
          extensive guidance. Ultimately, our dedication and expertise allowed us to overcome these challenges and
          provide the client with a high-quality website that exceeded their expectations.</p>
      </div>
      <div class="col-lg-6 col-sm-6">
        <div class="project-view m-mt30">
          <img src="assets/images/case-study/goen-view.png" alt="case study" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Project Challenges-->
<!--Start Solutions-->
<section class="case-study pad-tb bg-gradient5 deep-dark">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 block-1 v-center">
        <div class="common-heading inner-heading text-l pr25">
          <span i18n>Solutions</span>
          <h2 i18n>How We Work</h2>
          <h4 i18n>The chosen technology stack for the website includes ReactJS for the Front-end, NodeJS for the Back-end,
            and Redis and MongoDB as the database.<br><br></h4>
          <p i18n><strong>Design Demo:</strong> We work closely with our clients to understand their needs and vision for the
            website. We start by creating a design demo that captures the essence of their brand and incorporates their
            desired features and functionality. We then present the demo to the client and welcome their feedback to
            ensure that we're meeting their expectations.
          </p>
          <p i18n><strong>Communication during Development:</strong> Throughout the development process, we maintain open
            lines of communication with our clients. We provide frequent updates and progress reports, so they always
            know how the project is progressing. We also make ourselves available to answer any questions they may have
            and to address any concerns that arise. Our goal is to ensure that our clients feel fully informed and
            confident in the work we're doing.
          </p>
          <p i18n><strong>Maintenance:</strong> After the site is deployed to the client's live environment, we continue to
            provide ongoing maintenance and support. We monitor the site for any issues and take proactive steps to
            prevent downtime or other issues that could impact their business. We're always available to answer
            questions or make updates as needed, ensuring that the site remains up-to-date and fully functional.</p>
        </div>
        <div class="card-nx row">
          <div class="col-lg-3 col-md-6 col-sm-6 col-6 mt30">
            <div class="icon-card- hfull">
              <img src="assets/images/icons/logo-and-branding.svg" alt="case study" class="img-fluid">
              <h4>UI/UX Graphic Design</h4>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-6 mt30">
            <div class="icon-card- hfull">
              <img src="assets/images/icons/branding.svg" alt="case study" class="img-fluid">
              <h4>Front-end Development</h4>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-6 mt30">
            <div class="icon-card- hfull">
              <img src="assets/images/icons/back.svg" alt="case study" class="img-fluid">
              <h4>Back-end/API Development</h4>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-6 mt30">
            <div class="icon-card- hfull">
              <img src="assets/images/icons/seo.svg" alt="case study" class="img-fluid">
              <h4>Search Engine Optimization</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Solutions-->
<div class="case-study pad-tb">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="porto-slide owl-carousel">
          <div class="screen-splits"> <img src="assets/images/case-study/movinavir-web-1.jpg" alt="case study"
              class="img-fluid" style="border-style: solid; border-width: thin;" /></div>
          <div class="screen-splits"> <img src="assets/images/case-study/movinavir-web-2.jpg" alt="case study"
              class="img-fluid" style="border-style: solid; border-width: thin;" /></div>
          <div class="screen-splits"> <img src="assets/images/case-study/movinavir-web-3.jpg" alt="case study"
              class="img-fluid" style="border-style: solid; border-width: thin;" /></div>
          <div class="screen-splits"> <img src="assets/images/case-study/movinavir-web-4.jpg" alt="case study"
              class="img-fluid" style="border-style: solid; border-width: thin;" /></div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Start Navigate-->
<section class="testinomial-section pad-tb">
  <div class="container">
    <div class="row">
      <div class="col-6 v-center">
        <a [routerLink]="['/', 'portfolio', previousLink]" class="nav_link" i18n>&laquo; Previous</a>
      </div>
      <div class="col-6 v-center">
        <a [routerLink]="['/', 'portfolio', nextLink]" class="nav_link next_nav_link" i18n>Next &raquo;</a>
      </div>
    </div>
  </div>
</section>
<!--End Navigate-->

<!--Start Testinomial-->
<app-testinomial-component></app-testinomial-component>
<!--End Testinomial-->
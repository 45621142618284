import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class BlogService {
    private REST_API_SERVER = environment.api;

    constructor(private http: HttpClient) {}
    getAllBlogsVN(lang: string): Observable<any> {
        return this.http.get<any>(`${this.REST_API_SERVER}/blog-dicom?lang=${lang}`);
    }
    getBlog(urlBlog: string, lang: string,): Observable<any> {
        return this.http.get<any>(`${this.REST_API_SERVER}/blog-dicom/${urlBlog}?lang=${lang}`);
    }
    getBlogRecent(lang: string): Observable<any> {
        return this.http.get<any>(`${this.REST_API_SERVER}/dicom-recent?lang=${lang}`);
    }
    incrementViewCount(blogId: string): Observable<any> {
        return this.http.put<any>(`${this.REST_API_SERVER}/readcount/${blogId}`, {});
    }
    getBlogAndIncrementViewCount(urlBlog: string, blogId: string, lang: string): Observable<any> {
        return this.getBlog(urlBlog, lang).pipe(
            tap(() => this.incrementViewCount(blogId).subscribe())
        );
    }
    getBlogPosts(): Observable<any> {
        return this.http.get<any>(`${this.REST_API_SERVER}/blog-dicom-all`);
    }
}

<section class="hero-section hero-bg-bg1 bg-gradient">
  <div class="container notfound-container text-center">
    <div class="notfound-content">
      <h1 class="title">404</h1>
      <h2 class="title-secondary">Page not found</h2>
      <a href="/" class="lnk btn-main bg-btn">
        <i class="fa fa-home"></i> Go back home
      </a>
    </div>
  </div>
</section>

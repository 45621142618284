<section class="breadcrumb-area banner-3" *ngIf="showcase && showcase.attributes">
  <div class="text-block">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 v-center">
          <div class="bread-inner">
            <div class="bread-menu">
              <ul>
                <li><a [routerLink]="['/']">Home</a></li>
                <li><a [routerLink]="['/showcases-web', '']">Show Cases</a></li>
                <li><a [routerLink]="['/showcases-web', showcase.attributes.slug]">{{showcase.attributes.title}}</a></li>
              </ul>
            </div>
            <div class="bread-title">
              <h2>Our Portfolio</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="portfolio-page pad-tb" *ngIf="showcase && showcase.attributes">
  <div class="container">
    <div class="row justify-content-left">
      <div class="col-lg-7">
        <div class="common-heading pp p-details">
          <span>{{showcase.attributes.type}}</span>
          <h1 data-role="title" title="{{showcase.attributes.title}}">{{showcase.attributes.title}}</h1>
        </div>
        <div class="content-textEditor mt-5" data-role="content" [innerHTML]="showcase.attributes.description"></div>
          <div id="carouselExampleControls" class="carousel slide" data-ride="carousel" *ngIf="showcase.attributes.images.data?.length > 0">
            <div class="carousel-inner">
              <div
              class="carousel-item"
              *ngFor="let image of showcase.attributes.images.data; let i = index"
              [ngClass]="{'active': i == 0}"
              >
                <img class="d-block w-100" src="{{imgEndpoint + image.attributes.url}}" alt="{{image.attributes.alternativeText}}">
              </div>
            </div>
            <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>
      <div class="col-lg-5">
        <div class="portfolio-details">
          <div class="portfolio-meta link-hover">
            <ul>
              <li>
                <i class="fas fa-user"></i>
                <p>Client Name: <span>{{showcase.attributes.clientName}}</span></p>
              </li>
              <li>
                <i class="fas fa-tags"></i>
                <p>Project Category: <span>{{showcase.attributes.type}}</span></p>
              </li>
              <li>
                <i class="fas fa-calendar-alt"></i>
                <p>Project Date: <span>{{showcase.attributes.finishAt}}</span></p>
              </li>
              <li><a [href]="'//'+showcase.attributes.url" target="_blank">Open External Link</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

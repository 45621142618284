// https://stackoverflow.com/questions/57720654/after-change-route-jquery-not-working-when-refresh-again-then-work-correctly
// core
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';

// self
import { ScriptService, LangService, ApiService, MetaDataService } from '../services';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { NgForm } from '@angular/forms';
import { Quote } from '../model/quote.model';
import { Contact } from '../model/contact.model';
import { GeolocationService } from '../geolocation.service';
import { SharedService } from '../services/sharedService.service';
import { RecaptchaService } from '../services/recaptcha.service';
import { environment } from '../../environments/environment';

declare var grecaptcha: any;
declare global {
  interface Window {
    grecaptcha: any;
  }
}
@Component({
  selector: 'app-services-web-development',
  templateUrl: './web-development.component.html',
  styleUrls: ['./service-detail.component.css']
})
export class WebDevelopmentComponent extends MetaDataService implements OnInit {
  // for load init script
  isLoad = false;
  public current_lang: any = {};
  submitted = false;
  filesToUpload
  fileInputError
  fileChose1
  fileChose2
  public userLanguage: string;
  public userCountry: string;
  countryCode: string = '+84';
  countryName: string = 'vn';
  public quote: Quote = new Quote();
  public contact: Contact = new Contact();
  widgetId: string = '';
  recaptchaResponse: string | null = null;

  // get free quote form exclude VN
  quoteForm = this.formBuilder.group({
    name: new FormControl(this.quote.name, [Validators.required]),
    email: new FormControl(this.quote.email, [Validators.required]),
    countryCode: new FormControl(this.countryCode),
    countryName: new FormControl(this.countryName),
    phone: new FormControl(this.quote.phone, [Validators.required]),
    companyName: new FormControl(this.quote.companyName),
    deadline: new FormControl(this.quote.deadline),
    contentPlan: new FormControl(this.quote.contentPlan),
    pageRequest: new FormControl(this.quote.pageRequest),
    featureRequest: new FormControl(this.quote.featureRequest),
    message: new FormControl(this.quote.message, [Validators.required]),
    files: new FormControl(this.quote.files, [Validators.required]),
  });

  // order form only for VN
  contactForm = this.formBuilder.group({
    name: new FormControl(this.contact.name, [Validators.required]),
    email: new FormControl(this.contact.email, [Validators.required]),
    countryCode: new FormControl(this.countryCode),
    countryName: new FormControl(this.countryName),
    phone: new FormControl(this.contact.phone, [Validators.required]),
    subject: new FormControl(this.contact.subject, [Validators.required]),
    message: new FormControl(this.contact.message),
  });

  companyName: string;
  deadline: string;
  pageRequest: string;
  featureRequest: string;
  message: string;
  files: FileList;


  constructor(
    private scriptService: ScriptService,
    private langService: LangService,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private geolocation: GeolocationService,
    private sharedService: SharedService,
    private recaptchaService: RecaptchaService,
    private location: Location,
    private router: Router,
    title: Title,
    meta: Meta,
  ) {
    super(title, meta);
    // this.title.setTitle("Web Development Services | Dicom Interactive");
    // this.meta.updateTag({ name: "description", content: `We at Dicom Interactive build complex web applications to support you to build the most sophisticated online products. We make sure to keep up to date with the latest responsive and adaptive design trends in order to provide your websites that displayed flawlessly on both desktop and mobile across devices, browsers and OS.` });
    this.updateTags(
      'Web Development Services',
      'We at Dicom Interactive build complex web applications to support you to build the most sophisticated online products. We make sure to keep up to date with the latest responsive and adaptive design trends in order to provide your websites that displayed flawlessly on both desktop and mobile across devices, browsers and OS.',
      this.location.prepareExternalUrl(this.router.url),
    );
    
    // this._loadScript("assets/js/jquery-2.0.0.min.js");
    // this._loadScript("assets/js/bootstrap.js");
    // this._loadScript("assets/vendor/owl-carousel/js/owl.carousel.min.js");
    // this._loadScript("assets/vendor/revslider/js/jquery.themepunch.tools.min.js");
    // this._loadScript("assets/vendor/revslider/js/jquery.themepunch.revolution.min.js");
    // this._loadScript("assets/js/init.js");
    // this._loadScript("assets/js/slide.js");
    this.scriptService.load(this.scriptService.INIT).then(() => {
      this.isLoad = true;
    });
    // setTimeout(() => { this.isLoad = true }, 1000);
    this.geolocation.getClientLocale().subscribe((data) => {
      this.userLanguage = data.language;
      this.userCountry = data.country;
  });

    // save for contact page
    // localStorage.setItem('contact_subject', '');
  }

  async ngOnInit() {
    this.current_lang = await this.langService.loadLang();
    await this.recaptchaService.loadRecaptcha();
    this.widgetId = grecaptcha.render('recaptcha', {
      sitekey: environment.recaptcha,
      callback: this.onRecaptchaSuccess.bind(this),
      'expired-callback': this.onRecaptchaExpired.bind(this),
    });
  }

  onRecaptchaSuccess(response: string) {
    this.recaptchaResponse = response;
    (document.getElementById('form-submit') as HTMLButtonElement).disabled = false;
  }
  onRecaptchaExpired() {
    (document.getElementById('form-submit') as HTMLButtonElement).disabled = true;
  }

  onCountryChange(event: any) {
    this.contactForm.get('countryCode')!.setValue(`+${event.dialCode}`);
    this.contactForm.get('countryName')!.setValue(event.iso2);
  }

  onContactWeb() {
    this.sharedService.changeSubject('web');
  }
  /**
 * one way binding need pass NgForm as parameter
 * @param form
 * @returns
 */
  async onSubmit(form: NgForm) {
    try {
      const lang = this.userLanguage;
      this.submitted = true;
      if (form.invalid) {
        return;
      }
      this.quote = form.value;
      this.quote.countryCode = this.quoteForm.get('countryCode')!.value;
      this.quote.countryName = this.quoteForm.get('countryName')!.value;
      await this.apiService.handleFreeQuoteRequest(this.quote, this.filesToUpload, lang);
      alert("We've received your request! Thanks for your quote request! We'll be in touch soon to discuss your project further. Feel free to reach out at sales@dicom-interactive.com if you have any questions.");
    } catch (error) {
      return;
    }
    this.handleResetField();
    form.resetForm();
  }

  /**
 * one way binding need pass NgForm as parameter
 * @param form
 * @returns
 */
  async onSubmitPricingPackage(form: NgForm) {
    try {
      const lang = this.userLanguage
      this.submitted = true;
      if (form.invalid) {
        return;
      }
      if (!this.recaptchaResponse) {
        alert('Vui lòng hoàn thành Captcha để gửi yêu cầu của bạn.');
        return;
      }
      // set value for subject (pricing package) the client selected
      // form.setValue({
      //   subject: this.contact.subject,
      // });
      // const subject = this.contact.subject;
      this.contact = form.value;
      this.contact.countryCode = this.contactForm.get('countryCode')!.value;
      this.contact.countryName = this.contactForm.get('countryName')!.value;
      // call develop API
      await this.apiService.handleDevelopWebRequest(this.contact, lang);
      alert("Đã gửi yêu cầu thành công, Chúng tôi sẽ liên hệ với bạn sớm nhất có thể!");
    } catch (error) {
      alert("Đã có lỗi xãy ra, vui lòng thử lại hoặc liên hệ (+84) 287 1065 144");
      return;
    }
    if (grecaptcha && grecaptcha.reset) {
      grecaptcha.reset(this.widgetId);
      this.submitted = false;
      this.contact = new Contact();
    }
    this.handleResetField();
    form.resetForm();
  }

  private handleResetField() {
    // reset error
    this.fileInputError = "";
    this.fileChose1 = "";
    this.fileChose2 = "";
  }

  /**
 * for auto set position
 * @param package apply package
 */
  async selectPackage(pkg: string) {
    // because can not binding while open dialog, we have to delay biding
    setTimeout(() => {
      this.handleResetField();
      this.contact.subject = pkg;
    }, 400);
  }


  /**
   * handle input file
   * @param files file select
   * @returns
   */
  handleFileInput(files: FileList) {
    this.filesToUpload = files;
    if (files.length > 2) {
      return this.fileInputError = "Maximum is 2 files"
    }
    console.log("this.files:: ", files);

    // binding filename
    let fileChose1 = files.item(0).name;
    let fileChose2 = files?.item(1)?.name;
    if (this.verifyFileExtension(fileChose1) && this.verifyFileExtension(fileChose2)) {
      this.handleResetField();
      this.fileChose1 = fileChose1;
      this.fileChose2 = fileChose2;
      return;
    }

    this.handleResetField();
    return this.fileInputError = "Please upload only pdf, doc, docx file"
  }

  private verifyFileExtension(fileName) {
    if (!fileName) return true;
    let extension = fileName.split(".").pop();
    if (['pdf', 'docx', 'doc'].indexOf(extension) != -1) {
      return true;
    }
    return false;
  }

    /**
   *  scroll to
   * @param el element go to. e.g #x
   */
    scroll(el: HTMLElement) {
      el.scrollIntoView({ behavior: 'smooth' });
    }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { PortfolioComponent } from './portfolios/portfolios.component';
import { Ask2GoComponent } from './project/ask2go.component';
import { GoenComponent } from './project/goen.component';
import { EnvocaComponent } from './project/envoca.component';
import { MeotoComponent } from './project/meoto.component';
import { HyundaiI30BannerComponent } from './project/hyundai-i30-banner.component';
import { AscentisHomeDesignComponent } from './project/ascentis-home-design.component';
import { HyundaiI20BannerComponent } from './project/hyundai-i20-banner.component';
import { CulesComponent } from './project/cules.component';
import { FresyComponent } from './project/fresy.component';

import { DigitalMarketingComponent } from './dicom-service-detail/digital-marketing.component';
import { GraphicDesignComponent } from './dicom-service-detail/graphic-design.component';
import { MobileDevelopmentComponent } from './dicom-service-detail/mobile-development.component';
import { WebDevelopmentComponent } from './dicom-service-detail/web-development.component';
import { OffshoreDevelopmentCenterComponent } from './dicom-service-detail/offshore-development-center.component';

import { AboutDicomComponent } from './about-us/about-dicom.component';
import { AboutTeamComponent } from './about-us/about-team.component';
import { AboutDicomAusComponent } from './about-us/about-dicom-aus.component';

import { CareerComponent } from './career/career.component';
import { ContactComponent } from './contact/contact.component';

import { BlogComponent } from './blog/blog.component';
import { BlogSingleComponent } from './blog/blog-single.component';
import { UcademyPlatformComponent } from './project/ucademy-platform.component';
import { MmhfComponent } from './project/mmhf.component';
import { KmmtVendorComponent } from './project/kmmt-vendor.component';
import { WhipEvComponent } from './project/whipev.component';
import { TdholdingComponent } from './project/tdholding.component';
import { MovinavirComponent } from './project/movinavir.component';
import { KeazComponent } from './project/keaz.component';
import { WhiteSpaAndNailComponent } from './project/white-spa-and-nail.component';
import { NotFoundComponent } from './notfound/notfound.component';
import { ShowCaseDetailComponent, ShowcaseComponent } from './showcase';

const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  // { path: '', redirectTo: ':slug/', pathMatch: 'full' },

  { path: 'portfolio/', component: PortfolioComponent, pathMatch: 'full' },
  { path: 'portfolio', redirectTo: 'portfolio/', pathMatch: 'full' }, // redirect to trailing slash

  { path: 'portfolio/white-spa-and-nail/', component: WhiteSpaAndNailComponent, pathMatch: 'full' },
  { path: 'portfolio/white-spa-and-nail', redirectTo: 'portfolio/white-spa-and-nail/', pathMatch: 'full' }, // redirect to trailing slash

  { path: 'portfolio/keazaccess-platform/', component: KeazComponent, pathMatch: 'full' },
  { path: 'portfolio/keazaccess-platform', redirectTo: 'portfolio/keazaccess-platform/', pathMatch: 'full' }, // redirect to trailing slash

  { path: 'portfolio/ucademy-platform/', component: UcademyPlatformComponent, pathMatch: 'full' },
  { path: 'portfolio/ucademy-platform', redirectTo: 'portfolio/ucademy-platform/', pathMatch: 'full' }, // redirect to trailing slash

  { path: 'portfolio/mmhf-site/', component: MmhfComponent, pathMatch: 'full' },
  { path: 'portfolio/mmhf-site', redirectTo: 'portfolio/mmhf-site/', pathMatch: 'full' }, // redirect to trailing slash

  { path: 'portfolio/kmmt-vendor-site/', component: KmmtVendorComponent, pathMatch: 'full' },
  { path: 'portfolio/kmmt-vendor-site', redirectTo: 'portfolio/kmmt-vendor-site/', pathMatch: 'full' }, // redirect to trailing slash

  { path: 'portfolio/whipev-mobile-app/', component: WhipEvComponent, pathMatch: 'full' },
  { path: 'portfolio/whipev-mobile-app', redirectTo: 'portfolio/whipev-mobile-app/', pathMatch: 'full' }, // redirect to trailing slash

  { path: 'portfolio/tdholding-site/', component: TdholdingComponent, pathMatch: 'full' },
  { path: 'portfolio/tdholding-site', redirectTo: 'portfolio/tdholding-site/', pathMatch: 'full' }, // redirect to trailing slash

  { path: 'portfolio/movinavir-site/', component: MovinavirComponent, pathMatch: 'full' },
  { path: 'portfolio/movinavir-site', redirectTo: 'portfolio/movinavir-site/', pathMatch: 'full' }, // redirect to trailing slash

  { path: 'portfolio/ask2go-mobile-applications/', component: Ask2GoComponent, pathMatch: 'full' },
  { path: 'portfolio/ask2go-mobile-applications', redirectTo: 'portfolio/ask2go-mobile-applications/', pathMatch: 'full' }, // redirect to trailing slash

  { path: 'portfolio/goen-e-learning-system/', component: GoenComponent, pathMatch: 'full' },
  { path: 'portfolio/goen-e-learning-system', redirectTo: 'portfolio/goen-e-learning-system/', pathMatch: 'full' }, // redirect to trailing slash

  { path: 'portfolio/envoca-mobile-app/', component: EnvocaComponent, pathMatch: 'full' },
  { path: 'portfolio/envoca-mobile-app', redirectTo: 'portfolio/envoca-mobile-app/', pathMatch: 'full' }, // redirect to trailing slash

  { path: 'portfolio/meoto-auto-trader/', component: MeotoComponent, pathMatch: 'full' },
  { path: 'portfolio/meoto-auto-trader', redirectTo: 'portfolio/meoto-auto-trader/', pathMatch: 'full' }, // redirect to trailing slash

  { path: 'portfolio/3d-hyundai-i30-banner/', component: HyundaiI30BannerComponent, pathMatch: 'full' },
  { path: 'portfolio/3d-hyundai-i30-banner', redirectTo: 'portfolio/3d-hyundai-i30-banner/', pathMatch: 'full' }, // redirect to trailing slash

  { path: 'portfolio/ascentis-homepage-design/', component: AscentisHomeDesignComponent, pathMatch: 'full' },
  { path: 'portfolio/ascentis-homepage-design', redirectTo: 'portfolio/ascentis-homepage-design/', pathMatch: 'full' }, // redirect to trailing slash

  { path: 'portfolio/hyundai-i20-banner/', component: HyundaiI20BannerComponent, pathMatch: 'full' },
  { path: 'portfolio/hyundai-i20-banner', redirectTo: 'portfolio/hyundai-i20-banner/', pathMatch: 'full' }, // redirect to trailing slash

  { path: 'portfolio/cules-news-mobile-app/', component: CulesComponent, pathMatch: 'full' },
  { path: 'portfolio/cules-news-mobile-app', redirectTo: 'portfolio/cules-news-mobile-app/', pathMatch: 'full' }, // redirect to trailing slash

  { path: 'portfolio/fresy/', component: FresyComponent, pathMatch: 'full' },
  { path: 'portfolio/fresy', redirectTo: 'portfolio/fresy/', pathMatch: 'full' }, // redirect to trailing slash

  { path: 'about-us/', component: AboutDicomComponent, pathMatch: 'full' },
  { path: 'about-us', redirectTo: 'about-us/', pathMatch: 'full' }, // redirect to trailing slash

  { path: 'our-team/', component: AboutTeamComponent, pathMatch: 'full' },
  { path: 'our-team', redirectTo: 'our-team/', pathMatch: 'full' }, // redirect to trailing slash

  { path: 'software-development-melbourne/', component: AboutDicomAusComponent, pathMatch: 'full' },
  { path: 'software-development-melbourne', redirectTo: 'software-development-melbourne/', pathMatch: 'full' }, // redirect to trailing slash
  { path: 'software-development-Melbourne', redirectTo: 'software-development-melbourne/', pathMatch: 'full' }, // redirect to trailing slash

  { path: 'software-development-sydney/', component: AboutDicomAusComponent, pathMatch: 'full' },
  { path: 'software-development-sydney', redirectTo: 'software-development-sydney/', pathMatch: 'full' }, // redirect to trailing slash
  { path: 'software-development-Sydney', redirectTo: 'software-development-sydney/', pathMatch: 'full' }, // redirect to trailing slash

  { path: 'services/graphic-design/', component: GraphicDesignComponent, pathMatch: 'full' },
  { path: 'services/graphic-design', redirectTo: 'services/graphic-design/', pathMatch: 'full' }, // redirect to trailing slash

  { path: 'services/mobile-app-development/', component: MobileDevelopmentComponent, pathMatch: 'full' },
  { path: 'services/mobile-app-development', redirectTo: 'services/mobile-app-development/', pathMatch: 'full' }, // redirect to trailing slash

  { path: 'services/web-development/', component: WebDevelopmentComponent, pathMatch: 'full' },
  { path: 'services/web-development', redirectTo: 'services/web-development/', pathMatch: 'full' }, // redirect to trailing slash

  { path: 'services/digital-marketing/', component: DigitalMarketingComponent, pathMatch: 'full' },
  { path: 'services/digital-marketing', redirectTo: 'services/digital-marketing/', pathMatch: 'full' }, // redirect to trailing slash

  { path: 'services/offshore-development-center/', component: OffshoreDevelopmentCenterComponent, pathMatch: 'full' },
  { path: 'services/offshore-development-center', redirectTo: 'services/offshore-development-center/', pathMatch: 'full' }, // redirect to trailing slash

  { path: 'career/', component: CareerComponent, pathMatch: 'full' },
  { path: 'career', redirectTo: 'career/', pathMatch: 'full' }, // redirect to trailing slash

  { path: 'contact/', component: ContactComponent, pathMatch: 'full' },
  { path: 'contact', redirectTo: 'contact/', pathMatch: 'full' }, // redirect to trailing slash

  {path: 'showcases-web/', component: ShowcaseComponent, pathMatch: 'full' },
  {path: 'showcases-web', redirectTo: 'showcases-web/', pathMatch: 'full'},// redirect to trailing slash

  { path: 'showcases-web/:slug/', component: ShowCaseDetailComponent, pathMatch: 'full' },
  { path: 'showcases-web/:slug', redirectTo: 'showcases-web/:slug/', pathMatch: 'full' },// redirect to trailing slash

  { path: 'blog/', component: BlogComponent, pathMatch: 'full' },
  { path: 'blog', redirectTo: 'blog/', pathMatch: 'full' }, // redirect to trailing slash

  { path: 'blog/:slug/', component: BlogSingleComponent, pathMatch: 'full' },
  { path: 'blog/:slug', redirectTo: 'blog/:slug/', pathMatch: 'full' },// redirect to trailing slash

  // { path: 'services/', component: ServicesComponent, pathMatch: 'full' },
  // { path: 'services', redirectTo: 'services/', pathMatch: 'full' }, // redirect to trailing slash
  // { path: 'services/:slug/', component: ServiceDetailComponent, pathMatch: 'full' },
  // { path: 'services/:slug', redirectTo: 'services/:slug/', pathMatch: 'full' }, // redirect to trailing slash

  // {
  //   path: 'services',
  //   pathMatch: 'prefix',
  //   children: [
  //     {
  //       path: 'graphic-design/:slug',
  //       component: GraphicDesignComponent,
  //     },
  //     // {
  //     //   path: 'graphic-design',
  //     //   redirectTo: 'services/graphic-design/:slug',
  //     //   pathMatch: 'full',
  //     // },
  //     {
  //       path: 'mobile-app-development/:slug',
  //       component: MobileDevelopmentComponent,
  //     },

  //     {
  //       path: 'web-development/:slug',
  //       component: WebDevelopmentComponent,
  //     },
  //     {
  //       path: 'digital-marketing/:slug',
  //       component: DigitalMarketingComponent,
  //     },
  //   ]
  // },
  // redirect
  { path: '', redirectTo: '', pathMatch: 'full' },

  {path: '404', component: NotFoundComponent},
  {path: '**', redirectTo: '/404'}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

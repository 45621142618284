// https://stackoverflow.com/questions/57720654/after-change-route-jquery-not-working-when-refresh-again-then-work-correctly
// core
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { NgForm } from '@angular/forms';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
// self
import { ScriptService, LangService, ApiService, MetaDataService } from '../services';
import { Contact } from '../model/contact.model';
import { RecaptchaService } from '../services/recaptcha.service';
import { GeolocationService } from '../geolocation.service';
declare var grecaptcha: any;
declare global {
  interface Window {
    grecaptcha: any;
  }
}
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent extends MetaDataService implements OnInit, OnDestroy {
  // for load init script
  isLoad = false;
  public current_lang: any = { portfolios: [{}, {}, {}, {}, {}, {}] };
  public contact: Contact = new Contact();
  countryCode: string = '+61';
  countryName: string = 'aus';
  recaptchaResponse: string | null = null;
  public userLanguage: string;
  public userCountry: string;
  widgetId: string = ''

  onCountryChange(event: any) {
    this.contactForm.get('countryCode')!.setValue(`+${event.dialCode}`);
    this.contactForm.get('countryName')!.setValue(event.iso2);
  }

  contactForm = this.formBuilder.group({
    name: new FormControl(this.contact.name, [Validators.required]),
    email: new FormControl(this.contact.email, [Validators.required]),
    countryName: new FormControl(this.countryName),
    countryCode: new FormControl(this.countryCode),
    phone: new FormControl(this.contact.phone, [Validators.required]),
    subject: new FormControl(this.contact.subject, [Validators.required]),
    message: new FormControl(this.contact.message, [Validators.required]),
  });

  submitted = false;

  constructor(
    private scriptService: ScriptService,
    private langService: LangService,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private recaptchaService: RecaptchaService,
    private geolocation: GeolocationService,
    private location: Location,
    private router: Router,
    title: Title,
    meta: Meta,
  ) {
    super(title, meta);
    // this.title.setTitle("Top Software Development Company in Vietnam | Dicom Interactive");
    // this.meta.updateTag({name: 'description', content: `Dicom Interactive Vietnamese Australian software company focused on hi-tech custom software development services, outsourcing. We are offering custom software development, web / mobile app development & dedicated software teams to software production companies and startups`});
    this.updateTags(
      'Top Software Development Company in Vietnam',
      'Dicom Interactive Vietnamese Australian software company focused on hi-tech custom software development services, outsourcing. We are offering custom software development, web / mobile app development & dedicated software teams to software production companies and startups',
      this.location.prepareExternalUrl(this.router.url),
    );
    
    // this._loadScript("assets/js/jquery-2.0.0.min.js");
    // this._loadScript("assets/js/bootstrap.js");
    // this._loadScript("assets/vendor/owl-carousel/js/owl.carousel.min.js");
    // this._loadScript("assets/vendor/revslider/js/jquery.themepunch.tools.min.js");
    // this._loadScript("assets/vendor/revslider/js/jquery.themepunch.revolution.min.js");
    // this._loadScript("assets/js/init.js");
    // this._loadScript("assets/js/slide.js");
    this.geolocation.getClientLocale().subscribe((data) => {
      this.userLanguage = data.language;
      this.userCountry = data.country;
    });
  }

  ngOnDestroy() {}

  async ngOnInit() {
    await this.recaptchaService.loadRecaptcha();
    this.current_lang = await this.langService.loadLang();
    this.widgetId =  grecaptcha.render('recaptcha', {
      sitekey: environment.recaptcha,
      callback: this.onRecaptchaSuccess.bind(this),
      'expired-callback': this.onRecaptchaExpired.bind(this),
    });
    this.scriptService.load(this.scriptService.INIT).then(() => {
      this.isLoad = true;
    });
    this.current_lang = await this.langService.loadLang();
    setTimeout(() => { this.isLoad = true }, 1000);
  }
  onRecaptchaSuccess(response: string) {
    this.recaptchaResponse = response;
    (document.getElementById('form-submit') as HTMLButtonElement).disabled = false;
  }
  onRecaptchaExpired() {
    (document.getElementById('form-submit') as HTMLButtonElement).disabled = true;
  }
  // one way binding need pass NgForm as parameter
  onSubmit(contactForm: NgForm) {
    const lang = this.userLanguage;
    this.submitted = true;
    if (contactForm.invalid) {
      return;
    }
    if (!this.recaptchaResponse) {
      if (lang === 'en'){
        alert('Please complete captcha verification to submit your request.');
      } else {
        alert('Vui lòng hoàn thành Captcha để gửi yêu cầu của bạn.')
      }
      return;
    }

    this.contact = contactForm.value;
    this.contact.countryCode = this.contactForm.get('countryCode')!.value;
    this.contact.countryName = this.contactForm.get('countryName')!.value;
    this.apiService.sendContact2Slack(this.contact, lang);
    contactForm.resetForm();
    if (grecaptcha && grecaptcha.reset) {
      grecaptcha.reset(this.widgetId);
      this.submitted = false;
      this.contact = new Contact();
    }
    this.onRecaptchaExpired();
  }

  onHireNow() {
    localStorage.setItem('contact_subject', 'resource');
  }
}

// WORK
// <!-- Work -->
// <div *ngFor="let portfolio of current_lang.portfolios; let i = index" class="col-md-4 col-xs-6 work">
//   <img class="img-responsive" src="assets/img/work{{i}}.png" alt="">
//   <div class="overlay"></div>
//   <div class="work-content">
//     <span>{{portfolio.portfolio_item_title}}</span>
//     <h3>{{portfolio.portfolio_item_content}}</h3>
//     <div class="work-link">
//       <!-- <a href="#"><i class="fa fa-external-link"></i></a> -->
//       <a href="#portfolio-item{{i}}" class="popup-with-move-anim"><i class="fa fa-search"></i></a>
//     </div>
//   </div>
// </div>
// <!-- /Work -->
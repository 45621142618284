// recaptcha.service.ts
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RecaptchaService {
  async loadRecaptcha() {
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js?hl=en';
    document.head.appendChild(script);
  
    await new Promise<void>((resolve) => {
      const intervalId = setInterval(() => {
        if (window && 'grecaptcha' in window && window.grecaptcha.render) {
          clearInterval(intervalId);
          resolve();
        }
      }, 10);
    });
  };

}

//http://www.lukasjakob.com/how-to-dynamically-load-external-scripts-in-angular/
import { Injectable } from '@angular/core';

const myScripts = [
  // { name: 'themepunchTool', src: "assets/vendor/revslider/js/jquery.themepunch.tools.min.js" },
  // { name: 'themepunchRevolution', src: "assets/vendor/revslider/js/jquery.themepunch.revolution.min.js" },
  // { name: 'gogleAUth', src: "assets/vendor/revslider/js/jquery.themepunch.revolution.min.js" },
  { name: 'owl', src: "assets/js/owl.carousel.min.js" },
  { name: 'init', src: "assets/js/main.js" },
  { name: 'enabler', src: "assets/js/enabler.js" },
  { name: '3dcube', src: "assets/js/3d-cube.js" },
  { name: '3dBanner', src: "assets/js/3d-banner.js" },
  // { name: 'processBar', src: "assets/js/process-bar.js" },
  // { name: 'slide', src: "assets/js/slide.js" },
];

@Injectable({
  providedIn: 'root'
})
export class ScriptService {

  private scripts: any = {};
  public INIT = 'init';
  public CUBE = '3dcube';
  public BANNER = '3dBanner';
  public ENABLER = 'enabler';
  public OWL = 'owl';
  public PROCESS_BAR = 'processBar';

  constructor() {
    myScripts.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src
      };
    });
  }

  // load a single or multiple scripts
  load(...scripts: string[]) {
    const promises: any[] = [];
    // push the returned promise of each loadScript call
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    // return promise.all that resolves when all promises are resolved
    return Promise.all(promises);
  }

  // load the script
  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      // resolve if already loaded
      // if (this.scripts[name].loaded) {
      //   resolve({ script: name, loaded: true, status: 'Already Loaded' });
      // } else {
        // load script
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        script.type = 'text/javascript';
        script.async = true;
        script.charset = 'utf-8';
        script.onload = () => {
          this.scripts[name].loaded = true;
          resolve({ script: name, loaded: true, status: 'Loaded' });
        };
        script.onerror = (error: any) => reject({ script: name, loaded: false, status: 'Loading_fail', error });
        // finally append the script tag in the DOM
        document.getElementsByTagName('footer')[0].appendChild(script);
      // }
    });
  }

}

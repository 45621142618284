<!-- // Culés News Mobile App -->
<!--Breadcrumb Area-->
<section class="breadcrumb-area banner-5">
  <div class="text-block">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 v-center">
          <div class="bread-inner">
            <div class="bread-menu">
              <ul>
                <li><a [routerLink]="['/']" i18n>Home</a></li>
                <li><a [routerLink]="['/portfolio' , '']" i18n>Portfolio</a></li>
              </ul>
            </div>
            <div class="bread-title">
              <h1 i18n>Culés News Mobile App</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Breadcrumb Area-->
<!--Start Case Study About-->
<section class="case-study pad-tb">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="image-block upset">
          <img src="assets/images/case-study/cules-intro.png" alt="case study" class="img-fluid no-shadow" />
        </div>
      </div>
      <div class="col-lg-6 block-1 v-center">
        <div class="common-heading inner-heading text-l pl25">
          <span i18n>About The Project</span>
          <h2 i18n>News mobile app for culés</h2>
          <p i18n>Culés News offers is an app that designed for all FC Barcelona supporters. It provides recent
            update about ins and outs of the club and brings the most relevant stories related to FC
            Barcelona from the latest news, LIVE scores and match analysis to detailed statistics, game
            schedule, results and goal notifications.</p>
          <p><strong> Swift | Java | Python | Lambda | DynamoDB | SQS | S3 </strong></p>
        </div>
        <!-- <div class="project-platform mt60 pl25">
          <div class="project-platform-used -shadow"><a [routerLink]="[]"><img src="assets/images/icons/android.svg"
                alt="android"></a>
          </div>
          <div class="project-platform-used -shadow"><a [routerLink]="[]"><img src="assets/images/icons/apple.svg"
                alt="apple"></a></div>
          <div><strong> ⤺ </strong><span style="font-size: smaller;" i18n>Click the icon to download the app</span></div>
        </div> -->
      </div>
    </div>
  </div>
</section>
<!--End Case Study About-->
<section class="case-study pad-tb bg-gradient6 deep-dark">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <div class="common-heading">
          <span i18n>App Features</span>
          <h2 class="mb30" i18n>As per client's need, we have developed Envoca mobile app for both platform:
            iOS & Android.
          </h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <ul class="list-ul ul-circle">
          <li i18n>App is comfortable to use with easy navigation and beautiful design which is flawlessly
            displayed on any screen resolution.</li>
          <li>Adjustable push notifications for top news, game events, lineups, score.</li>
        </ul>
      </div>
      <div class="col-lg-6">
        <ul class="list-ul ul-circle">
          <li i18n>Stay on top of all the news with the most up-to-date Barca news, video highlights and photo
            galleries, latest transfers and rumors.</li>
          <li>Win exclusive prizes through different quizzes and games in-app.</li>
        </ul>
      </div>
    </div>
  </div>
</section>
<!--Start Solutions-->
<section class="case-study pad-tb bg-gradient5 deep-dark">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 block-1 v-center">
        <div class="common-heading inner-heading text-l pr25">
          <span i18n>Solutions</span>
          <h2 i18n>How We Work</h2>
          <h4 i18n>We decided to go with native programming languages for this mobile app development.<br><br>
          </h4>
          <p i18n>First off, we sketch the layout and finetune it various number of times until it meets our
            client expectation. And then, based on that sketch, our designers breathe life into it with
            colors, logo, graphic, animation... So that the applications could have a tangible identity.
          </p>
          <p i18n>During the development stage, we define milestones that we deliver shippable package to our
            client so we could make sure we are going on the right path.</p>
        </div>
        <div class="card-nx row">
          <div class="col-lg-3 col-md-6 col-sm-6 col-6 mt30">
            <div class="icon-card- hfull">
              <img src="assets/images/icons/branding.svg" alt="case study" class="img-fluid">
              <h3>Mobile App Development</h3>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-6 mt30">
            <div class="icon-card- hfull">
              <img src="assets/images/icons/video.svg" alt="case study" class="img-fluid">
              <h3>Video Streaming Integration</h3>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-6 mt30">
            <div class="icon-card- hfull">
              <img src="assets/images/icons/seo.svg" alt="case study" class="img-fluid">
              <h3>In-App Purchase Integration</h3>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-6 mt30">
            <div class="icon-card- hfull">
              <img src="assets/images/icons/logo-and-branding.svg" alt="case study" class="img-fluid">
              <h3>UI/UX Graphic Design</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Solutions-->
<div class="case-study pad-tb">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="project-screens owl-carousel">
          <div class="screen-splits"> <img src="assets/images/case-study/cules-1.jpg" alt="case study"
              class="img-fluid" /></div>
          <div class="screen-splits"> <img src="assets/images/case-study/cules-2.jpg" alt="case study"
              class="img-fluid" /></div>
          <div class="screen-splits"> <img src="assets/images/case-study/cules-3.jpg" alt="case study"
              class="img-fluid" /></div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Start Navigate-->
<section class="testinomial-section pad-tb">
  <div class="container">
    <div class="row">
      <div class="col-6 v-center">
        <a [routerLink]="['/', 'portfolio', previousLink]" class="nav_link" i18n>&laquo; Previous</a>
      </div>
      <div class="col-6 v-center">
        <a [routerLink]="['/', 'portfolio', nextLink]" class="nav_link next_nav_link" i18n>Next &raquo;</a>
      </div>
    </div>
  </div>
</section>
<!--End Navigate-->

<!--Start Testinomial-->
<app-testinomial-component></app-testinomial-component>
<!--End Testinomial-->

// https://www.techiediaries.com/angular/angular-9-8-tutorial-by-example-rest-crud-apis-http-get-requests-with-httpclient/
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { Contact } from '../model/contact.model';
import { Career } from '../model/career.model';
import { Quote } from '../model/quote.model';
import { Response, ResponseWithoutMeta } from '../types/common';
import { ShowCase } from '../types/showcase';


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private REST_API_SERVER = environment.apiUrl;
  private API_URL_STRAPI = environment.apiStrapi

  constructor(private httpClient: HttpClient) { }
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  public getShowcases(){
    return this.httpClient.get<Response<ShowCase[]>>(`${this.API_URL_STRAPI}/showcases?populate=*`).pipe().toPromise()
  }

  public getShowcase(slug:string){
    return this.httpClient.get<ResponseWithoutMeta<ShowCase[]>>(`${this.API_URL_STRAPI}/showcases?filters[slug][$eq]=${slug}&populate=*`).pipe().toPromise()
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(error);
  }

  public get(endpoint) {
    return this.httpClient.get(this.REST_API_SERVER + endpoint, this.httpOptions).pipe(catchError(this.handleError)).toPromise();
  }

  public post(endpoint, data) {
    return this.httpClient.post<any>(this.REST_API_SERVER + endpoint, JSON.stringify(data), this.httpOptions).pipe(catchError(this.handleError)).toPromise();
  }

  public patch(endpoint, data) {
    return this.httpClient.patch<any>(this.REST_API_SERVER + endpoint, JSON.stringify(data), this.httpOptions).pipe(catchError(this.handleError)).toPromise();
  }

  public delete(endpoint) {
    return this.httpClient.delete(this.REST_API_SERVER + endpoint, this.httpOptions).pipe(catchError(this.handleError)).toPromise();
  }


  handleSlackError(error: HttpErrorResponse, lang: string) {
    let errorMessage = 'Unknown error!';
    if (error.status == 200) {
      if (lang === 'en') {
        alert("Send message successfully, We will reply soon!");
        return;
      } else {
        alert("Lời nhắn của bạn đã được gửi thành công. Dicom Interactive sẽ phản hồi bạn sớm nhất có thể!");
        return;
      }
    } else {
      if (lang === 'en') {
        alert("Send message failed, Please try again!");
      } else {
        alert("Gửi không thành công. Vui lòng thử lại.");
      }
      return throwError(error);
    }
  }

  handleCareerError(error: HttpErrorResponse, lang: string) {
    let errorMessage = 'Unknown error!';
    if (error.status == 200) {
      if (lang === 'en') {
      alert("Thank for your applied, We will contact you as soon as possible!");
      } else {
        alert(" Cảm ơn bạn đã ứng tuyển, Dicom Interactive sẽ liên hệ lại trong thời gian sớm nhất.")
      }
      return;
    } else {
      if (lang === 'en') {
        alert("Send applicant form failed, Please try again!");
      } else {
        alert('Gửi không thành công. Vui lòng thử lại.!')
      }
      return throwError(error);
    }
  }

  handleSlackSubscribeError(error: HttpErrorResponse, lang: string) {
    let errorMessage = 'Unknown error!';
    if (error.status == 200) {
      if (lang === 'en') {
        alert("Thanks you for your subscribe!");
      } else {
        alert("Cảm ơn bạn đã đăng ký.")
      }
      return;
    } else {
      if (lang === 'en') {
        alert("Subscribe failed, Please try again!");
      } else {
        alert("Đăng ký không thành công. Vui lòng thử lại")
      }
      return throwError(error);
    }
  }

  // Slack reponse 200 also error
  public sendContact2Slack(contact: Contact, lang: string) {
    const data = {
      "text": `From: ${contact.name}, countryName: ${contact.countryName}, countryCode: ${contact.countryCode}, phone: ${contact.phone}, email: ${contact.email}, Subject: Need ${contact.subject}, message: ${contact.message}`,
      "icon_emoji": ":robot_face:",
      "username": "Dicom Bot"
    }
    console.log("Slack:: ", data);
    const slackEndpoint = "https://hooks.slack.com/services/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";
    return this.httpClient.post<any>(slackEndpoint, JSON.stringify(data)).pipe(catchError((error) => this.handleSlackError(error, lang)) ).toPromise();
  }

  // Slack reponse 200 also error
  public sendSub2Slack(param, lang: string) {
    const data = {
      "text": `Subscribe Email: ${param.email}`,
      "icon_emoji": ":robot_face:",
      "username": "Dicom Bot"
    }
    console.log("Slack:: ", data);
    const slackEndpoint = "https://hooks.slack.com/services/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";
    return this.httpClient.post<any>(slackEndpoint, JSON.stringify(data)).pipe(catchError((error) => this.handleSlackSubscribeError(error, lang))).toPromise();
  }

  public handleCareerApply(career: Career, fileList: FileList, lang: string) {
    const endpoint = this.REST_API_SERVER + '/dicom/career-apply';
    const formData: FormData = new FormData();
    formData.append('lang', lang);
    Array.from(fileList).forEach((file, index) => {
      formData.append('file' + index, file, file.name);
    });
    for (let [key, value] of Object.entries(career)) {
      formData.append(key, value);
    }
    return this.httpClient.post<any>(endpoint, formData, { headers: {} }).pipe(catchError((error) => this.handleCareerError(error, lang))).toPromise();
  }

  public handleDevelopWebRequest(contact: Contact, lang: string) {
    const endpoint = this.REST_API_SERVER + '/dicom/develop-web-request';
    const formData: FormData = new FormData();
    for (let [key, value] of Object.entries(contact)) {
      formData.append(key, value);
    }
    return this.httpClient.post<any>(endpoint, formData, { headers: {} }).pipe(catchError((error) => this.handleCareerError(error, lang))).toPromise();
  }


  public handleFreeQuoteRequest( quote: Quote, fileList: FileList, lang: string ) {
    const endpoint = this.REST_API_SERVER + '/dicom/free-quote-request';
    const formData: FormData = new FormData();
    formData.append('lang', lang);
    if (quote.subject === 'web') {
      if (quote.files !== undefined) {
        Array.from(fileList).forEach((file, index) => {
          formData.append('file' + index, file, file.name);
        });
      }
    }
    for (let [key, value] of Object.entries(quote)) {
      formData.append(key, value);
    }
    return this.httpClient.post<any>(endpoint, formData, { headers: {} }).pipe(catchError((error) => this.handleCareerError(error, lang))).toPromise();
  }
}

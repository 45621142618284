
  <!--Breadcrumb Area-->
  <section class="breadcrumb-areav2" data-background="assets/images/banner/8.jpg">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-7">
          <div class="bread-titlev2">
            <h1 class="wow fadeInUp" data-wow-delay=".2s" i18n="@@mobile_app_development">Mobile App Development</h1>
            <p class="mt20 wow fadeInUp" data-wow-delay=".4s" i18n>Mobile App that matters</p>
            <a [routerLink]="['/contact']" class="btn-main bg-btn2 lnk mt20 wow zoomInDown" (click)="onContactApp()" data-wow-delay=".6s" i18n>Get Quote <i
                class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- <div class="statistics-wrap bg-gradient5">
    <div class="container">
      <div class="row small t-ctr mt0">
        <div class="col-lg-3 col-sm-6">
          <div class="statistics">
            <div class="statistics-img">
              <img src="assets/images/icons/deal.svg" alt="happy" class="img-fluid">
            </div>
            <div class="statnumb">
              <span class="counter">40</span><span>+</span>
              <p>Happy Clients</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6">
          <div class="statistics">
            <div class="statistics-img">
              <img src="assets/images/icons/computers.svg" alt="project" class="img-fluid">
            </div>
            <div class="statnumb counter-number">
              <span class="counter">60</span><span>+</span>
              <p>Projects Done</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6">
          <div class="statistics">
            <div class="statistics-img">
              <img src="assets/images/icons/worker.svg" alt="work" class="img-fluid">
            </div>
            <div class="statnumb">
              <span class="counter">50</span><span>k</span>
              <p>Hours Worked</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6">
          <div class="statistics mb0">
            <div class="statistics-img">
              <img src="assets/images/icons/customer-service.svg" alt="support" class="img-fluid">
            </div>
            <div class="statnumb">
              <span class="counter">24</span><span>/</span><span class="counter">7</span>
              <p>Support Available</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <app-services-counter></app-services-counter>
  <!--End Hero-->
  <!--Start About-->
  <section class="service pad-tb bg-gradient5">
    <div class="container">
      <div class="row">
        <div class="col-lg-4">
          <div class="image-block wow fadeIn">
            <img src="assets/images/service/app-develops.png" alt="image" class="img-fluid no-shadow" />
          </div>
        </div>
        <div class="col-lg-8 block-1">
          <div class="common-heading text-l pl25">
            <span i18n>Overview</span>
            <h2 i18n>Custom Mobile App Solutions</h2>
            <p i18n>Usage of mobile devices has exploded over the last decade. Consumers spend more than 3 hours  every day on
              their smartphones or tablets. This is a great opportunity for companies to transform their operations
              taking mobile first approach. Custom mobile applications increase customer engagement and open access to
              new revenue channels.</p>
            <p i18n>Over the last decade, our teams at Dicom Interactive have created iOS and Android mobile apps for
              companies across Europe, Asia, and Australia. As one of the top mobile app development companies in
              Vietnam, Dicom Interactive possesses process and experience in creating mobile apps for transportation,
              entertainment, game, healthcare, pharmaceutical, education industries. We offer full cycle of app
              development from app design to programming through its launch.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--End About-->
  <section class="service pad-tb">
    <div class="container">
      <div class="row">
        <div class="col-lg-7">
          <div class="text-l service-desc- pr25">
            <h5 class="mt10 mb20 fron-mobile" i18n>If you need our consultation in which type of mobile app development to go with, give
              us a call or drop us a message today. It’s totally free.</h5>
            <p i18n>Modern mobile applications are complex that many of them involve data storage, multimedia, payment
              gateway, Internet of things, augmented reality, AI & machine learning... Dicom Interactive has expertise
              in end-to-end mobile app development which requires the integration of all of the above, that will help
              you stay ahead of the competition and transform your business, turn your app idea into a successful story.
            </p>
            <a [routerLink]="['/contact']" class="btn-main bg-btn2 lnk mt30" (click)="onContactApp()" i18n>
              Get in touch with us today <i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span>
            </a>
          </div>
        </div>
        <div class="col-lg-5">
          <div class="servie-key-points mt30">
            <h4 i18n>Advantages of Mobile App Development</h4>
            <ul class="key-points mt20">
              <li i18n>Strengthen customer engagement</li>
              <li i18n>Increase accessibility</li>
              <li i18n>Provide value for customers</li>
              <li i18n>Transforming the retail experience</li>
              <li i18n>Build brand awareness and recognition</li>
              <li i18n>Stand out from the competition</li>
              <li i18n>Cultivate customer loyalty</li>
              <li i18n>Unique services and payment</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--Start Tech-->
  <div class="techonology-used-">
    <div class="container">
      <ul class="h-scroll tech-icons">
        <li><a [routerLink]="[]"><img src="assets/images/icons/stack-icon16.png" alt="icon"></a></li>
        <li><a [routerLink]="[]"><img src="assets/images/icons/stack-icon17.png" alt="icon"></a></li>
        <li><a [routerLink]="[]"><img src="assets/images/icons/stack-icon18.png" alt="icon"></a></li>
        <li><a [routerLink]="[]"><img src="assets/images/icons/stack-icon19.png" alt="icon"></a></li>
        <li><a [routerLink]="[]"><img src="assets/images/icons/stack-icon20.png" alt="icon"></a></li>
        <li><a [routerLink]="[]"><img src="assets/images/icons/stack-icon21.png" alt="icon"></a></li>
      </ul>
    </div>
  </div>
  <!--End Tech-->
  <!--Start Service-->
  <section class="service-block bg-gradient6 pad-tb">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="common-heading ptag">
            <span i18n>Service</span>
            <h2 i18n="@@our_service">Our Services</h2>
            <p class="mb30" i18n>We think big and have hands in all leading technology platforms to provide you wide array of
              services.</p>
          </div>
        </div>
      </div>
      <div class="row upset link-hover">
        <div class="col-lg-6 col-sm-6 mt30  wow fadeInUp" data-wow-delay=".2s">
          <div class="s-block wide-sblock hfull">
            <div class="s-card-icon-large"><img src="assets/images/service/mob-service-item2.png" alt="service"
                class="img-fluid" /></div>
            <div class="s-block-content-large">
              <h4 i18n>iOS App Development</h4>
              <p i18n>The software is written in the Swift programming language or Objective-C and then deployed to the
                Appstore for users to download.</p>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".4s">
          <div class="s-block wide-sblock hfull">
            <div class="s-card-icon-large"><img src="assets/images/service/mob-service-item1.png" alt="service"
                class="img-fluid" /></div>
            <div class="s-block-content-large">
              <h4 i18n>Android App Development</h4>
              <p i18n>The software is written in the Java programming language or Kotlin and then deployed to the Google Play
                Store for users to download.</p>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".6s">
          <div class="s-block wide-sblock hfull">
            <div class="s-card-icon-large"><img src="assets/images/service/mob-service-item3.png" alt="service"
                class="img-fluid" /></div>
            <div class="s-block-content-large">
              <h4 i18n>Cross Platform Development</h4>
              <p i18n>The software is written in the React Native programming language or Dart and then deployed to both the
                Google Play Store and the Appstore for users to download.</p>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".8s">
          <div class="s-block wide-sblock hfull">
            <div class="s-card-icon-large"><img src="assets/images/service/mob-service-item4.png" alt="service"
                class="img-fluid" /></div>
            <div class="s-block-content-large">
              <h4 i18n>Wearable App Development</h4>
              <p i18n>With the health and wellness awareness increasing around the globe, wearable app development is also
                getting more value and popularity.</p>
            </div>
          </div>
        </div>


      </div>
      <div class="-cta-btn mt70">
        <div class="free-cta-title v-center wow zoomInDown" data-wow-delay="1.1s">
          <p i18n>Hire a <span>Dedicated Developer</span></p>
          <a [routerLink]="['/contact']" (click)="onContactApp()" class="btn-main bg-btn2 lnk" i18n>Hire Now<i class="fas fa-chevron-right fa-icon"></i><span
              class="circle"></span></a>
        </div>
      </div>
    </div>
  </section>
  <!--End Service-->
  <!--Start Key points-->
  <section class="service pad-tb light-dark">
    <div class="container">
      <div class="row">
        <div class="col-lg-5">
          <div class="single-image wow fadeIn">
            <img src="assets/images/service/branding-app-development1.jpg" alt="image" class="img-fluid no-shadow" />
          </div>
        </div>
        <div class="col-lg-7">
          <div class="text-l service-desc- pr25">
            <h3 class="mb20" i18n>iOS App Development</h3>
            <p i18n>We have been with iOS apps almost from the first release of iOS SDK. In other words, we leverage the
              strengths of every platform to be successfully giving astonishing solutions across iPhone, iPad and Apple
              Watch. We here at Dicom Interactive specialize in creating incredible iOS apps using its latest SDKs and
              with a seamless native user interface design. We have also set the highest benchmark for select-services
              for which we are most loved!</p>
            <div class="row">
              <div class="col-md-6 mt10 mobile-lang">
                <ul class="list-ul ul-check">
                  <li i18n>IPhone / IPad Development.</li>
                  <li i18n>Swift.</li>
                </ul>
              </div>
              <div class="col-md-6 mt10 mobile-lang">
                <ul class="list-ul ul-check">
                  <li i18n>Objective-C.</li>
                  <li i18n>Apple Watch.</li>
                </ul>
              </div>
            </div>
            <a [routerLink]="['/contact']" class="btn-main bg-btn2 lnk mt30" (click)="onContactApp()" i18n>Request A Quote <i
                class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="service pad-tb light-dark">
    <div class="container">
      <div class="row">
        <div class="col-lg-7 single-image-app2">
          <div class="text-l service-desc- pr25">
            <h3 class="mb20" i18n>Android App Development</h3>
            <p i18n>Same as for iOS SDK, we have been developing Android apps since the earliest days of cupcake. We are not
              only developing but also taking the advantages of everything that OS offers in order to build spectacular
              Android applications. Our teams at Dicom Interactive have been giving our best to create the most
              astonishing and robust Android applications that could be running seamlessly across all kinds of common
              Android devices and versions.</p>
            <div class="row">
              <div class="col-md-6 mt10 mobile-lang">
                <ul class="list-ul ul-check">
                  <li i18n>Java.</li>
                  <li i18n>Kotlin.</li>
                </ul>
              </div>
              <div class="col-md-6 mt10 mobile-lang">
                <ul class="list-ul ul-check">
                  <li i18n>Android TV.</li>
                  <li i18n>Custom-embedded Android ROM.</li>
                </ul>
              </div>
            </div>
            <a [routerLink]="['/contact']" class="btn-main bg-btn2 lnk mt30" (click)="onContactApp()" i18n>Request A Quote <i
                class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
          </div>
        </div>
        <div class="col-lg-5 single-image-app1">
          <div class="single-image wow fadeIn">
            <img src="assets/images/service/branding-app-development2.jpg" alt="image" class="img-fluid no-shadow" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="service pad-tb light-dark">
    <div class="container">
      <div class="row">
        <div class="col-lg-5">
          <div class="single-image wow fadeIn">
            <img src="assets/images/service/branding-app-development3.jpg" alt="image" class="img-fluid no-shadow" />
          </div>
        </div>
        <div class="col-lg-7">
          <div class="text-l service-desc- pr25">
            <h3 class="mb20" i18n>Cross-Platform App Development</h3>
            <p i18n>When it comes to cross-platform solution, we know you want something that could handle a moderate
              complexity and be having almost the same feel and performance of a native one. Cross-platform app
              development, also called Hybrid app development, aims to fasten the development. If it’s the case, then
              Flutter / React Native will definitely be a good choice for you. With Flutter / React Native, we could
              write all apps in the same programming language and at the same time, share the source code across all the
              mobile development platforms. And since we are good at handling each platform natively, we know how to
              create great apps from single code base that look good and feel great.</p>
            <div class="row">
              <div class="col-md-6 mt10 mobile-lang">
                <ul class="list-ul ul-check">
                  <li i18n>React Native.</li>
                  <li i18n>Flutter.</li>
                  <li i18n>Firebase.</li>
                </ul>
              </div>
              <div class="col-md-6 mt10 mobile-lang">
                <ul class="list-ul ul-check">
                  <li i18n>Cloud Computing.</li>
                  <li i18n>Serverless.</li>
                  <li i18n>Backend Restful API.</li>
                </ul>
              </div>
            </div>
            <a [routerLink]="['/contact']" class="btn-main bg-btn2 lnk mt30" (click)="onContactApp()" i18n>Request A Quote <i
                class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--End Key points-->
  <!--Start Process-->
  <section class="service-block pad-tb bg-gradient5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="common-heading ptag">
            <span i18n>Process</span>
            <h2 i18n>Our App Development Process</h2>
            <p i18n>Our design process follows a proven approach. We begin with a deep understanding of your needs and create
              a planning template.</p>
          </div>
        </div>
      </div>
      <div class="row upset justify-content-center mt60">
        <div class="col-lg-4 v-center order1 order-app1">
          <div class="image-block1">
            <img src="assets/images/process/process-app-1.jpg" alt="Process" class="img-fluid" />
          </div>
        </div>
        <div class="col-lg-7 v-center order2 order-app2">
          <div class="ps-block">
            <span>1</span>
            <h3 i18n>Requirement Gathering</h3>
            <p i18n>At this first step, we would like to know what kind of application you desire, what is the budget and
              timeline you expect. The more we know about your idea, the better we could further assist you.<br><br></p>
            <p i18n>Once we finish analyzing your requirements, we start defining use cases and capturing detailed functional
              requirements.</p>
          </div>
        </div>
      </div>
      <div class="row upset justify-content-center mt60">
        <div class="col-lg-7 v-center order2 order-app2">
          <div class="ps-block">
            <span>2</span>
            <h3 i18n>Prototype & Design</h3>
            <p i18n>In order to make sure we are on the same page, we build the wireframe, which could be understood as the
              pillar for our application. It will also help us to give the precise estimate of the development as well
              as assist our designer in creating accurate designs.<br><br></p>
            <p i18n>The purpose of UI/UX design is to create and deliver seamless user experiences that makes your app
              interactive, intuitive and user-friendly.</p>
          </div>
        </div>
        <div class="col-lg-4 v-center order1 order-app1">
          <div class="image-block1 image-mobile">
            <img src="assets/images/process/process-app-2.jpg" alt="Process" class="img-fluid" />
          </div>
        </div>
      </div>
      <div class="row upset justify-content-center mt60">
        <div class="col-lg-4 v-center order1 order-app1">
          <div class="image-block1 image-mobile">
            <img src="assets/images/process/process-app-3.jpg" alt="Process" class="img-fluid" />
          </div>
        </div>
        <div class="col-lg-7 v-center order2 order-app2">
          <div class="ps-block">
            <span>3</span>
            <h3 i18n>Development & QA</h3>
            <p i18n>Our mobile app developers come in to bring the app designs into reality. Our professional working process
              makes sure every task would be assigned to developers precisely and every app development steps in the
              right place to achieve the expected milestones.<br><br></p>
            <p i18n>And just before your app comes to store, our QA engineers get down to perform various tests to make sure
              that the app stable and works flawlessly.</p>
          </div>
        </div>
      </div>
      <div class="row upset justify-content-center mt60">
        <div class="col-lg-7 v-center order2 order-app2">
          <div class="ps-block">
            <span>4</span>
            <h3 i18n>Launch & Support</h3>
            <p i18n>We will assist you in uploading your application on Appstore and / or Google Playstore without any
              additional fee. We will need your developer account before launching your mobile app.<br><br></p>
            <p i18n>After a few hours (for Android) or days (for iOS), your app becomes available in those stores, we will
              keep monitoring its usage through mobile analytics platforms and frequently check crash reports or any
              other issues.</p>
          </div>
        </div>
        <div class="col-lg-4 v-center order1 order-app1">
          <div class="image-block1 image-mobile">
            <img src="assets/images/process/process-app-4.jpg" alt="Process" class="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--End Process-->

<!-- why us, some works, let work together -->
<app-services-why-us></app-services-why-us>

// https://stackoverflow.com/questions/57720654/after-change-route-jquery-not-working-when-refresh-again-then-work-correctly
// core
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';

// self
import { ScriptService, LangService, MetaDataService } from '../services';
import { SharedService } from '../services/sharedService.service';

@Component({
  selector: 'app-services-mobile-development',
  templateUrl: './mobile-development.component.html',
  styleUrls: ['./service-detail.component.css']
})
export class MobileDevelopmentComponent extends MetaDataService implements OnInit {
  // for load init script
  isLoad = false;
  public current_lang: any = {};

  constructor(
    private scriptService: ScriptService,
    private langService: LangService,
    private sharedService: SharedService,
    private location: Location,
    private router: Router,
    title: Title,
    meta: Meta,
  ) {
    super(title, meta);
    // this.title.setTitle("Mobile App Development | Dicom Interactive");
    // this.meta.updateTag({name: "description", content: `Dicom Interactive have created iOS and Android mobile apps for companies across Europe, Asia, and Australia. As one of the top mobile app development companies in Vietnam, Dicom Interactive possesses process and experience in creating mobile apps for transportation, entertainment, game, healthcare, pharmaceutical, education industries. We offer full cycle of app development from app design to programming through its launch.`});
    this.updateTags(
      'Mobile App Development',
      'Dicom Interactive have created iOS and Android mobile apps for companies across Europe, Asia, and Australia. As one of the top mobile app development companies in Vietnam, Dicom Interactive possesses process and experience in creating mobile apps for transportation, entertainment, game, healthcare, pharmaceutical, education industries. We offer full cycle of app development from app design to programming through its launch.',
      this.location.prepareExternalUrl(this.router.url),
    );
    
    // this._loadScript("assets/js/jquery-2.0.0.min.js");
    // this._loadScript("assets/js/bootstrap.js");
    // this._loadScript("assets/vendor/owl-carousel/js/owl.carousel.min.js");
    // this._loadScript("assets/vendor/revslider/js/jquery.themepunch.tools.min.js");
    // this._loadScript("assets/vendor/revslider/js/jquery.themepunch.revolution.min.js");
    // this._loadScript("assets/js/init.js");
    // this._loadScript("assets/js/slide.js");
    this.scriptService.load(this.scriptService.INIT).then(() => {
      this.isLoad = true;
    });
    // setTimeout(() => { this.isLoad = true }, 1000);
    // save for contact page
    // localStorage.setItem('contact_subject', '');
  }

  async ngOnInit() {
    this.current_lang = await this.langService.loadLang();
  }

  onContactApp() {
    this.sharedService.changeSubject('app');
  }

}

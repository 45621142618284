<!--Breadcrumb Area-->
<section class="breadcrumb-area banner-5">
  <div class="text-block">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 v-center">
          <div class="bread-inner">
            <div class="bread-menu">
              <ul>
                <li><a [routerLink]="['/']" i18n>Home</a></li>
                <li><a [routerLink]="['/portfolio' , '']" i18n>Portfolio</a></li>
              </ul>
            </div>
            <div class="bread-title">
              <h1 i18n>Ask2Go Mobile Applications</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Breadcrumb Area-->
<!--Start Case Study About-->
<section class="case-study pad-tb">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="image-block upset">
          <img src="assets/images/case-study/app-intro.png" alt="case study" class="img-fluid no-shadow" />
        </div>
      </div>
      <div class="col-lg-6 block-1 v-center">
        <div class="common-heading inner-heading text-l pl25">
          <span i18n>About The Project</span>
          <h2 i18n>On-Demand Travel Guides Application</h2>
          <p i18n>2 separate mobile apps (Traveler & Guru) which offers online travel guru to someone who wants
            to
            explore new places. Travelers just need asks relevant questions and pick a suitable guru to
            help.</p>
          <p i18n>Users who join the platform as travel guru could be booked as an online tour guide for many
            virtual tours they could handle simultaneously.</p>
          <p><strong> Swift 5.2 | Java | Python | Redis | Stripe | DigitalOcean </strong></p>
        </div>
        <!-- <div class="project-platform mt60 pl25">
          <div class="project-platform-used -shadow"><a [routerLink]="[]"><img src="assets/images/icons/android.svg"
                alt="android"></a></div>
          <div class="project-platform-used -shadow"><a [routerLink]="[]"><img src="assets/images/icons/apple.svg"
                alt="apple"></a></div>
        </div> -->
      </div>
    </div>
  </div>
</section>
<!--End Case Study About-->
<section class="case-study pad-tb bg-gradient6 deep-dark">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <div class="common-heading">
          <span i18n>App Features</span>
          <h2 class="mb30" i18n>As per client's need, we have developed 2 Mobile Apps: Ask2Go Traveler & Guru.
          </h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <h4 class="mt20 mb10" i18n>Traveler App Features</h4>
        <ul class="list-style-">
          <li i18n>Choose a service you want. I could be Nightlife, History, Food & Restaurant or whatsoever.
          </li>
          <li i18n>Type a question and wait for travel guru to answer. You could tip him if he manages to help
            you out.</li>
          <li i18n>Scroll through the list of travel guru and find yourself a suitable one to book for your
            upcoming trip.</li>
          <li i18n>Pay your travel guru with just a few steps with online payment gateway which already
            integrated.</li>
          <li i18n>Rate your trip, leave a comment, follow your guru are some of the actions you could do with
            this platform.</li>
          <li i18n>Join the community as travel guru and start earning wherever you are in the world.</li>
        </ul>
      </div>
      <div class="col-lg-6">
        <h4 class="mt20 mb10" i18n>Guru App Features</h4>
        <ul class="list-style-">
          <li i18n>Register and upload certificate image as online travel guru.</li>
          <li i18n>Write relevant articles and photos in profile page in order to drive traffic.</li>
          <li i18n>Pick up and help answer travel question and receives either review or tip.</li>
          <li i18n>Select travel category to help with and be booked by travelers.</li>
          <li i18n>Online support travelers by chatting with them during their trip.</li>
          <li i18n>Helpful travel guru should be always rewarded with high rating, review and tip.</li>
          <li i18n>Service fee and tip will be settled monthly over our international online payment gateway.
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
<!--Start Project Challenges-->
<section class="case-study pad-tb">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-sm-6">
        <h2 class="mt20 mb20" i18n>Challenges</h2>
        <p i18n>Our client of this project doesn't have technical background so that means it takes us a while to
          fully grasp the idea of this service and that same amount of time to analyze, consult and put
          them together into a wireframe.<br><br></p>
        <p i18n>This project, which is inclusive of 2 separate mobile applications, requires a handful of
          experienced developers as well as clear project management processes.<br><br></p>
        <p i18n>Our client wants to eventually roll this out to global which requires us to research and
          determine
          suitable 3rd services in terms of server, payment gateway, supported languages, time zone... and
          also a programming framework that could be easily scaled up.</p>
      </div>
      <div class="col-lg-6 col-sm-6">
        <div class="project-view m-mt30">
          <img src="assets/images/case-study/app-view.png" alt="case study" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Project Challenges-->
<!--Start Solutions-->
<section class="case-study pad-tb bg-gradient5 deep-dark">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 block-1 v-center">
        <div class="common-heading inner-heading text-l pr25">
          <span i18n>Solutions</span>
          <h2 i18n>How We Work</h2>
          <h4 i18n>We decided to go with native programming languages for this mobile app development.</h4>
          <p i18n>First off, we sketch the layout and finetune it various number of times until it meets our
            client expectation. And then, based on that sketch, our designers breathe life into it with
            colors, logo, graphic, animation... So that the applications could have a tangible identity.
          </p>
          <p i18n>During the development stage, we define milestones that we deliver shippable package to our
            client so we could make sure we are going on the right path.</p>
        </div>
        <div class="card-nx row">
          <div class="col-lg-3 col-md-6 col-sm-6 col-6 mt30">
            <div class="icon-card- hfull">
              <img src="assets/images/icons/branding.svg" alt="case study" class="img-fluid">
              <h3>Mobile App Development</h3>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-6 mt30">
            <div class="icon-card- hfull">
              <img src="assets/images/icons/back.svg" alt="case study" class="img-fluid">
              <h3>Back-end/API Development</h3>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-6 mt30">
            <div class="icon-card- hfull">
              <img src="assets/images/icons/seo.svg" alt="case study" class="img-fluid">
              <h3>Payment Gateway Integration</h3>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-6 mt30">
            <div class="icon-card- hfull">
              <img src="assets/images/icons/logo-and-branding.svg" alt="case study" class="img-fluid">
              <h3>UI/UX Graphic Design</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Solutions-->
<div class="case-study pad-tb">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="project-screens owl-carousel">
          <div class="screen-splits"> <img src="assets/images/case-study/restro-app-1.jpg" alt="case study"
              class="img-fluid" /></div>
          <div class="screen-splits"> <img src="assets/images/case-study/restro-app-2.jpg" alt="case study"
              class="img-fluid" /></div>
          <div class="screen-splits"> <img src="assets/images/case-study/restro-app-3.jpg" alt="case study"
              class="img-fluid" /></div>
          <div class="screen-splits"> <img src="assets/images/case-study/restro-app-4.jpg" alt="case study"
              class="img-fluid" /></div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Start Navigate-->
<section class="testinomial-section pad-tb">
  <div class="container">
    <div class="row">
      <div class="col-6 v-center">
        <a [routerLink]="['/', 'portfolio', previousLink]" class="nav_link" i18n>&laquo; Previous</a>
      </div>
      <div class="col-6 v-center">
        <a [routerLink]="['/', 'portfolio', nextLink]" class="nav_link next_nav_link" i18n>Next &raquo;</a>
      </div>
    </div>
  </div>
</section>
<!--End Navigate-->

<!--Start Testinomial-->
<app-testinomial-component></app-testinomial-component>
<!--End Testinomial-->
export class Career {
  name: string;
  email: string;
  phone: string;
  position: string;
  message: string;
  files: FileList;
  countryCode: string;
  countryName: string;
}

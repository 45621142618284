// language.service.ts

import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  changeLanguage(language: string, event: MouseEvent, Url: string = '') {
    let href = window.location.href;
    if (Url !== '') {
        href = `/${language}/blog/${Url}`;
    } else {
      event.preventDefault();
      if (language == 'en') {
        href = href.replace('/vn/', `/${language}/`);
      } else {
        href = href.replace('/en/', `/${language}/`);
      }
    }
    window.location.href = href;
  }
}

<!--Breadcrumb Area-->
<section class="breadcrumb-area banner-5">
  <div class="text-block">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 v-center">
          <div class="bread-inner">
            <div class="bread-menu">
              <ul>
                <li><a [routerLink]="['/']" i18n>Home</a></li>
                <li><a [routerLink]="['/portfolio' , '']" i18n>Portfolio</a></li>
              </ul>
            </div>
            <div class="bread-title">
              <h1 i18n>MeOTO - Auto Trader</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Breadcrumb Area-->
<!--Start Case Study About-->
<section class="case-study pad-tb">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="image-block upset">
          <img src="assets/images/case-study/meoto-intro.png" alt="case study" class="img-fluid no-shadow" />
        </div>
      </div>
      <div class="col-lg-6 block-1 v-center">
        <div class="common-heading inner-heading text-l pl25">
          <span i18n>About The Project</span>
          <h2 i18n>MeOTO used car Trading platform</h2>
          <p i18n>An auto trader service which will help both seller and buyer to trade their previously
            enjoyed
            car. Seller could be either individual or auto salon. </p>
          <p i18n>As an individual seller or auto salon, you could just submit your car to the platform
            with a pre-defined list of vehicles. You could also check the price of your rivals who are
            also selling that same car.</p>
          <p i18n>As a buyer, you could easily compare the price as well as other criteria of any cars which have been
            selling.</p>
          <p><strong> Angular 9 | NodeJS | MongoDB | Redis | Elasticsearch | AlePay | AWS </strong></p>
        </div>
        <!--div class="project-platform mt60 pl25">
              <div class="project-platform-used -shadow"><a href="#"><img src="assets/images/icons/android.svg"
                    alt="android"></a></div>
              <div class="project-platform-used -shadow"><a href="#"><img src="assets/images/icons/apple.svg"
                    alt="apple"></a></div>
            </div-->
      </div>
    </div>
  </div>
</section>
<!--End Case Study About-->
<section class="case-study pad-tb bg-gradient6 deep-dark">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <div class="common-heading">
          <span i18n>Service Features</span>
          <h2 class="mb30" i18n>As per client's need, we have developed a mobile-friendly web service.
          </h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <h4 class="mt20 mb10" i18n>Selling Car</h4>
        <ul class="list-style-">
          <li i18n>Submitting and start selling your car with just a few clicks. You could easily select your
            car make and model from the pre-defined list or entering its detail yourself.
          </li>
          <li i18n>System also suggests you the price of your car based upon its detail compared to other one
            so then it will get more attentions.</li>
        </ul>
      </div>
      <div class="col-lg-6">
        <h4 class="mt20 mb10" i18n>Buying Car</h4>
        <ul class="list-style-">
          <li i18n>Browsing from thousands of both brand-new and used cars from the platform and pick one that
            suits your need the most.</li>
          <li i18n>Buyer could contact the seller directly or via chat service which is embedded into the
            platform.
          </li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <h4 class="mt20 mb10" i18n>Join as Salon</h4>
        <ul class="list-style-">
          <li i18n>Joining as salon, you will have your own page and your own list of cars, and you are free to
            customize its layout.</li>
        </ul>
      </div>
    </div>
  </div>
</section>
<!--Start Project Challenges-->
<section class="case-study pad-tb">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-sm-6">
        <h2 class="mt20 mb20" i18n>Challenges</h2>
        <p i18n>Unrealistic deadline: MeOto wants to finish everything inclusive of responsive portal, API, CMS
          and mobile applications within a very short period of time. Dicom Interactive then advise to
          break it into smaller pieces and we come to an agreement to finish mobile application
          later. Dicom Interactive also needs to allocate more resources to make sure we finish their
          project as
          soon as we could but still meets their expectation and achieve the total quality.<br><br></p>
        <p i18n>Though the deadline is a bit tight, our client seems to be slow with communication which could
          negatively affect our project deadline. Dicom Interactive decides to take the initiative and not
          wait for their response but to use our own experience to move forward in the direction which we
          think the most suitable way and try to communicate with the production team and let them finish
          the next step as our assumption instead of waiting for the confirmation.</p>
      </div>
      <div class="col-lg-6 col-sm-6">
        <div class="project-view m-mt30">
          <img src="assets/images/case-study/meoto-view.png" alt="case study" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Project Challenges-->
<!--Start Solutions-->
<section class="case-study pad-tb bg-gradient5 deep-dark">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 block-1 v-center">
        <div class="common-heading inner-heading text-l pr25">
          <span i18n>Solutions</span>
          <h2 i18n>How We Work</h2>
          <h4 i18n>We decide to go with AngularJS for the Front-end, NodeJS for the Back-end, Redis
            as the database, Alepay as Payment Gateway and AWS as our web service.<br><br></h4>
          <p i18n>We spend weeks to talk with MeOto to understand what their expectation is. Simultaneously, we analyze and
            sketch out their ideas and we work together
            until that sketch becomes our final interactive prototype.
          </p>
          <p i18n>Right after we finalize the wireframe and get the contract, our designers come in and
            collaborate on the vision then created 2 different look-and-feel homepages so that MeOto
            could pick one. We then adapt that style into all other pages and on each page finish, we
            check and fine-tune it with the client.
          </p>
          <p i18n>During the development stage, we define milestones that we deliver shippable package to our
            client so we could make sure we are going on the right path.</p>
        </div>
        <div class="card-nx row">
          <div class="col-lg-3 col-md-6 col-sm-6 col-6 mt30">
            <div class="icon-card- hfull">
              <img src="assets/images/icons/branding.svg" alt="case study" class="img-fluid">
              <h3>Front-end Development</h3>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-6 mt30">
            <div class="icon-card- hfull">
              <img src="assets/images/icons/back.svg" alt="case study" class="img-fluid">
              <h3>Back-end/API Development</h3>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-6 mt30">
            <div class="icon-card- hfull">
              <img src="assets/images/icons/seo.svg" alt="case study" class="img-fluid">
              <h3>Payment Gateway Integration</h3>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-6 mt30">
            <div class="icon-card- hfull">
              <img src="assets/images/icons/logo-and-branding.svg" alt="case study" class="img-fluid">
              <h3>UI/UX Graphic Design</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Solutions-->
<div class="case-study pad-tb">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="porto-slide owl-carousel">
          <div class="screen-splits"> <img src="assets/images/case-study/meoto-web-1.jpg" alt="case study"
              class="img-fluid" /></div>
          <div class="screen-splits"> <img src="assets/images/case-study/meoto-web-2.jpg" alt="case study"
              class="img-fluid" /></div>
          <div class="screen-splits"> <img src="assets/images/case-study/meoto-web-3.jpg" alt="case study"
              class="img-fluid" /></div>
          <div class="screen-splits"> <img src="assets/images/case-study/meoto-web-4.jpg" alt="case study"
              class="img-fluid" /></div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Start Navigate-->
<section class="testinomial-section pad-tb">
  <div class="container">
    <div class="row">
      <div class="col-6 v-center">
        <a [routerLink]="['/', 'portfolio', previousLink]" class="nav_link" i18n>&laquo; Previous</a>
      </div>
      <div class="col-6 v-center">
        <a [routerLink]="['/', 'portfolio', nextLink]" class="nav_link next_nav_link"i18n>Next &raquo;</a>
      </div>
    </div>
  </div>
</section>
<!--End Navigate-->


<!--Start Testinomial-->
<app-testinomial-component></app-testinomial-component>
<!--End Testinomial-->
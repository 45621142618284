// https://stackoverflow.com/questions/57720654/after-change-route-jquery-not-working-when-refresh-again-then-work-correctly
// core
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';

// self
import { ScriptService, LangService, MetaDataService } from '../services';
import { SharedService } from '../services/sharedService.service';

@Component({
  selector: 'app-services-graphic-design',
  templateUrl: './graphic-design.component.html',
  styleUrls: ['./service-detail.component.css']
})
export class GraphicDesignComponent extends MetaDataService implements OnInit {
  // for load init script
  isLoad = false;
  public current_lang: any = {};

  constructor(
    private scriptService: ScriptService,
    private langService: LangService,
    private sharedService: SharedService,
    private location: Location,
    private router: Router,
    title: Title,
    meta: Meta,
  ) {
    super(title, meta);
    // this.title.setTitle("Graphic Design Services | Dicom Interactive");
    // this.meta.updateTag({name: "description", content: `Like other businesses, you want more customers, plenty of them. How to make your potential customers cannot help but notice you amongst others? An effective design from a strong and consistent brand is definitely what you need. And that’s where Dicom Interactive can assist. We are expertise in creating artworks that help you form a comprehensive branding strategy across all media channels. We love doing graphic design and are good at it. But not to win any awards, we’re here to win over your audience. So let us help!`});
    this.updateTags(
      'Graphic Design Services',
      'Like other businesses, you want more customers, plenty of them. How to make your potential customers cannot help but notice you amongst others? An effective design from a strong and consistent brand is definitely what you need. And that’s where Dicom Interactive can assist. We are expertise in creating artworks that help you form a comprehensive branding strategy across all media channels. We love doing graphic design and are good at it. But not to win any awards, we’re here to win over your audience. So let us help!',
      this.location.prepareExternalUrl(this.router.url),
    );
    
    // this._loadScript("assets/js/jquery-2.0.0.min.js");
    // this._loadScript("assets/js/bootstrap.js");
    // this._loadScript("assets/vendor/owl-carousel/js/owl.carousel.min.js");
    // this._loadScript("assets/vendor/revslider/js/jquery.themepunch.tools.min.js");
    // this._loadScript("assets/vendor/revslider/js/jquery.themepunch.revolution.min.js");
    // this._loadScript("assets/js/init.js");
    // this._loadScript("assets/js/slide.js");
    this.scriptService.load(this.scriptService.INIT).then(() => {
      this.isLoad = true;
    });
    // setTimeout(() => { this.isLoad = true }, 1000);

    // save for contact page
    // localStorage.setItem('contact_subject', '');
  }

  async ngOnInit() {
    this.current_lang = await this.langService.loadLang();
  }

  onContactGra() {
    this.sharedService.changeSubject('graphic');
  }
}

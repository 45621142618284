<!--Breadcrumb Area-->
<section class="breadcrumb-area banner-3">
  <div class="text-block">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 v-center">
          <div class="bread-inner">
            <div class="bread-menu">
              <ul>
                <li><a [routerLink]="['/']" i18n>Home</a></li>
                <li><a [routerLink]="['/portfolio' , '']" i18n>Portfolio</a></li>
              </ul>
            </div>
            <div class="bread-title">
              <h1 i18n>Our Projects</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Breadcrumb Area-->
<!--Start Portfolio-->
<section class="portfolio-page pad-tb">
  <div class="container">
    <div class="row justify-content-left">
      <div class="col-lg-6">
        <div class="common-heading pp">
          <span i18n>Our Work</span>
          <h2 i18n>Awesome Creative Works</h2>
        </div>
      </div>
      <div class="col-lg-6 v-center">
        <div class="filters">
          <ul class="filter-menu">
            <li data-filter="*" class="is-checked" i18n>All</li>
            <li data-filter=".website" i18n>Website</li>
            <li data-filter=".app" i18n>Mobile App</li>
            <li data-filter=".graphic" i18n>Graphic</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row card-list">
      <div class="col-lg-4 col-md-6 grid-sizer"></div>
      <div class="col col-lg-4 col-sm-6 single-card-item website">
        <div class="card-item isotope_item hover-scale">
          <div class="item-image">
            <a [routerLink]="['/portfolio', 'white-spa-and-nail', '']"><img src="assets/images/portfolio/image-17.jpg"
                alt="portfolio" class="img-fluid" />
            </a>
          </div>
          <div class="item-info">
            <h4><a [routerLink]="['/portfolio', 'white-spa-and-nail', '']">White Spa & Nail site</a></h4>
            <p i18n>Wordpress Site</p>
          </div>
        </div>
      </div>
      <div class="col col-lg-4 col-sm-6 single-card-item website">
        <div class="card-item isotope_item hover-scale">
          <div class="item-image">
            <a [routerLink]="['/portfolio', 'keazaccess-platform', '']"><img src="assets/images/portfolio/image-16.jpg"
                alt="portfolio" class="img-fluid" />
            </a>
          </div>
          <div class="item-info">
            <h4><a [routerLink]="['/portfolio', 'keazaccess-platform', '']" i18n>KeazAccess Platform</a></h4>
            <p i18n>B2B Fleet Management System</p>
          </div>
        </div>
      </div>
      <div class="col col-lg-4 col-sm-6 single-card-item website">
        <div class="card-item isotope_item hover-scale">
          <div class="item-image">
            <a [routerLink]="['/portfolio', 'ucademy-platform', '']">
              <img src="assets/images/portfolio/image-15.jpg" alt="portfolio" class="img-fluid" />
            </a>
          </div>
          <div class="item-info">
            <h4><a [routerLink]="['/portfolio', 'ucademy-platform', '']" i18n>Ucademy Platform</a></h4>
            <p i18n>B2B LMS System</p>
          </div>
        </div>
      </div>
      <div class="col col-lg-4 col-sm-6 single-card-item website">
        <div class="card-item isotope_item hover-scale">
          <div class="item-image">
            <a [routerLink]="['/portfolio', 'mmhf-site', '']">
              <img src="assets/images/portfolio/image-14.jpg" alt="portfolio" class="img-fluid" />
            </a>
          </div>
          <div class="item-info">
            <h4><a [routerLink]="['/portfolio', 'mmhf-site', '']" i18n>MMHF Site</a></h4>
            <p i18n>Wordpress Site</p>
          </div>
        </div>
      </div>
      <!-- <div class="col-lg-4 col-sm-6 single-card-item website">
        <div class="isotope_item hover-scale">
          <div class="item-image">
            <a [routerLink]="['/portfolio', 'kmmt-vendor-site', '']">
              <img src="assets/images/portfolio/image-13.jpg" alt="portfolio" class="img-fluid" />
            </a>
          </div>
          <div class="item-info">
            <h4><a [routerLink]="['/portfolio', 'kmmt-vendor-site', '']">KMMT Vendor Site</a></h4>
            <p>Responsive Web Portal</p>
          </div>
        </div>
      </div> -->
      <div class="col col-lg-4 col-sm-6 single-card-item app">
        <div class="card-item isotope_item hover-scale">
          <div class="item-image">
            <a [routerLink]="['/portfolio', 'whipev-mobile-app', '']">
              <img src="assets/images/portfolio/image-12.jpg" alt="portfolio" class="img-fluid" />
            </a>
          </div>
          <div class="item-info">
            <h4><a [routerLink]="['/portfolio', 'whipev-mobile-app', '']" i18n>WhipEV Mobile Applications</a></h4>
            <p i18n>White-label Mobile App</p>
          </div>
        </div>
      </div>
      <div class="col col-lg-4 col-sm-6 single-card-item website">
        <div class="card-item isotope_item hover-scale">
          <div class="item-image">
            <a [routerLink]="['/portfolio', 'tdholding-site', '']">
              <img src="assets/images/portfolio/image-10.jpg" alt="portfolio" class="img-fluid" />
            </a>
          </div>
          <div class="item-info">
            <h4><a [routerLink]="['/portfolio', 'tdholding-site', '']" i18n>Truong Dinh Holdings Site</a></h4>
            <p i18n>Wordpress Site</p>
          </div>
        </div>
      </div>
      <div class="col col-lg-4 col-sm-6 single-card-item website">
        <div class="card-item isotope_item hover-scale">
          <div class="item-image">
            <a [routerLink]="['/portfolio', 'movinavir-site', '']">
              <img src="assets/images/portfolio/image-11.jpg" alt="portfolio" class="img-fluid" />
            </a>
          </div>
          <div class="item-info">
            <h4><a [routerLink]="['/portfolio', 'movinavir-site', '']" i18n>Movinavir Business Website</a></h4>
            <p i18n>Responsive Corporate Website</p>
          </div>
        </div>
      </div>
      <div class="col col-lg-4 col-sm-6 single-card-item app">
        <div class="card-item isotope_item hover-scale">
          <div class="item-image">
            <a [routerLink]="['/portfolio', 'ask2go-mobile-applications', '']">
              <img src="assets/images/portfolio/image-1.webp" alt="portfolio" class="img-fluid" />
            </a>
          </div>
          <div class="item-info">
            <h4><a [routerLink]="['/portfolio', 'ask2go-mobile-applications', '']" i18n>Ask2Go - App For Traveler</a></h4>
            <p i18n>iOS, Android Mobile App</p>
          </div>
        </div>
      </div>
      <div class="col col-lg-4 col-sm-6 single-card-item website">
        <div class="card-item isotope_item hover-scale">
          <div class="item-image">
            <a [routerLink]="['/portfolio', 'goen-e-learning-system', '']"><img
                src="assets/images/portfolio/image-2.jpg" alt="image" class="img-fluid" /> </a>
          </div>
          <div class="item-info">
            <h4><a [routerLink]="['/portfolio', 'goen-e-learning-system', '']" i18n>GoEn eLearning System</a></h4>
            <p i18n>Web Application</p>
          </div>
        </div>
      </div>
      <div class="col col-lg-4 col-sm-6 single-card-item app">
        <div class="card-item isotope_item hover-scale">
          <div class="item-image">
            <a [routerLink]="['/portfolio', 'envoca-mobile-app', '']">
              <img src="assets/images/portfolio/image-3.jpg" alt="image" class="img-fluid" /> </a>
          </div>
          <div class="item-info">
            <h4><a [routerLink]="['/portfolio', 'envoca-mobile-app', '']" i18n>Envoca Mobile App</a></h4>
            <p i18n>iOS, Android Mobile App</p>
          </div>
        </div>
      </div>
      <div class="col col-lg-4 col-sm-6 single-card-item website">
        <div class="card-item isotope_item hover-scale">
          <div class="item-image">
            <a [routerLink]="['/portfolio', 'meoto-auto-trader', '']">
              <img src="assets/images/portfolio/image-4.jpg" alt="image" class="img-fluid" /> </a>
          </div>
          <div class="item-info">
            <h4><a [routerLink]="['/portfolio', 'meoto-auto-trader', '']" i18n>MeOTO - Auto Trader</a></h4>
            <p i18n>Web Application</p>
          </div>
        </div>
      </div>
      <div class="col col-lg-4 col-sm-6 single-card-item graphic">
        <div class="card-item isotope_item hover-scale">
          <div class="item-image">
            <a [routerLink]="['/portfolio', '3d-hyundai-i30-banner', '']">
              <img src="assets/images/portfolio/image-5.jpg" alt="image" class="img-fluid" /> </a>
            <!-- <a [routerLink]="['/portfolio', '']"><img src="assets/images/portfolio/image-5.jpg" alt="image" class="img-fluid" /> </a> -->
          </div>
          <div class="item-info">
            <h4><a [routerLink]="['/portfolio', '3d-hyundai-i30-banner', '']" i18n>3D Hyundai i30 Banner</a></h4>
            <!-- <h4><a [routerLink]="['/portfolio', '']">3D Hyundai i30 Banner</a></h4> -->
            <p i18n>HTML5 Banner</p>
          </div>
        </div>
      </div>
      <div class="col col-lg-4 col-sm-6 single-card-item graphic">
        <div class="card-item isotope_item hover-scale">
          <div class="item-image">
            <a [routerLink]="['/portfolio', 'ascentis-homepage-design', '']">
              <img src="assets/images/portfolio/image-6.webp" alt="image" class="img-fluid" /> </a>
          </div>
          <div class="item-info">
            <h4><a [routerLink]="['/portfolio', 'ascentis-homepage-design', '']" i18n>Ascentis Homepage Design</a></h4>
            <p i18n>UI/UX Design</p>
          </div>
        </div>
      </div>
      <div class="col col-lg-4 col-sm-6 single-card-item graphic">
        <div class="card-item isotope_item hover-scale">
          <div class="item-image">
            <a [routerLink]="['/portfolio', 'hyundai-i20-banner', '']">
              <img src="assets/images/portfolio/image-7.jpg" alt="image" class="img-fluid" /> </a>
          </div>
          <div class="item-info">
            <h4><a [routerLink]="['/portfolio', 'hyundai-i20-banner', '']" i18n>Animated Hyundai i20 Banner</a></h4>
            <p i18n>HTML5 Banner</p>
          </div>
        </div>
      </div>
      <div class="col col-lg-4 col-sm-6 single-card-item app">
        <div class="card-item isotope_item hover-scale">
          <div class="item-image">
            <a [routerLink]="['/portfolio', 'cules-news-mobile-app', '']">
              <img src="assets/images/portfolio/image-8.jpg" alt="image" class="img-fluid" /> </a>
          </div>
          <div class="item-info">
            <h4><a [routerLink]="['/portfolio', 'cules-news-mobile-app', '']" i18n>Barcelona - News For Cules</a></h4>
            <p i18n>UI/UX Design</p>
          </div>
        </div>
      </div>
      <div class="col col-lg-4 col-sm-6 single-card-item graphic">
        <div class="card-item isotope_item hover-scale">
          <div class="item-image">
            <a [routerLink]="['/portfolio', 'fresy', '']">
              <img src="assets/images/portfolio/image-9.jpg" alt="image" class="img-fluid" /> </a>
          </div>
          <div class="item-info">
            <h4><a [routerLink]="['/portfolio', 'fresy', '']" i18n>Fresy Web Design</a></h4>
            <p i18n>Web Design</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Portfolio-->

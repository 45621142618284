// https://stackoverflow.com/questions/57720654/after-change-route-jquery-not-working-when-refresh-again-then-work-correctly
// core
import { Component, OnInit, ElementRef, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';

// self
import { ScriptService, LangService, MetaDataService } from '../services';
import { SharedService } from '../services/sharedService.service'

@Component({
  selector: 'app-services-digital-marketing',
  templateUrl: './digital-marketing.component.html',
  styleUrls: ['./service-detail.component.css']
})
export class DigitalMarketingComponent extends MetaDataService implements OnInit {
  // for load init script
  isLoad = false;
  public current_lang: any = {};

  constructor(
    private scriptService: ScriptService,
    private langService: LangService,
    private sharedService: SharedService,
    private location: Location,
    private router: Router,
    title: Title,
    meta: Meta,
  ) {
    super(title, meta);
    // this.title.setTitle("Digital Marketing Services | Dicom Interactive");
    // this.meta.updateTag({name: "description", content: `Having more leads, more sales, more revenue yet remaining sustainable in the industry is what all of businesses have been seeking for. We can help you build a long-term digital marketing strategy for your ultimate business plan. At the same time, we offer digital advertising services to boost sales, promote your brands, products, and services. We do not boast about working with Fortune 500 companies as one of our core values is helping our clients achieve their success in business growth and revenue.`});
    this.updateTags(
      'Digital Marketing Services',
      'Having more leads, more sales, more revenue yet remaining sustainable in the industry is what all of businesses have been seeking for. We can help you build a long-term digital marketing strategy for your ultimate business plan. At the same time, we offer digital advertising services to boost sales, promote your brands, products, and services. We do not boast about working with Fortune 500 companies as one of our core values is helping our clients achieve their success in business growth and revenue.',
      this.location.prepareExternalUrl(this.router.url),
    );
    
    // this._loadScript("assets/js/jquery-2.0.0.min.js");
    // this._loadScript("assets/js/bootstrap.js");
    // this._loadScript("assets/vendor/owl-carousel/js/owl.carousel.min.js");
    // this._loadScript("assets/vendor/revslider/js/jquery.themepunch.tools.min.js");
    // this._loadScript("assets/vendor/revslider/js/jquery.themepunch.revolution.min.js");
    // this._loadScript("assets/js/init.js");
    // this._loadScript("assets/js/slide.js");
    this.scriptService.load(this.scriptService.INIT).then(() => {
      this.isLoad = true;
    });
    // setTimeout(() => { this.isLoad = true }, 1000);

    // save for contact page
    // localStorage.setItem('contact_subject', 'marketing');
  }

  async ngOnInit() {
    this.current_lang = await this.langService.loadLang();
  }

  onContactMar() {
    this.sharedService.changeSubject('marketing');
  }
}

<!--Breadcrumb Area-->
<section class="breadcrumb-area banner-1" data-background="assets/images/banner/9.jpg">
  <div class="text-block">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 v-center">
          <div class="bread-inner">
            <div class="bread-menu">
              <ul>
                <li><a [routerLink]="['/']" i18n>Home</a></li>
                <li><a [routerLink]="[]">{{place}} Team</a></li>
              </ul>
            </div>
            <div class="bread-title">
              <h1 i18n>About Company</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Breadcrumb Area-->
<!--Start About-->
<section class="about-agency pad-tb block-1">
  <div class="container">
    <div class="row">
      <!-- <div class="col-lg-6 v-center">
					<div class="about-image">
						<img src="assets/images/about/company-about.png" alt="About Us" class="img-fluid" />
					</div>
				</div> -->
      <div class="col-lg-12">
        <div class="common-heading text-l ">
          <span i18n>About Us</span>
          <h2>Dicom Interactive {{place}}</h2>
          <p i18n>
            Dicom Interactive is one of the top boutique software development companies
            with offices located in {{place}} (Australia) and Ho Chi Minh City
            (Vietnam). We have been in the software business for over 15 years.
          </p>
          <p i18n>
            We help our clients build strong digital base by the best implementation of
            the latest technologies in the industry for their businesses. We make sure
            that all the digital touch points and software products delivered to our
            clients are ready for a full speed growth and scalable development in the
            future. We offer custom software development, web app development, mobile
            app development &amp; dedicated software teams to software companies and
            other businesses.
          </p>
          <p i18n>
            We have many successful stories in helping startups to launch their online
            services, from consulting, prototyping to full product developing and
            maintenance. We are not looking at the current status of our client’s
            businesses but at their potential growth.
          </p>
            <h3 i18n>Our team</h3>
          <p i18n>
            With a formulated mix of a workforce grouping senior and junior levels in
            software development team, creative team and product team from various
            industries, we are able to bring value added services to our clients, on
            both international and local projects. We offer high standard software
            solutions at effective cost, which suit your utmost demands on either
            business transformation, or product budget allocation, or project deadline
            challenges.
          </p>
          <p i18n>
            We value diversity. We believe that cultural diversity would unleash full
            potential of teamwork. We have been working on a variety of projects across
            the continents, with focus on education, transportation, entertainment,
            game, healthcare, insurance, food &amp; beverage, automobile, travel
            industry, and more to come.
          </p>
          <!-- <p align="center">
              <a href="https://dicom-interactive.com/our-team/" class="btn-main bg-btn2 lnk">Meet the team <i
                class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
          </p> -->
            <h3 i18n>Our offices</h3>
          <p i18n>
            With offices in selected parts of the globe, we are able to deliver our
            excellence to our clients seamlessly. Currently, our teams in {{place}} and
            Ho Chi Minh city are taking care of our clients in Australia, Asia, and
            Europe.
          </p>
          <p i18n>
            Our business direction is to offer boutique services in custom software
            development, where our clients have our full attention on their projects
            and get unique digital products that address their unique needs. We have
            happy clients from the most demanding markets like Japan, Singapore,
            Belgium, and Australia. In return, we also gain lots of valuable insights
            and industry-leading experience through every completed project.
          </p>
          <p i18n>
            At Dicom Interactive, we fully grasp the importance of listening and
            understanding our clients’ businesses, their visions and missions, to make
            sure both parties benefit from a long-term and fruitful corporation. We
            think big and have hands in all leading technology platforms to provide our
            clients a wide array of services.
          </p>
        </div>
        <div class="row in-stats small about-statistics">
          <div class="col-lg-4 col-sm-4">
            <div class="statistics">
              <div class="statnumb counter-number">
                <span class="counter">40</span><span>+</span>
                <p i18n i18n>Happy Clients</p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-4">
            <div class="statistics">
              <div class="statnumb">
                <span class="counter">50</span><span>k</span>
                <p i18n>Hours Worked</p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-4">
            <div class="statistics mb0">
              <div class="statnumb counter-number">
                <span class="counter">60</span><span>+</span>
                <p i18n>Projects Done</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End About-->
<!--Start why-choose-->
<section class="why-choose pad-tb">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="common-heading">
          <span i18n>We Are Awesome</span>
          <h2 class="mb30" i18n>Why Choose Us</h2>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-4 col-sm-6 mt30 wow fadeIn" data-wow-delay="0.2s">
        <div class="s-block wide-sblock hfull">
          <div class="s-card-icon"><img src="assets/images/icons/ontime.svg" alt="service" class="img-fluid">
          </div>
          <div class="s-block-content">
            <h4 i18n>On Time Delivery</h4>
            <p i18n>We value your precious time, so we try our best to deliver the good on time and within
              budget.</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 mt30 wow fadeIn" data-wow-delay=".4s">
        <div class="s-block wide-sblock hfull">
          <div class="s-card-icon"><img src="assets/images/icons/quality.svg" alt="service" class="img-fluid">
          </div>
          <div class="s-block-content">
            <h4 i18n>Top Quality</h4>
            <p i18n>Our testing experts efficiently collaborate with you to achieve the best product quality.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 mt30 wow fadeIn" data-wow-delay=".6s">
        <div class="s-block wide-sblock hfull">
          <div class="s-card-icon"><img src="assets/images/icons/accountability.svg" alt="service" class="img-fluid">
          </div>
          <div class="s-block-content">
            <h4 i18n>Accountability</h4>
            <p i18n>We fully grasp the importance of listening and understanding your business and
              objectives.</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 mt30  wow fadeIn" data-wow-delay="0.8s">
        <div class="s-block wide-sblock hfull">
          <div class="s-card-icon"><img src="assets/images/icons/price.svg" alt="service" class="img-fluid">
          </div>
          <div class="s-block-content">
            <h4 i18n>Reasonable Price</h4>
            <p i18n>We optimize our operation cost so that we could provide you services with bargain price.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 mt30 wow fadeIn" data-wow-delay="1.0s">
        <div class="s-block wide-sblock hfull">
          <div class="s-card-icon"><img src="assets/images/icons/process.svg" alt="service" class="img-fluid">
          </div>
          <div class="s-block-content">
            <h4 i18n>Professional Process</h4>
            <p i18n>We standardize Agile process to improve both team productivity and project quality.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End why-choose-->

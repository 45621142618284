import { Component, OnInit, ViewEncapsulation } from '@angular/core';
// import { FormBuilder, FormGroup, FormArray, FormControl, ValidatorFn } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { data } from './data';
import { Meta, Title } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppComponent } from '../app.component';
import { ScriptService, MetaDataService, LangService, ApiService } from '../services';
import { GeolocationService } from '../geolocation.service';
import { LanguageService } from '../services/language.service';
import { environment } from './../../environments/environment';

interface Blog {
  title: string;
  author: string;
  createAt: string;
  views: number;
  introduce: string;
  imageUrl: string;
  tags: string[];
  urlBlog: string;
}

@Component({
  selector: 'app-blog-single',
  templateUrl: './blog-single.component.html',
  styleUrls: ['./blog-single.component.css'],
})
export class BlogSingleComponent extends MetaDataService implements OnInit {
  private REST_API_SERVER = environment.api;
  blog: any = {};
  isLoad = false;
  blogs: any = [];
  getblog: any = {};
  formattedDate: string;
  name = 'Angular';
  testMarkup: SafeHtml;
  htmlString: string;
  public userLanguage: string;
  public userCountry: string;
  relatedBlogUrl: string = '';
  public shouldHideDiv: boolean = false;
  hasIncreasedView: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private scriptService: ScriptService,
    private http: HttpClient,
    private sanitized: DomSanitizer,
    private geolocation: GeolocationService,
    private languageService: LanguageService,
    private location: Location,
    private router: Router,
    title: Title,
    meta: Meta,
  ) { 
    super(title, meta);
    this.scriptService.load(this.scriptService.INIT).then(() => {
      this.isLoad = true;
    });

    this.geolocation.getClientLocale().subscribe((data) => {
      this.userLanguage = data.language;
      this.userCountry = data.country;
    });
  }

  ngOnInit(): void {
    const lang = this.userLanguage;
    this.route.paramMap.subscribe((params) => {
      const urlBlog = params.get('slug');
      // const slug = params.get('slug');
      // this.blog = this.getBlog(slug);
      this.getBlog(urlBlog).then(({ title, excerpt, imageUrl }) => {
        this.updateTags(
          title,
          excerpt,
          this.location.prepareExternalUrl(this.router.url),
          imageUrl
        );
      });
    });

    this.http.get<any>(`${this.REST_API_SERVER}/dicom-recent?lang=${lang}`)
    .subscribe(
      data => {
        this.blogs = data;
        this.blogs.forEach((blog: { createAt: any; formattedDate: string; }) => {
          let timestamp = blog.createAt;
          if (typeof timestamp === 'string') {
            timestamp = parseInt(timestamp);
          }
          if (typeof timestamp === 'number' && !isNaN(timestamp)) {
            const dateObj = new Date(timestamp * 1000);
            const options:{
              year?: "numeric" | "2-digit" | undefined;
              month?: "numeric" | "2-digit" | "long" | "short" | "narrow" | undefined;
              day?: "numeric" | "2-digit" | undefined;
            } = { year: 'numeric', month: 'short', day: 'numeric' };
            blog.formattedDate = dateObj.toLocaleDateString('en-US', options);
          }
        });
      },
      error => {}
    );
  }

  getBlog(urlBlog: string): Promise<{ title: string, excerpt: string, imageUrl: string }> {
    return new Promise((resolve, reject) => {
      this.route.queryParams.subscribe((queryParams) => {
        const lang = this.userLanguage;
        const headers = new HttpHeaders().set('Language', lang);
        this.http.get<any>(`${this.REST_API_SERVER}/blog-dicom-all/${urlBlog}`, { headers }).subscribe(
          (response) => {
            this.blog = response;
            const timestamp = this.blog.createAt;
            const dateObj = new Date(timestamp * 1000);
            this.formattedDate = dateObj.toDateString();
            this.htmlString = this.blog.description;
            if (!this.hasIncreasedView) {
              const view = this.blog.view;
              const updatedView = view + 1;
              this.blog.view = updatedView;
              this.hasIncreasedView = true;
            }
            this.testMarkup = this.sanitized.bypassSecurityTrustHtml(this.htmlString);
            const Url = this.blog.urlBlog;
            if (urlBlog !== Url) {
              this.changeLanguageAndReload(this.blog.language, undefined, Url);
            }
            resolve({ title: this.blog.title, excerpt: this.blog.introduce, imageUrl: this.blog.imageUrl });
          },
          (error) => {
            this.handleBlogError(error);
            reject(error);
          }
        );
      });
    });
  }

  changeLanguageAndReload(language: string, event: MouseEvent, Url: any) {
    this.languageService.changeLanguage(language, event, Url);
  }

  incrementView(blogId: string) {
    this.http.put<any>(`${this.REST_API_SERVER}/readcount/${blogId}`, {}).subscribe((response) => {
      const blogToUpdate = this.blogs.find(
        (blog: { _id: string }) => blog._id === blogId
      );
      if (blogToUpdate) {
        blogToUpdate.views = response.updatedViewCount;
      }
    })
  }

  handleBlogError(error: any): void {
    alert(
      'An error occurred while fetching the blog data. Please try again later.'
    );
  }

  public scrollToTop() {
    window.scrollTo(0, 0);
  }
}

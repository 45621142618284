import { Component, OnInit } from '@angular/core';
import { ShowCase } from '../types/showcase';
import { Pagination } from '../types/common';
import { environment } from 'src/environments/environment';
import { ApiService } from '../services';

@Component({
  selector: 'app-showcase',
  templateUrl: './showcase.component.html',
  styleUrls: ['./showcase.component.css'],
})
export class ShowcaseComponent implements OnInit {
  showcases: ShowCase[];
  pagination: Pagination;
  imgEndpoint = environment.imgEndPoint;
  constructor(private apiService: ApiService) {}

  ngOnInit(): void {
    this.getShowcases();
  }

  async getShowcases() {
    const res = await this.apiService.getShowcases();
    if (res.data.length > 0) {
      this.showcases = res.data;
      this.pagination = res.meta.pagination;
    }
  }
}

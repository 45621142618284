//http://www.lukasjakob.com/how-to-dynamically-load-external-scripts-in-angular/
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

const LANG =
{
  en: "assets/lang/en.json",
  vn: "assets/lang/vn.json"
};

@Injectable({
  providedIn: 'root'
})
export class LangService {
  public EN = 'en';
  public VN = 'vn';

  constructor(
    private httpClient: HttpClient,
  ) {

  }

  // will get from localstorage what language user choose
  public loadLang() {
    // hard code EN first
    return this.httpClient.get(LANG.en).toPromise();
  }

}

// https://stackoverflow.com/questions/57720654/after-change-route-jquery-not-working-when-refresh-again-then-work-correctly
// core
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';

// self
import { ScriptService, LangService, MetaDataService } from '../services';


@Component({
  selector: 'offshore-development-center.component.',
  templateUrl: './offshore-development-center.component.html',
  styleUrls: ['./service-detail.component.css']
})
export class OffshoreDevelopmentCenterComponent extends MetaDataService implements OnInit {
  // for load init script
  isLoad = false;
  public current_lang: any = {};

  constructor(
    private scriptService: ScriptService,
    private langService: LangService,
    private location: Location,
    private router: Router,
    title: Title,
    meta: Meta,
  ) {
    super(title, meta);
    this.updateTags(
      'Offshore Development Center',
      'We help businesses scale up delivery capacity with our high standard offshore development services. Our solutions resolve the challenges that you might be having such as project deadlines, limited team headcount, Project budget, or out of expertise scope, ... and help you meet your business milestones of growth.',
      this.location.prepareExternalUrl(this.router.url),
    );

    // this._loadScript("assets/js/jquery-2.0.0.min.js");
    // this._loadScript("assets/js/bootstrap.js");
    // this._loadScript("assets/vendor/owl-carousel/js/owl.carousel.min.js");
    // this._loadScript("assets/vendor/revslider/js/jquery.themepunch.tools.min.js");
    // this._loadScript("assets/vendor/revslider/js/jquery.themepunch.revolution.min.js");
    // this._loadScript("assets/js/init.js");
    // this._loadScript("assets/js/slide.js");
    // setTimeout(() => { this.isLoad = true }, 1000);
    this.scriptService.load(this.scriptService.INIT).then(() => {
      this.isLoad = true;
    });
  }

  async ngOnInit() {
    this.current_lang = await this.langService.loadLang();
  }


}

export class Quote {
  name: string;
  email: string;
  countryCode: string;
  countryName: string;
  phone: string;
  companyName: string;
  deadline: string;
  contentPlan: string;
  pageRequest: string;
  featureRequest: string;
  message: string;
  files: FileList;
  subject: string;
}

// https://stackoverflow.com/questions/57720654/after-change-route-jquery-not-working-when-refresh-again-then-work-correctly
// core
import { Component, AfterViewInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
// import { Router, CanActivate, ActivatedRoute, RouterStateSnapshot } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';


@Component({
  selector: 'app-testinomial-component',
  templateUrl: './testinomial.component.html',
  styleUrls: ['./testinomial.component.css']
})
export class TestinomialComponent implements AfterViewInit {

  constructor(
  ) {
  }

  ngAfterViewInit() {

  }

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
// import { FormBuilder, FormGroup, FormArray, FormControl, ValidatorFn } from '@angular/forms';
import { ScriptService, MetaDataService } from '../services';
import { HttpClient } from '@angular/common/http';
import { data } from './data/blog-data';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from './../../environments/environment';
import { GeolocationService } from '../geolocation.service';
import { Observable } from 'rxjs';
import { BlogService } from './service/blog-service';
@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css'],
})
export class BlogComponent extends MetaDataService implements OnInit {
  private REST_API_SERVER = environment.api;
  [x: string]: any;
  blogs: any = [];
  posts: any[] = [];
  isLoad = false;
  public userLanguage: string;
  public userCountry: string;

  constructor(
    private scriptService: ScriptService,
    private http: HttpClient,
    private blogService: BlogService,
    private geolocation: GeolocationService,
    private location: Location,
    private router: Router,
    title: Title,
    meta: Meta,
  ) {
    super(title, meta);
    // this.title.setTitle('Blog | Dicom Interactive');
    // this.meta.updateTag({
    //   name: 'description',
    //   content:
    //     'We have been providing software products to startups and Software development companies in various ranges of scale. We are offering custom software development, web app development, mobile app development & dedicated software teams to software companies and startups.',
    // });
    this.updateTags(
      'Blog',
      'We have been providing software products to startups and Software development companies in various ranges of scale. We are offering custom software development, web app development, mobile app development & dedicated software teams to software companies and startups.',
      this.location.prepareExternalUrl(this.router.url),
    );

    this.scriptService.load(this.scriptService.INIT).then(() => {
      this.isLoad = true;
    });
    this.geolocation.getClientLocale().subscribe((data) => {
      this.userLanguage = data.language;
      this.userCountry = data.country;
    });
  }

  ngOnInit(): void {
      let url = `${this.REST_API_SERVER}/blog-dicom-all`;
      if (this.userLanguage === 'en' || this.userLanguage === 'vn') {
        url += `?lang=${this.userLanguage}`;
      }
      this.http.get<any>(url).subscribe(
          data => {
              this.blogs = data.map((blog: { createAt: any; formattedDate: string; language: string }) => this.formatBlogDate(blog));
          },
          error => {
              console.error(error);
          }
      );
  }
  

  private formatBlogDate(blog: { createAt: any; formattedDate: string; language: string }) {
    let timestamp = blog.createAt;
    if (typeof timestamp === 'string') {
      timestamp = parseInt(timestamp);
    }
    if (typeof timestamp === 'number' && !isNaN(timestamp)) {
      const dateObj = new Date(timestamp * 1000);
      const options: {
        year?: "numeric" | "2-digit" | undefined;
        month?: "numeric" | "2-digit" | "long" | "short" | "narrow" | undefined;
        day?: "numeric" | "2-digit" | undefined;
      } = { year: 'numeric', month: 'short', day: 'numeric' };
      blog.formattedDate = dateObj.toLocaleDateString('en-US', options);
    }
    return blog;
  }
  

  incrementView(blogId: string) {
    this.http.put<any>(`${this.REST_API_SERVER}/readcount/${blogId}`, {}).subscribe((response) => {
      const blogToUpdate = this.blogs.find(
        (blog: { _id: string }) => blog._id === blogId
      );
      if (blogToUpdate) {
        blogToUpdate.views = response.updatedViewCount;
      }
    })
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  private subjectSource = new BehaviorSubject<string | null>(null);
  currentSubject = this.subjectSource.asObservable();

  constructor() { }

  changeSubject(subject: string) {
    if (subject) {
      this.subjectSource.next(subject);
    } else {
      this.subjectSource.next(null);
    }
  }
}

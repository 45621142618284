<!--Start Why Choose-->
<section class="service-block pad-tb bg-gradient7">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="common-heading ptag">
          <span i18n>We Deliver Our Best</span>
          <h2 i18n>Why Hire Dicom Interactive</h2>
          <!--p class="mb30">Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit.</p-->
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-4 col-sm-6 mt30 wow fadeIn" data-wow-delay="0.2s">
        <div class="s-block wide-sblock hfull">
          <div class="s-card-icon"><img src="assets/images/icons/ontime.svg" alt="service" class="img-fluid">
          </div>
          <div class="s-block-content">
            <h4 i18n>On Time Delivery</h4>
            <p i18n>We value your precious time, so we try our best to deliver the good on time and within
              budget.</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 mt30 wow fadeIn" data-wow-delay=".5s">
        <div class="s-block wide-sblock hfull">
          <div class="s-card-icon"><img src="assets/images/icons/quality.svg" alt="service" class="img-fluid">
          </div>
          <div class="s-block-content">
            <h4 i18n>Top Quality</h4>
            <p i18n>Our testing experts efficiently collaborate with you to achieve the best product quality.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 mt30 wow fadeIn" data-wow-delay=".8s">
        <div class="s-block wide-sblock hfull">
          <div class="s-card-icon"><img src="assets/images/icons/accountability.svg" alt="service"
              class="img-fluid"></div>
          <div class="s-block-content">
            <h4 i18n>Accountability</h4>
            <p i18n>We fully grasp the importance of listening and understanding your business and
              objectives.</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 mt30  wow fadeIn" data-wow-delay="1.1s">
        <div class="s-block wide-sblock hfull">
          <div class="s-card-icon"><img src="assets/images/icons/price.svg" alt="service" class="img-fluid">
          </div>
          <div class="s-block-content">
            <h4 i18n>Reasonable Price</h4>
            <p i18n>We optimize our operation cost so that we could provide you services with bargain price.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 mt30 wow fadeIn" data-wow-delay="1.4s">
        <div class="s-block wide-sblock hfull">
          <div class="s-card-icon"><img src="assets/images/icons/process.svg" alt="service" class="img-fluid">
          </div>
          <div class="s-block-content">
            <h4 i18n>Professional Process</h4>
            <p i18n>We standardize Agile process to improve both team productivity and project quality.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="-cta-btn mt70">
      <div class="free-cta-title v-center wow zoomInDown" data-wow-delay="1.8s">
        <p i18n>Let's Start a <span>New Project</span> Together</p>
        <a [routerLink]="['/contact']" class="btn-main bg-btn2 lnk" i18n>Inquire Now<i
            class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
      </div>
    </div>
  </div>
</section>
<!--End Why Choose-->
<!--Stat Projects-->
<section class="featured-project pad-tb">
  <div class="container">
    <div class="row justify-content-center">
    <div class="col-lg-6">
      <div class="common-heading ptag">
      <span i18n>Our Projects</span>
      <h2 i18n>Some of Our Works</h2>
      <p class="mb0" i18n>We think big and have hands in all leading technology platforms to provide you wide array of
        services.</p>
      </div>
    </div>
    </div>
    <div class="row">
    <div class="col-lg-4 wow fadeInUp" data-wow-delay=".2s">
      <div class="isotope_item hover-scale">
      <div class="item-image">
        <a [routerLink]="['/portfolio', 'ask2go-mobile-applications', '']"><img src="assets/images/portfolio/image-1.webp" alt="portfolio" class="img-fluid" /> </a>
      </div>
      <div class="item-info">
        <h4><a [routerLink]="['/portfolio', 'ask2go-mobile-applications', '']" i18n>Ask2Go - App For Traveler</a></h4>
        <p i18n>iOS, Android Mobile App</p>
      </div>
      </div>
    </div>
    <div class="col-lg-4 wow fadeInUp" data-wow-delay=".4s">
      <div class="isotope_item hover-scale">
      <div class="item-image">
        <a [routerLink]="['/portfolio', 'goen-e-learning-system', '']"><img src="assets/images/portfolio/image-2.jpg" alt="portfolio" class="img-fluid" /> </a>
      </div>
      <div class="item-info">
        <h4><a [routerLink]="['/portfolio', 'goen-e-learning-system', '']" i18n>GoEn eLearning System</a></h4>
        <p i18n>Web Application</p>
      </div>
      </div>
    </div>
    <div class="col-lg-4 wow fadeInUp" data-wow-delay=".6s">
      <div class="isotope_item hover-scale">
      <div class="item-image">
        <a [routerLink]="['/portfolio', 'envoca-mobile-app', '']"><img src="assets/images/portfolio/image-3.jpg" alt="portfolio" class="img-fluid" /> </a>
      </div>
      <div class="item-info">
        <h4><a [routerLink]="['/portfolio', 'envoca-mobile-app', '']" i18n>Envoca Mobile App</a></h4>
        <p i18n>iOS, Android Mobile App</p>
      </div>
      </div>
    </div>
    <div class="col-lg-4 wow fadeInUp" data-wow-delay=".8s">
      <div class="isotope_item hover-scale">
      <div class="item-image">
        <a [routerLink]="['/portfolio', 'meoto-auto-trader', '']"><img src="assets/images/portfolio/image-4.jpg" alt="portfolio" class="img-fluid" /> </a>
      </div>
      <div class="item-info">
        <h4><a [routerLink]="['/portfolio', 'meoto-auto-trader', '']" i18n>MeOTO - Auto Trader</a></h4>
        <p i18n>Web Application</p>
      </div>
      </div>
    </div>
    <div class="col-lg-4 wow fadeInUp" data-wow-delay="1s">
      <div class="isotope_item hover-scale">
      <div class="item-image">
        <a [routerLink]="['/portfolio', '3d-hyundai-i30-banner', '']"><img src="assets/images/portfolio/image-5.jpg" alt="portfolio" class="img-fluid" /> </a>
      </div>
      <div class="item-info">
        <h4><a [routerLink]="['/portfolio', '3d-hyundai-i30-banner', '']" i18n>3D Hyundai i30 Banner</a></h4>
        <p i18n>HTML5 Banner</p>
      </div>
      </div>
    </div>
    <div class="col-lg-4 wow fadeInUp" data-wow-delay="1.2s">
      <div class="isotope_item hover-scale">
      <div class="item-image">
        <a [routerLink]="['/portfolio', 'ascentis-homepage-design', '']"><img src="assets/images/portfolio/image-6.webp" alt="portfolio" class="img-fluid" /> </a>
      </div>
      <div class="item-info">
        <h4><a [routerLink]="['/portfolio', 'ascentis-homepage-design', '']" i18n>Ascentis Homepage Design</a></h4>
        <p i18n>UI/UX Design</p>
      </div>
      </div>
    </div>
    </div>
    <div class="row">
    <div class="col-lg-12 maga-btn mt60">
      <a [routerLink]="['/portfolio']" class="btn-outline" i18n>View More Projects <i
        class="fas fa-chevron-right fa-icon"></i></a>
    </div>
    </div>
  </div>
  </section>
  <!--End Projects-->

<!--Start CTA-->
<section class="cta-area pad-tb">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="common-heading">
          <span i18n>Let's work together</span>
          <h2 i18n>We Love to Listen to Your Requirements</h2>
          <a [routerLink]="['/contact']" class="btn-outline" i18n>Estimate Project <i
              class="fas fa-chevron-right fa-icon"></i></a>
          <p class="cta-call" i18n>Or call us now <a href="tel:+61402752523"><i class="fas fa-phone-alt"></i>
              (+61) 402 7525 23</a> or <a href="tel:+84907506614"><i class="fas fa-phone-alt"></i>
              (+84) 907 5066 14</a></p>
        </div>
      </div>
    </div>
  </div>
  <div class="shape shape-a1"><img src="assets/images/shape/shape-3.svg" alt="shape" /></div>
  <div class="shape shape-a2"><img src="assets/images/shape/shape-4.svg" alt="shape" /></div>
  <div class="shape shape-a3"><img src="assets/images/shape/shape-13.svg" alt="shape" /></div>
  <div class="shape shape-a4"><img src="assets/images/shape/shape-11.svg" alt="shape" /></div>
</section>
<!--End CTA-->

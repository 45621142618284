// https://stackoverflow.com/questions/57720654/after-change-route-jquery-not-working-when-refresh-again-then-work-correctly
// core
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';

// self
import {
  ScriptService,
  LangService,
  previousEvent,
  nextEvent,
  MetaDataService
} from '../services';

// Envoca Mobile App
@Component({
  selector: 'app-envoca',
  templateUrl: './envoca.component.html',
  styleUrls: ['./project.component.css'],
})
export class EnvocaComponent extends MetaDataService implements OnInit {
  // for load init script
  isLoad = false;
  public current_lang: any = {};
  previousLink = '';
  nextLink = '';
  currentLink = 'envoca-mobile-app';

  constructor(
    private scriptService: ScriptService,
    private langService: LangService,
    private location: Location,
    private router: Router,
    title: Title,
    meta: Meta,
  ) {
    super(title, meta);
    // this.title.setTitle('Portfolio Envoca Mobile App | Dicom Interactive');
    // this.meta.updateTag({
    //   name: 'description',
    //   content: 'Envoca mobile app for English learner.',
    // });
    this.updateTags(
      'Portfolio Envoca Mobile App',
      'Envoca mobile app for English learner.',
      this.location.prepareExternalUrl(this.router.url),
      this.location.prepareExternalUrl('/assets/images/case-study/envoca-intro.png')
    );
    
    // this.scriptService.load("assets/js/main.js");
    // this._loadScript("assets/js/bootstrap.js");
    // this._loadScript("assets/vendor/owl-carousel/js/owl.carousel.min.js");
    // this._loadScript("assets/vendor/revslider/js/jquery.themepunch.tools.min.js");
    // this._loadScript("assets/vendor/revslider/js/jquery.themepunch.revolution.min.js");
    // this._loadScript("assets/js/init.js");
    // this._loadScript("assets/js/slide.js");
    this.scriptService.load(this.scriptService.INIT).then(() => {
      this.isLoad = true;
    });
    // setTimeout(() => { this.isLoad = true }, 1000);
    this.previousLink = previousEvent(this.currentLink);
    this.nextLink = nextEvent(this.currentLink);
  }

  async ngOnInit() {
    this.current_lang = await this.langService.loadLang();
  }

  executeVideoScript(): void {
    document.querySelectorAll('.container-video video').forEach((vid: HTMLVideoElement) => {
        const qopupVideo = document.querySelector('.class-qopup-video') as HTMLElement;
        const qopupVideo1 = document.querySelector('.video-ing') as HTMLElement;
        if (qopupVideo) {
          qopupVideo.style.display = 'block';
          qopupVideo1.style.display = 'block';
        }
      });
    }

  executeVideo(): void {
    document.querySelectorAll('.container-video').forEach((span) => {
      const qopupVideo1 = document.querySelector('.class-qopup-video') as HTMLVideoElement;
      const qopupVideo = span.querySelector('.video-ing');
      if (qopupVideo instanceof HTMLVideoElement ) {
        qopupVideo.style.display = 'none';
        qopupVideo1.style.display = 'none';
        qopupVideo.pause();
      }
    });
  }

  exexute(): void {
    document.querySelectorAll('.container-video').forEach((vid: HTMLVideoElement) => {
    const qopupVideo1 = document.querySelector('.class-qopup-video') as HTMLVideoElement;
    const qopupVideo = document.querySelector('.video-ing') as HTMLVideoElement;
    document.addEventListener('keydown', (event) => {
          if (event.keyCode === 27) {
            qopupVideo.style.display = 'none';
            qopupVideo1.style.display = 'none';
            qopupVideo.pause();
          }
        })
      });
    }

  export = this.exexute()
}
